import {useState} from 'react';
import {FiMenu} from 'react-icons/fi';
import WriteWithRAIModal from "./Add/AI/WriteWithRAIModal";
import TranslateWithRAIModal from "./Add/AI/TranslateWithRAIModal";

export default function ReleaseNoteEditorMenuButton(
    {
        markdownContents
    }: {
        markdownContents: string
    }
) {
    const [showOptions, setShowOptions] = useState(false);

    const toggleOptions = () => {
        setShowOptions(!showOptions);
    };

    return (
        <div className="">
            <div className="flex space-x-1 items-center justify-center">
                <FiMenu className="w-4 h-4" onClick={toggleOptions}/>
                <span className="text-sm text-gray-500" onClick={toggleOptions}>Options</span>
            </div>
            {showOptions && (
                <div
                    className="absolute bg-white border border-gray-200 shadow-lg rounded-md mt-2 py-2 w-36 z-10 right-4">
                    <div className="px-4 py-2 hover:bg-gray-100 cursor-pointer flex items-center">
                        <WriteWithRAIModal/>
                    </div>
                    <div className="px-4 py-2 hover:bg-gray-100 cursor-pointer flex items-center">
                        <TranslateWithRAIModal content={markdownContents}/>
                    </div>
                </div>
            )}
        </div>
    );
}
