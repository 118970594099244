import API from "../../api";
import {useQuery} from "@tanstack/react-query";

async function fetchPublicFeedbackInfo(
    {
        releaseNoteID,
        subscriberID,
    }: {
        releaseNoteID: string;
        subscriberID: string;
    }
) {
    const data = await API.get(`/public-feedback/${releaseNoteID}/${subscriberID}`);
    return data.data;
}

function usePublicFeedbackGetInfo(
    {
        releaseNoteID,
        subscriberID,
    }: {
        releaseNoteID: string;
        subscriberID: string;
    }) {
    return useQuery(
        ["publicFeedback", releaseNoteID, subscriberID],
        () => {
            return fetchPublicFeedbackInfo({releaseNoteID, subscriberID});
        },
    );
}

export default usePublicFeedbackGetInfo;