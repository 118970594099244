import { Table } from "flowbite-react";
import { OrganizationUser } from "../../../../types/users";
import GenericDateComponent from "../../../Shared/GenericDateComponent";
import OrganizationMemberDeleteModal from "./OrganizationMemberDeleteModal";
import OrganizationMemberUpdateRoleModel from "./OrganizationMemberUpdateRoleModal";
import useGetOrgRoleForUser from "../../../../hooks/store/useGetOrgRoleForUser";

interface MemberListComponentPropos {
  members: OrganizationUser[];
  orgID: string;
}

function capitalizeFirstLetter(str: string) {
  if (!str) return str;
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export default function MemberListTableComponent({
  members,
  orgID,
}: MemberListComponentPropos) {
  const userRole = useGetOrgRoleForUser(orgID);

  return (
    <div>
      <Table>
        <Table.Head>
          <Table.HeadCell className="bg-gray-200">Member Name</Table.HeadCell>
          <Table.HeadCell className="bg-gray-200">Role</Table.HeadCell>
          <Table.HeadCell className="bg-gray-200">User Email</Table.HeadCell>
          <Table.HeadCell className="bg-gray-200">Added Date</Table.HeadCell>
          {userRole === "admin" && (
            <Table.HeadCell className="bg-gray-200">Action</Table.HeadCell>
          )}
        </Table.Head>
        <Table.Body className="divide-y">
          {members.map((member) => (
            <Table.Row
              className="bg-gray-50 dark:border-gray-700 dark:bg-gray-800"
              key={member.id}
            >
              <Table.Cell>{`${member.firstName} ${member.lastName}`}</Table.Cell>
              <Table.Cell>{capitalizeFirstLetter(member.role)}</Table.Cell>
              <Table.Cell>{member.email}</Table.Cell>
              <Table.Cell>
                <GenericDateComponent date={member.addedAt} />
              </Table.Cell>
              {userRole === "admin" && (
                <Table.Cell>
                  <div className="flex">
                    <OrganizationMemberUpdateRoleModel
                      orgId={orgID}
                      memberId={member.id}
                      name={member.firstName + " " + member.lastName}
                      currentRole={member.role}
                    />
                    <OrganizationMemberDeleteModal
                      organizationID={orgID}
                      memberID={member.id}
                      memberName={member.firstName + " " + member.lastName}
                    />
                  </div>
                </Table.Cell>
              )}
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </div>
  );
}
