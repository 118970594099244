import {Button, Table} from "flowbite-react";
import DefaultSettingLayout from "../../../components/Settings/SettingsDefaultLayout";
import {HiCog, HiPlusCircle} from "react-icons/hi";
import {useOrganizations} from "../../Organizations/hooks/useOrganizations";
import {Link} from "react-router-dom";
import GenericCircularIndicatorSkeletonLoader from "../../../components/Shared/GenericCirularIndicatorSkeletonLoader";
import OrganizationEditModal from "../../../components/Organization/OrganizationEditOrganizationModal";
import React from "react";
import OrganizationAddModal from "../../../components/Organization/OrganizationAddModal";
import TableSkeletonLoader from "../../../components/Shared/TableSkeletonLoader";

export default function DefaultSettingOrganizationsListPage() {
    const {data: organizations, isLoading, isFetching} = useOrganizations();

    if (!organizations) {
        return <GenericCircularIndicatorSkeletonLoader/>;
    }

    return (
        <DefaultSettingLayout>
            <div className="space-y-4 p-4">
                <div className="flex">
                    <div className="flex-1">
                        <h1 className="text-gray-800 text-2xl font-semibold mb-4">
                            Organizations
                        </h1>
                    </div>
                    <div>
                        <div className="cursor-pointer">
                            <OrganizationAddModal/>
                        </div>
                    </div>
                </div>
                <div>
                    {isFetching || isLoading ? <TableSkeletonLoader /> :
                        <Table hoverable>
                            <Table.Head>
                                <Table.HeadCell className="bg-gray-200">
                                    Organization
                                </Table.HeadCell>
                                <Table.HeadCell className="bg-gray-200">Phone</Table.HeadCell>
                                <Table.HeadCell className="bg-gray-200">Address</Table.HeadCell>
                                <Table.HeadCell className="bg-gray-200">ZipCode</Table.HeadCell>
                                <Table.HeadCell className="bg-gray-200"></Table.HeadCell>
                            </Table.Head>
                            <Table.Body className="divide-y">
                                {organizations.map((organization) => (
                                    <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                                        <Table.Cell>
                                            <Link
                                                to={`/app/settings/organization/${organization.ROWID}`}
                                            >
                                                <div className="flex space-x-2">
                                                    <div>
                                                        <img
                                                            src={organization.logo}
                                                            alt={`${organization.name} Logo`}
                                                            className="h-6 w-6 rounded-full"
                                                        />
                                                    </div>
                                                    <div className="text-lg my-auto text-gray-900 font-semibold">
                                                        {organization.name}
                                                    </div>
                                                </div>
                                            </Link>
                                        </Table.Cell>
                                        <Table.Cell>
                                            <div>{organization.phone}</div>
                                        </Table.Cell>
                                        <Table.Cell>
                                            <div>{organization.address}</div>
                                        </Table.Cell>
                                        <Table.Cell>
                                            <div>{organization.zipCode}</div>
                                        </Table.Cell>
                                        <Table.Cell>
                                            <Link
                                                to={`/app/settings/organization/${organization.ROWID}`}
                                                className="ml-auto"
                                            >
                                                <Button color="gray" size="small">
                                                    <HiCog className="h-6 w-6"/>
                                                </Button>
                                            </Link>
                                        </Table.Cell>
                                    </Table.Row>
                                ))}
                            </Table.Body>
                        </Table>
                    }
                </div>
            </div>
        </DefaultSettingLayout>
    );
}
