import React from "react";
import {useNavigate, useParams} from "react-router-dom";
import API from "../../api";
import {OrganizationSelect} from "./OrganizationSelect";
import {Avatar, DarkThemeToggle, Dropdown} from "flowbite-react";
import {ProductSelect} from "./ProductSelect";
import {useQuery} from "@tanstack/react-query";

export function StickyNavbar() {
    const {orgId} = useParams();

    const [openNav, setOpenNav] = React.useState(false);
    const [openDropdown, setOpenDropdown] = React.useState(false);

    React.useEffect(() => {
        window.addEventListener(
            "resize",
            () => window.innerWidth >= 960 && setOpenNav(false)
        );
    }, []);

    const fetchOrganizations = async () => {
        const response = await API.get("organizations", {
            headers: {
                "Content-Type": "application/json",
            },
        });
        return response.data.payload;
    };

    const {data: organizations, isLoading: isOrganizationsLoading} = useQuery({
        queryKey: ["organizations"],
        queryFn: fetchOrganizations,
        staleTime: Infinity,
        refetchOnWindowFocus: false,
    });

    const fetchSelfProfile = async () => {
        const response = await API.get(`organization/${orgId}/users/me`, {
            headers: {
                "Content-Type": "application/json",
            },
        });
        return response.data.payload;
    };

    const {
        data: profileData,
        isLoading: isProfileLoading,
        isError: isProfileError,
    } = useQuery({
        queryKey: ["selfProfile", orgId],
        queryFn: fetchSelfProfile,
        staleTime: Infinity,
        refetchOnWindowFocus: false,
    });

    const navigate = useNavigate();

    const dropdownOptions = (
        <div
            className={`origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-gray-50 ring-1 ring-black ring-opacity-5 ${
                openDropdown ? "block" : "hidden"
            }`}
        >
            <div className="py-1">
                {!isOrganizationsLoading &&
                    organizations?.map((organization: any) => {
                        return (
                            <button
                                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-start"
                                onClick={() => {
                                    localStorage.setItem("orgID", organization.ROWID);
                                    setOpenDropdown(false);
                                }}
                            >
                                {organization.name}
                            </button>
                        );
                    })}
            </div>
        </div>
    );

    return (
        <div className="sticky top-0 z-10 bg-gray-50 dark:bg-gray-700 dark:text-white">
            <div
                className="max-w-full px-4 lg:px-8 py-1 bg-gray-100 mx-6 my-2 rounded-lg dark:bg-gray-800 dark:text-white">
                <div className="flex items-center justify-between text-blue-gray-900 dark:text-gray-200">
                    <div className="mr-4 cursor-pointer py-1.5 text-lg font-bold">
                        <div className="flex flex-col space-y-0">
              <span
                  className="text-gray-500"
                  style={{
                      fontSize: "0.65rem",
                      lineHeight: "1rem",
                  }}
              >
                Current Product
              </span>
                            <ProductSelect orgID={orgId ?? ""}/>
                        </div>
                    </div>
                    <div className="flex items-center gap-4 font-bold">
                        <div className="flex flex-col space-y-0">
                          <span
                              className="text-gray-500"
                              style={{
                                  fontSize: "0.65rem",
                                  lineHeight: "1rem",
                              }}
                          >
                            Current Organization
                          </span>
                            <OrganizationSelect organizations={organizations}/>
                        </div>
                        {/*<DarkThemeToggle/>*/}
                        {profileData && !isProfileLoading && !isProfileError ? (
                            <div>
                                <Dropdown
                                    arrowIcon={false}
                                    inline
                                    label={
                                        <Avatar
                                            alt="User settings"
                                            img={
                                                "https://api.dicebear.com/7.x/adventurer-neutral/svg?seed=" +
                                                profileData?.firstName +
                                                " " +
                                                profileData?.lastName
                                            }
                                            rounded
                                        />
                                    }
                                >
                                    <Dropdown.Header>
                                        <span className="block text-sm">
                                          {profileData?.firstName} {profileData?.lastName}
                                        </span>
                                        <span className="block truncate text-sm font-medium">
                                          {profileData.email}
                                        </span>
                                    </Dropdown.Header>
                                    <Dropdown.Item
                                        onClick={() => {
                                            navigate("/app/settings");
                                        }}
                                    >
                                        Settings
                                    </Dropdown.Item>
                                    <Dropdown.Divider/>
                                    <Dropdown.Item
                                        onClick={() => {
                                            window.catalyst.auth.signOut("/__catalyst/auth/login");
                                        }}
                                    >
                                        Sign out
                                    </Dropdown.Item>
                                </Dropdown>
                            </div>
                        ) : (
                            <>
                                <Avatar
                                    alt="User settings"
                                    className="animate-pulse p-2"
                                    rounded
                                />
                            </>
                        )}

                        <button
                            className="ml-auto h-6 w-6 text-inherit hover:bg-transparent focus:bg-transparent active:bg-transparent lg:hidden"
                            onClick={() => setOpenNav(!openNav)}
                        >
                            {openNav ? (
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    className="h-6 w-6"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    strokeWidth={2}
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M6 18L18 6M6 6l12 12"
                                    />
                                </svg>
                            ) : (
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-6 w-6"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth={2}
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M4 6h16M4 12h16M4 18h16"
                                    />
                                </svg>
                            )}
                        </button>
                    </div>
                </div>
                <div
                    className={`bg-white fixed top-0 left-0 w-full h-full transition-transform duration-300 transform ${
                        openNav ? "translate-x-0" : "translate-x-full"
                    }`}
                >
                    <div className="p-4">
                        <button
                            onClick={() => {
                                setOpenNav(false);
                            }}
                        >
                            Close
                        </button>
                        <button
                            className="bg-purple-600 text-white px-4 py-2 rounded-md focus:outline-none hover:bg-purple-700 block w-full"
                            onClick={() => setOpenDropdown(!openDropdown)}
                        >
                            Select Organization
                        </button>
                        {dropdownOptions}
                    </div>
                </div>
            </div>
        </div>
    );
}
