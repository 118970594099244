import {useState} from "react";
import {ReleaseNoteWithRowId} from "../../../../types/releaseNotes";
import PublishModalSendTestEmailComponent from "./PublishModalSendTestEmailComponent";
import PublishModalScheduleSendEmailComponent from "./PublishModalScheduleSendEmailComponent";
import PublishModalImmidiateSendEmailComponent from "./PublishModalImmidiateSendEmailComponent";
import useValidateEmailConfig from "../../../../hooks/emailconfig/useValidateEmailConfig";
import {useParams} from "react-router-dom";
import PublishedModalEmailNotConfiguredComponent from "./PublishedModalEmailNotConfiguredComponent";
import GenericCircularIndicatorSkeletonLoader from "../../../Shared/GenericCirularIndicatorSkeletonLoader";
import {CircularProgressIndicator} from "../../../Shared/Button";

export default function UnscheduledUnsentEmailModalBodyComponent({
                                                                     note,
                                                                 }: {
    note: ReleaseNoteWithRowId;
}) {
    const [isLoading, setIsLoading] = useState(false);

    const {prodId} = useParams();

    const {
        data: emailConfigValidation,
        isLoading: emailConfigValLoading,
        isFetching: emailConfigValFetching,
    } = useValidateEmailConfig({productID: prodId ?? ""});


    if (emailConfigValLoading || !emailConfigValidation || emailConfigValFetching)
        return <div
            className="h-[50vh] flex justify-center items-center p-4 m-1">
            <CircularProgressIndicator/>
        </div>;

    if (emailConfigValidation?.isValid === false) {
        return <PublishedModalEmailNotConfiguredComponent/>
    }


    return (
        <>
            <div className="space-y-6 h-[55vh]">
                <PublishModalScheduleSendEmailComponent
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                    releaseNoteID={note.ROWID}
                />
                <hr/>
                <PublishModalSendTestEmailComponent
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                    releaseNoteID={note.ROWID}
                />
                <hr/>
                <PublishModalImmidiateSendEmailComponent
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                    releaseNoteID={note.ROWID}
                />
                <hr/>
                {emailConfigValidation?.helperText &&
                    <div className="p-4 bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700">
                        <h4 className="font-semibold mb-2">Important Information:</h4>
                        <ul className="list-disc pl-5">
                            <li>
                                {emailConfigValidation?.helperText}
                            </li>
                        </ul>
                    </div>}
            </div>
        </>
    );
}
