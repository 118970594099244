import API from "../../../api";
import { AddIntegrationEmbedFormInput } from "../../../types/embeds";
import { useMutation } from "@tanstack/react-query";

async function addIntegrationEmbedConfig({
  formInput,
  productId,
}: {
  formInput: AddIntegrationEmbedFormInput;
  productId: string;
}) {
  const response = await API.post(`/embed/${productId}`, {
    name: formInput.embedName,
    releaseCountLimit: formInput.releaseCountLimit,
    language: formInput.language,
    minPriority: formInput.priorityFilter,
    tags: formInput.tags,
    theme: formInput.theme,
    lowestVersionFilter: formInput.lowestVersionFilter,
    highestVersionFilter: formInput.higestVersionFilter,
    lowestDateFilter: formInput.lowestDateFilter,
    highestDateFilter: formInput.highestDateFilter,
    allowedDomains: "*",
  });

  return response.data;
}

export default function useAddIntegrationEmbedConfig({
  onSuccess,
  onError,
}: {
  onSuccess: () => void;
  onError: () => void;
}) {
  return useMutation(addIntegrationEmbedConfig, {
    onSuccess: onSuccess,
    onError: onError,
  });
}
