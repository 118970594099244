import { useMutation } from "@tanstack/react-query";
import API from "../../api";

async function handleToggleReleaseNoteStatus(rowID: string) {
    const response = await API.post(`/releaseNote/toggleStatus/${rowID}`);
    return response.data.payload;
}

function useToggleReleaseNoteStatus({
  onSuccess,
  onError,
}: {
  onSuccess: (data: any) => void;
  onError: (error: any) => void;
}) {
  return useMutation(handleToggleReleaseNoteStatus, {
    onSuccess: (data) => {
        onSuccess(data);
    },
    onError: (error) => {
      onError(error);
    },
  });
}

export default useToggleReleaseNoteStatus;