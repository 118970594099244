import React, { useState } from "react";

interface TimePickerProps {
  resolution?: number; // in minutes
  selectedDate?: Date; // date that the datepicker linked with time picker is associated with
  onTimeChange?: (newTime: Date) => void; // callback prop
}

const TimePicker: React.FC<TimePickerProps> = ({
  selectedDate,
  resolution = 5,
  onTimeChange,
}) => {
  const [currentLocalTime] = useState<Date>(new Date());
  const [selectedTime, setSelectedTime] = useState<string>("");

  function isToday(date: Date) {
    const today = new Date();

    return (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    );
  }

  const generateTimeOptions = () => {
    const times = [];
    const isTodaySelected = selectedDate ? isToday(selectedDate) : false;
    const bufferedCurrentLocalTime = new Date(
      currentLocalTime.getTime() + 30 * 60000
    ); // 60000 milliseconds in a minute; Need to schedule at least 1/2 hr in the future

    const currentHour = isTodaySelected
      ? bufferedCurrentLocalTime.getHours()
      : null;

    const currentMinute = isTodaySelected
      ? bufferedCurrentLocalTime.getMinutes()
      : null;

    for (let hour = currentHour ?? 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += resolution) {
        if (hour === currentHour && minute < (currentMinute ?? 0)) {
          continue;
        }

        const hour12 = hour % 12 === 0 ? 12 : hour % 12; // Converts 24-hour time to 12-hour format for display
        const suffix = hour < 12 ? "AM" : "PM"; // Determines AM or PM for display
        const displayTimeString = `${hour12
          .toString()
          .padStart(2, "0")}:${minute.toString().padStart(2, "0")} ${suffix}`;
        const valueTimeString = `${hour.toString().padStart(2, "0")}:${minute
          .toString()
          .padStart(2, "0")}`;

        times.push(
          <option key={valueTimeString} value={valueTimeString}>
            {displayTimeString}
          </option>
        );
      }
    }

    return times;
  };

  const handleTimeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const [hour, minutes] = event.target.value.split(":").map(Number);
    const newTime = new Date(selectedDate ?? currentLocalTime);
    newTime.setHours(hour, minutes, 0, 0);

    setSelectedTime(event.target.value);

    console.log("Selected Time: ", newTime);

    if (onTimeChange) {
      onTimeChange(newTime);
    }
  };

  return (
    <div className="flex justify-center items-center border border-solid border-gray-300 rounded bg-gray-50">
      <select
        className="form-select block w-32 px-3 py-1.5 text-base font-normal text-gray-700 bg-gray-50 bg-clip-padding border-none rounded-l transition ease-in-out focus:text-gray-700 focus:bg-white focus:outline-none"
        value={selectedTime}
        onChange={handleTimeChange}
        required
      >
        <option value="">-- : --</option>
        {generateTimeOptions()}
      </select>
    </div>
  );
};

export default TimePicker;
