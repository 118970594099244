import { useParams } from "react-router-dom";
import SettingsOrganizationLayout from "../../../components/Settings/Organization/SettingsOrganizationLayout";
import SettingsOrganizationProfile from "../../../components/Settings/Organization/SettingsOrganizationProfile";
import { useOrganizations } from "../../Organizations/hooks/useOrganizations";
import { Organization } from "../../../hooks/store/useOrgStore";
import React from "react";
import { CircularProgressIndicator } from "../../../components/Shared/Button";

export default function OrgSettingsProfilePage() {
  const { orgId } = useParams<{ orgId: string }>();

  const [organization, setOrganization] = React.useState<
    Organization | undefined
  >(undefined);

  const { data: organizations, isLoading, isFetching } = useOrganizations();

  React.useEffect(() => {
    const organization = organizations?.find((e) => {
      return e.ROWID === orgId;
    });

    setOrganization(organization);
  }, [orgId, organizations]);

  return (
    <>
      <SettingsOrganizationLayout>
        {isLoading || isFetching || !organization ? (
          <div className="min-h-screen flex justify-center items-center bg-gray-100 p-4 m-1">
            <CircularProgressIndicator />
          </div>
        ) : (
          <div className="h-full">
            <SettingsOrganizationProfile organization={organization} />
          </div>
        )}
      </SettingsOrganizationLayout>
    </>
  );
}
