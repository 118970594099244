import React, { useState } from "react";
import { useParams } from "react-router-dom";
import useQueryParams from "../../hooks/useQueryParams";
import { useSubscriberProduct } from "./hooks/useSubscriberProduct";
import SubscriberVerificationComponent from "../../components/SubscriberVerification/SubscriberVerificationComponent";
import SubscriberVerificationError from "../../components/SubscriberVerification/SubscriberVerificationError";
import SubscriberVerificationSkeleton from "../../components/SubscriberVerification/SubscriberVerificationSkeleton";
import { useConfirmRejectSubscription } from "./hooks/useConfirmRejectSubscription";

export default function SubscriberVerification() {
  const query = useQueryParams();
  const token = query.get("token");
  const { subscriberID } = useParams();
  const { product, loading, error } = useSubscriberProduct(subscriberID ?? "");

  const [showSuccess, setShowSuccess] = useState(false);
  const [showReject, setShowReject] = useState(false);
  const [showError, setShowError] = useState("");

  const { handleConfirm, handleReject, isSubmitting } =
    useConfirmRejectSubscription({
      productId: product?.id || null,
      token,
      subscriberID: subscriberID ?? "",
      onSuccess: (didConfirm) => {
        if (didConfirm) {
          setShowSuccess(true);
          setShowReject(false);
        } else {
          setShowSuccess(false);
          setShowReject(true);
        }
      },
      onError: (error) => setShowError(error),
    });

  if (loading) {
    return <SubscriberVerificationSkeleton />;
  }

  if (error || showError) {
    return <SubscriberVerificationError message={error || showError} />;
  }

  if (!product) {
    return <SubscriberVerificationError message="No product found" />;
  }

  if (showSuccess) {
    return (
      <div className="flex items-center justify-center h-full">
        <div
          className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative"
          role="alert"
        >
          <span className="block sm:inline">
            Congratulations! You are now subscribed to the updates.
          </span>
        </div>
      </div>
    );
  }

  if (showReject) {
    return (
      <div className="flex items-center justify-center h-full">
        <div
          className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
          role="alert"
        >
          <span className="block sm:inline">
            Thank you for your response. You will not recieve any update email
            from
            {" " + product.name + " "}.
          </span>
        </div>
      </div>
    );
  }

  if (product.verified) {
    return (
      <div className="flex items-center justify-center h-full">
        <div
          className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative"
          role="alert"
        >
          <span className="block sm:inline">
            You are already subscribed to this product.
          </span>
        </div>
      </div>
    );
  }

  return (
    <SubscriberVerificationComponent
      id={product.id}
      name={product.name}
      logo={product.logo}
      onConfirm={handleConfirm}
      onReject={handleReject}
      loading={isSubmitting}
    />
  );
}
