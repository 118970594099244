import { BiSolidHourglass, BiSolidPaperPlane } from "react-icons/bi";
import { ReleaseNoteWithRowId } from "../../types/releaseNotes";
import { HiClock, HiPresentationChartBar } from "react-icons/hi";
import { SendReleaseNoteGenericModalComponent } from "./PublishModal/SendReleaseNoteGenericModalComponent";
import UnscheduledUnsentEmailModalBodyComponent from "./PublishModal/UnscheduledUnsent/UnscheduledUnsentEmailModalBodyComponent";
import UpdateScheduledEmailModalBodyComponent from "./PublishModal/UpdateSchedule/UpdateScheduledEmailSendComponent";
import { PublishedNoteStatsModalBodyComponent } from "./PublishModal/Stats/PublishedNoteStatsModalBodyComponent";
import { toast } from "react-toastify";

export default function SendReleaseNoteModal({
  note,
}: {
  note: ReleaseNoteWithRowId;
}) {
  // If email has already been sent; show them the stats modal
  if (Number(note.subscriptionEmailProcessingStatus) === 1) {
    return (
      <SendReleaseNoteGenericModalComponent
        onClick={(setOpenModal) => setOpenModal(true)}
        buttonHoverLabel={"Stats"}
        buttonIcon={
          <HiPresentationChartBar className="h-4 w-4 text-gray-500 hover:text-gray-900" />
        }
        modalBody={<PublishedNoteStatsModalBodyComponent note={note} />}
        modalHeaderText="Email Statistics"
      />
    );
  }

  // If it is processing or yet to start processing; but we have already sent the request to send the email
  if (note.subscriptionEmailSendRequest) {
    return (
      <button
        className=""
        onClick={() => {
          toast.info(
            "Email is being processed. Please wait a few minutes and try again."
          );
        }}
      >
        <BiSolidHourglass className="h-4 w-4 text-gray-500 hover:text-gray-900" />
      </button>
    );
  }

  if (note.scheduleSendAt) {
    // If the email has been scheduled but not been sent then show them this modal
    return (
      <SendReleaseNoteGenericModalComponent
        onClick={(setOpenModal) => setOpenModal(true)}
        buttonHoverLabel={"Update Schedule"}
        buttonIcon={
          <HiClock className="h-4 w-4 text-gray-500 hover:text-gray-900" />
        }
        modalBody={<UpdateScheduledEmailModalBodyComponent note={note} />}
        modalHeaderText="Update Email Send Schedule"
        disableFooter={true}
      />
    );
  }

  if (!note.isPublic) {
    return (
      <SendReleaseNoteGenericModalComponent
        onClick={() => {
          toast.error("Cannot publish a draft release note.");
        }}
        buttonHoverLabel={"Publish"}
        buttonIcon={
          <BiSolidPaperPlane className="h-4 w-4 text-gray-500 hover:text-gray-900" />
        }
        modalBody={<UnscheduledUnsentEmailModalBodyComponent note={note} />}
        modalHeaderText="Publish Release Note"
      />
    );
  }

  // If the email has not been scheduled or sent or draft then show them this modal
  return (
    <SendReleaseNoteGenericModalComponent
      onClick={(setOpenModal) => setOpenModal(true)}
      buttonHoverLabel={"Publish"}
      buttonIcon={
        <BiSolidPaperPlane className="h-4 w-4 text-gray-500 hover:text-gray-900" />
      }
      modalBody={<UnscheduledUnsentEmailModalBodyComponent note={note} />}
      modalHeaderText="Publish Release Note"
    />
  );
}
