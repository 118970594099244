import {Select} from "flowbite-react";
import {Column, Table} from "@tanstack/react-table";
import {ReleaseNoteWithRowId} from "../../../types/releaseNotes";
import {useEffect} from "react";
import {string} from "yup";

interface TagFilterDropdownProps {
    tagsOptions: string[];
    table: Table<ReleaseNoteWithRowId>;
    selectedTagOption: string;
    setSelectedTagOption: (tag: string) => void;
}

export default function TagFilterDropdown({
                                              tagsOptions,
                                              table,
                                              selectedTagOption,
                                              setSelectedTagOption,
                                          }: TagFilterDropdownProps) {

    const handleTagChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedTags = e.target.value !== "" ? [e.target.value] : [];
        const tagColumn = table
            .getAllColumns()
            .find((c: Column<ReleaseNoteWithRowId>) => c.id === "tag");
        if (tagColumn) tagColumn.setFilterValue(selectedTags);

        setSelectedTagOption(selectedTags[0]);
    };

    return (
        <div className="my-2">
            <Select id="tag-filter" onChange={handleTagChange}>
                <option disabled>
                    Filter by Tag
                </option>
                <option value={""}>
                    All
                </option>
                {tagsOptions.map((tag) => (
                    <option key={tag} value={tag} selected={selectedTagOption === tag}>
                        {tag}
                    </option>
                ))}
            </Select>
        </div>
    );
}
