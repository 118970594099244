import React from 'react';
import {Button, Label, Textarea, TextInput} from "flowbite-react";
import {useFormik} from "formik";
import * as Yup from "yup";
import {ReleaseNoteWithRowId} from "../../../types/releaseNotes";
import useAddPublicFeedback from "../../../hooks/feedback/useAddFeedback";
import {toast} from "react-toastify";

const PublicFeedbackForm = (
    {
        email,
        productID,
        noteID,
        onSubmit,
        setShowThankYou,
    }: {
        email: string;
        productID: string;
        noteID: string;
        onSubmit: (data: any) => void;
        setShowThankYou: (value: boolean) => void;
    }
) => {


    const formik = useFormik({
        initialValues: {
            email: email,
            feedback: '',
            feedbackType: 'happy',
        },
        validationSchema: Yup.object({
            feedback: Yup.string().required('Feedback is required'), // Ensure feedback is not empty
            feedbackType: Yup.string().oneOf(['happy', 'unhappy']).required('Feedback type is required'), // Ensure feedback type is either happy or unhappy
        }),
        onSubmit: (values) => {
            onSubmit({
                feedbackData: {
                    email: values.email,
                    feedback: values.feedback,
                    isLike: values.feedbackType === 'happy',
                },
                productID: productID,
                noteID: noteID,
            })
        },
    });

    return (
        <form className="p-6 bg-white rounded-lg shadow-md text-center md:w-1/3 w-1/2" onSubmit={formik.handleSubmit}>
            <h2 className="text-2xl font-bold mb-4">Give Feedback</h2>
            <p className="mb-4 text-gray-700">How do you feel about this release? </p>
            <div className="flex justify-center space-x-4 mb-4">
                <button onClick={(e) => {
                    e.preventDefault()
                    formik.setFieldValue('feedbackType', 'happy')
                }}>
                    <div className={"flex flex-col space-y-2"}>
                        <div className={
                            formik.values.feedbackType === "happy" ?
                                "px-2 py-2 border border-gray-200 text-4xl text-gray-100 cursor-pointer rounded-md bg-amber-100" :
                                "px-2 py-2 border border-gray-200 text-4xl cursor-pointer rounded-md bg-white"
                        }>
                            😊
                        </div>
                        <div className="text-gray-700 text-sm">
                            Happy
                        </div>
                    </div>
                </button>
                <button onClick={(e) => {
                    e.preventDefault()
                    formik.setFieldValue('feedbackType', 'unhappy')
                }}>
                    <div className="flex flex-col space-y-2">
                        <div className={
                            formik.values.feedbackType === "unhappy" ?
                                "px-2 py-2 border border-gray-200 text-4xl text-gray-100 cursor-pointer rounded-md bg-amber-100" :
                                "px-2 py-2 border border-gray-200 text-4xl cursor-pointer rounded-md bg-white"
                        }>
                            🥲
                        </div>
                        <div className="text-gray-700 text-sm">
                            Unhappy
                        </div>
                    </div>
                </button>

            </div>
            <div className="mt-8 flex flex-col space-y-6">
                <div className="text-left">
                    <Label htmlFor="email" value="Email"/>
                    <TextInput id="email" {...formik.getFieldProps('email')} addon="@" disabled/>
                </div>
                <div className="text-left">
                    <Label htmlFor="feedback" value="Share your thoughts on this release"/>
                    <Textarea
                        id="feedback"
                        {...formik.getFieldProps('feedback')}
                        placeholder="Your feedback here..."
                        required
                        rows={4}
                    />
                    {formik.touched.feedback && formik.errors.feedback ? (
                        <div className="text-red-500 text-sm">{formik.errors.feedback}</div>
                    ) : null}
                </div>
                <div className="flex flex-row justify-center mt-4 space-x-2">
                    <Button
                        isProcessing={formik.isSubmitting}
                        type="submit"
                        color={'blue'}
                    >
                        Submit Feedback
                    </Button>
                    <button
                        type="button"
                        className="w-40 px-4 py-2 border-gray-200 border bg-gray-50 text-black rounded hover:bg-gray-100 focus:outline-none focus:shadow-outline"
                        onClick={() => {
                            setShowThankYou(true);
                        }}
                    >
                        Cancel
                    </button>
                </div>
            </div>
        </form>
    );
};

export default PublicFeedbackForm;
