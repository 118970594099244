import API from "../../../api";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { ReleaseNote } from "../../../types/releaseNotes";

const fetchReleaseNotes = async (prodId: string) => {
  const response = await API.get(`/releaseNote/${prodId}`);
  return response.data.payload;
};

const fetchReleaseNoteById = async (releaseNoteID: string) => {
  const response = await API.get(`/releaseNote/note/${releaseNoteID}`);
  return response.data.payload;
};

const postReleaseNote = async ({
  releaseNote,
  prodId,
}: {
  releaseNote: ReleaseNote;
  prodId: string;
}) => {
  const response = await API.post(
    `/releaseNote/${prodId}`,
    JSON.stringify(releaseNote),
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return response.data.payload;
};

const postScheduleReleaseNote = async ({
  releaseNoteID,
  scheduledDateTime,
}: {
  releaseNoteID: string;
  scheduledDateTime: string;
}) => {
  const response = await API.post(
    `/releaseNote/publish/schedule/${releaseNoteID}`,
    JSON.stringify({
      scheduledDateTime: scheduledDateTime,
    }),
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return response.data.payload;
};

const postImmidiateScheduleReleaseNoteEmail = async ({
  releaseNoteID,
}: {
  releaseNoteID: string;
}) => {
  const response = await API.post(
    `/releaseNote/publish/${releaseNoteID}`,
    {},
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return response.data.payload;
};

const updateReleaseNoteAPI = async ({
  releaseNote,
  releaseNoteID,
}: {
  releaseNote: ReleaseNote;
  releaseNoteID: string;
}) => {
  const response = await API.put(
    `/releaseNote/${releaseNoteID}`,
    JSON.stringify(releaseNote),
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return response.data.payload;
};

export const useReleaseNotes = (prodId: string) => {
  return useQuery(["releaseNotes", prodId], () => fetchReleaseNotes(prodId), {
    staleTime: 5 * 60 * 1000, // 5 minutes in milliseconds
  });
};

export const useReleaseNoteById = (releaseNoteID: string) => {
  console.log("releaseNoteID");
  return useQuery(["releaseNoteByID", releaseNoteID], () =>
    fetchReleaseNoteById(releaseNoteID)
  );
};

export const usePublishReleaseNote = () => {
  const queryClient = useQueryClient();
  return useMutation(postReleaseNote, {
    onSuccess: () => {
      // Invalidate and refetch queries
      queryClient.invalidateQueries(["releaseNotes"]);
    },
  });
};

export const useUpdateReleaseNote = ({
  releaseNoteID,
}: {
  releaseNoteID: string;
}) => {
  const queryClient = useQueryClient();
  return useMutation(updateReleaseNoteAPI, {
    onSuccess: () => {
      // Invalidate and refetch queries
      queryClient.invalidateQueries(["releaseNotes"]);
      queryClient.invalidateQueries(["releaseNoteByID", releaseNoteID]);
    },
  });
};

export const useScheduleReleaseNoteImmidiateSend = ({
  onSuccess,
  onError,
}: {
  onSuccess: () => void;
  onError: () => void;
}) => {
  const queryClient = useQueryClient();
  return useMutation(postImmidiateScheduleReleaseNoteEmail, {
    onSuccess: () => {
      queryClient.invalidateQueries(["releaseNotes"]);
      onSuccess();
    },
    onError: onError,
  });
};

export const useScheduleReleaseNote = ({
  onSuccess,
  onError,
}: {
  onSuccess: () => void;
  onError: () => void;
}) => {
  const queryClient = useQueryClient();
  return useMutation(postScheduleReleaseNote, {
    onSuccess: () => {
      queryClient.invalidateQueries(["releaseNotes"]);
      onSuccess();
    },
    onError: onError,
  });
};
