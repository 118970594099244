import {Toaster} from "react-hot-toast";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import ProtectedRoutes from "./components/ProtectedRoute";
import EntryPoint from "./EntryPoint";
import LoginForm from "./pages/Auth";
import Login from "./pages/Auth/Login";
import SignUp from "./pages/Auth/SignUp";
import ErrorPage from "./pages/Error";
import Dashboard from "./pages/Dashboard";
import RedirectRoute from "./pages/Home";
import Embed from "./pages/Integration/Embeds/add";
import EmbedForm from "./pages/Integration/Embeds/add/add_embed";
import Landing from "./pages/Landing";
import LandingSignUp from "./pages/Landing/signup";
import SubscriberList from "./pages/Subscriber";
import SubscriberAddPage from "./pages/Subscriber/add";
import SubscriberVerification from "./pages/SubscriberVerification";
import EditReleaseNotePage from "./pages/ReleaseNotes/views/EditReleaseNotePage";
import AddNotePage from "./pages/ReleaseNotes/views/AddReleaseNotePage";
import DefaultSettingOrganizationsListPage from "./pages/Settings/DefaultSettings/organizations";
import DefaultSettingProfilePage from "./pages/Settings/DefaultSettings/profile";
import DefaultSettingSecurityPage from "./pages/Settings/DefaultSettings/security";
import OrgSettingsProfilePage from "./pages/Settings/OrgSettings/profile";
import OrgSettingsMembersPage from "./pages/Settings/OrgSettings/members";
import OrgSettingsProductsPage from "./pages/Settings/OrgSettings/products";
import ViewReleaseNotesTablePage from "./pages/ReleaseNotes/views/ViewReleaseNotesTablePage";
import UnsubscribePage from "./pages/SubscriberVerification/unsubscribe";
import IntegrationEmbedListView from "./pages/Integration/Embeds/list";
import IntegrationEmbedThemeListView from "./pages/Integration/Embeds/themes/list";
import FeedbackListTablePage from "./pages/Feedback";
import PublicFeedbackPage from "./pages/Feedback/publicEmailFeedbackGather";
import SubscriberPreferencePage from "./pages/SubscriberPreference";

const router = createBrowserRouter([
    {
        path: "/app",
        element: <EntryPoint/>,
        errorElement: <ErrorPage/>,
        children: [
            {
                path: "",
                children: [
                    {
                        path: "",
                        element: <Landing/>,
                    },
                    {
                        path: "new",
                        element: <LandingSignUp/>,
                    },
                ],
            },
            {
                path: "redirect",
                element: <RedirectRoute/>,
            },
            {
                path: "embed/:embedID",
                element: <Embed/>,
            },
            {
                path: ":orgId",
                children: [
                    {
                        path: "notes",
                        children: [
                            {
                                path: ":prodId",
                                children: [
                                    {
                                        path: "dash",
                                        element: (
                                            <ProtectedRoutes>
                                                <Dashboard/>
                                            </ProtectedRoutes>
                                        ),
                                    },
                                    {
                                        path: "edit/:releaseNoteID",
                                        element: (
                                            <ProtectedRoutes>
                                                <EditReleaseNotePage/>
                                            </ProtectedRoutes>
                                        ),
                                    },
                                    {
                                        path: "subscribers",
                                        children: [
                                            {
                                                path: "",
                                                element: (
                                                    <ProtectedRoutes>
                                                        <SubscriberList/>
                                                    </ProtectedRoutes>
                                                ),
                                            },
                                            {
                                                path: "add",
                                                element: (
                                                    <ProtectedRoutes>
                                                        <SubscriberAddPage/>
                                                    </ProtectedRoutes>
                                                ),
                                            },
                                        ],
                                    },
                                    {
                                        path: "add",
                                        element: (
                                            <ProtectedRoutes>
                                                <AddNotePage/>
                                            </ProtectedRoutes>
                                        ),
                                    },
                                    {
                                        path: "embed/add",
                                        element: (
                                            <ProtectedRoutes>
                                                <EmbedForm/>
                                            </ProtectedRoutes>
                                        ),
                                    },
                                    {
                                        path: "",
                                        element: (
                                            <ProtectedRoutes>
                                                <ViewReleaseNotesTablePage/>
                                            </ProtectedRoutes>
                                        ),
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        path: ":prodId",
                        children: [
                            {
                                path: "integration",
                                children: [
                                    {
                                        path: "embeds",
                                        children: [
                                            {
                                                path: "",
                                                element: (
                                                    <ProtectedRoutes>
                                                        <IntegrationEmbedListView/>
                                                    </ProtectedRoutes>
                                                ),
                                            },
                                            {
                                                path: "theme",
                                                element: (
                                                    <ProtectedRoutes>
                                                        <IntegrationEmbedThemeListView/>
                                                    </ProtectedRoutes>
                                                ),
                                            },
                                        ],
                                    },
                                ],
                            },
                            {
                                path: "feedback",
                                element: (
                                    <ProtectedRoutes>
                                        <FeedbackListTablePage/>
                                    </ProtectedRoutes>
                                ),
                            },
                        ],
                    },
                ],
            },
            {
                path: "settings",
                children: [
                    {
                        path: "",
                        element: <DefaultSettingProfilePage/>,
                    },
                    {
                        path: "security",
                        element: <DefaultSettingSecurityPage/>,
                    },
                    {
                        path: "organizations",
                        element: <DefaultSettingOrganizationsListPage/>,
                    },
                    {
                        path: "organization/:orgId",
                        children: [
                            {
                                path: "", // Organization Profile Page
                                element: <OrgSettingsProfilePage/>,
                            },
                            {
                                path: "members", // Organization Members Page
                                element: <OrgSettingsMembersPage/>,
                            },
                            {
                                path: "products", // Organization Products Page
                                element: <OrgSettingsProductsPage/>,
                            },
                        ],
                    },
                ],
            },
            {
                path: "auth",
                children: [
                    {
                        path: "login",
                        element: <LoginForm/>,
                    },
                    {
                        path: "signUp",
                        element: <SignUp/>,
                    },
                ],
            },
            {
                path: "public/subscribers/verify/:subscriberID",
                element: <SubscriberVerification/>,
            },
            {
                path: "public/subscribers/preference/:subscriberID",
                element: <SubscriberPreferencePage/>
            },
            {
                path: "public/feedback/release-note/:releaseNoteID/subscriber/:subscriberID",
                element: <PublicFeedbackPage/>,
            },
            {
                path: "public/subscribers/unsubscribe/:subscriberID",
                element: <UnsubscribePage/>,
            },
            {
                path: "*",
                element: <Dashboard/>,
            },
        ],
    },
    {
        path: "/__catalyst/auth/login",
        element: <Login/>,
    },
]);

function App() {
    return (
        <div id="app" className="bg-gray-50">
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <Toaster/>
            <RouterProvider router={router}/>
        </div>
    );
}

export default App;
