import { useState } from "react";
import { toast } from "react-toastify";
import { useQueryClient } from "@tanstack/react-query";
import { BiLoaderAlt } from "react-icons/bi";
import { HiLockClosed } from "react-icons/hi";
import { Modal, Button } from "flowbite-react";

import useToggleReleaseNoteStatus from "../../../hooks/releasenote/useToggleReleaseNoteStatus";
import { ReleaseNoteWithRowId } from "../../../types/releaseNotes";

export default function ToggleReleaseNoteStatus({
  prodID,
  releaseNote,
}: {
  prodID: string;
  releaseNote: ReleaseNoteWithRowId;
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const queryClient = useQueryClient();

  const { mutate } = useToggleReleaseNoteStatus({
    onSuccess: () => {
      setIsLoading(false);
      queryClient.invalidateQueries(["releaseNotes", prodID]);
      toast.success("Release note status updated.");
      setIsModalOpen(false);
    },
    onError: () => {
      setIsLoading(false);
      toast.error("Failed to update release note status.");
      setIsModalOpen(false);
    },
  });

  function handleToggleStatus() {
    setIsModalOpen(true);
  }

  function handleSubmit() {
    setIsLoading(true);
    mutate(releaseNote.ROWID);
  }

  const getPriorityLabel = (level: string) => {
    switch (level) {
      case '1':
        return 'Patch Release';
      case '3':
        return 'Minor Release';
      case '5':
        return 'Major Release';
      default:
        return `Level ${level}`;
    }
  };


  return (
    <div>
      <button
        className="bg-gray-200 text-gray-500 hover:text-gray-900 rounded-md px-1 py-1 dark:bg-gray-700"
        onClick={handleToggleStatus}
      >
        <div className="relative group">
          {isLoading ? (
            <BiLoaderAlt className="animate-spin h-4 w-4 text-gray-500 hover:text-gray-900" />
          ) : (
            <HiLockClosed className="h-4 w-4 text-gray-500 hover:text-gray-900" />
          )}
          <span className="absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-6 w-auto px-2 py-1 bg-black text-white text-xs rounded hidden group-hover:block">
            Toggle Status
          </span>
        </div>
      </button>

      <Modal show={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <Modal.Header>
          Are you sure you want to make this release note public?
        </Modal.Header>
        <Modal.Body>
          <div className="space-y-4">
            <p className="text-base leading-relaxed text-gray-600 dark:text-gray-200">
              <b>Note:</b>
              <i>
                This action is irreversible and will make this release note
                public and visible to everyone with the following
                configurations.
              </i>
            </p>
            <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow">
              <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-2">
                Release Note Details
              </h3>
              <div className="space-y-2">
                <p className="text-gray-600 dark:text-gray-400">
                  <span className="font-medium text-gray-900 dark:text-white">
                    Title:
                  </span>{" "}
                  {releaseNote.title}
                </p>
                <div className="flex">
                  <p className="text-gray-600 dark:text-gray-400 flex-1">
                    <span className="font-medium text-gray-900 dark:text-white">
                      Tags:
                    </span>{" "}
                    {releaseNote.tag.join(", ")}
                  </p>
                  <p className="text-gray-600 dark:text-gray-400 flex-1">
                    <span className="font-medium text-gray-900 dark:text-white">
                      Language:
                    </span>{" "}
                    {releaseNote.language}
                  </p>
                </div>
                <div className="flex">
                  <p className="text-gray-600 dark:text-gray-400 flex-1">
                    <span className="font-medium text-gray-900 dark:text-white">
                      Version:
                    </span>{" "}
                    {releaseNote.version}
                  </p>
                  <p className="text-gray-600 dark:text-gray-400 flex-1">
                    <span className="font-medium text-gray-900 dark:text-white">
                      Update Granularity:
                    </span>{" "}
                    {getPriorityLabel(releaseNote.priority.toString())}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button
              onClick={handleSubmit}
              color="success"
              isProcessing={isLoading}
          >
            Confirm
          </Button>
          <Button color="gray" onClick={() => setIsModalOpen(false)}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
