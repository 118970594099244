import React from "react";
import { Organization } from "../../../hooks/store/useOrgStore";
import OrganizationLogoCard from "./Profile/OrganizationLogoCard";

type SettingsOrganizationProfileProps = {
  organization: Organization;
};

const SettingsOrganizationProfile: React.FC<
  SettingsOrganizationProfileProps
> = ({ organization }) => {
  return (
    <div className="flex flex-row h-full">
      <div className="h-full w-full">
        <OrganizationLogoCard
          organization={organization}
          onSaveLogo={() => {}}
        />
      </div>
    </div>
  );
};

export default SettingsOrganizationProfile;
