import React from "react";

type VersionComponentProps = {
  value: string;
  onChange: (value: string) => void;
};

export const VersionComponent: React.FC<VersionComponentProps> = ({
  value,
  onChange,
}) => {
  return (
    <>
      <label
        htmlFor="version"
        className="block text-gray-700 text-md font-semibold mb-2"
      >
        Release Version
      </label>
      <input
        id="version"
        type="number"
        min={0}
        step={0.001}
        required
        className="w-full my-1 border-solid border-2 border-gray-300 rounded p-2 mb-4"
        value={value}
        onChange={(e) => onChange(e.target.value)}
      />
    </>
  );
};
