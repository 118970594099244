import React from 'react';
import PublicFeedbackForm from "../../components/Feedback/PublicFeedback/PublicFeedbackForm";
import FeedbackPageSkeleton from "../../components/Feedback/PublicFeedback/PublicFeedbackSkeleton";
import {useParams} from "react-router-dom";
import usePublicFeedbackGetInfo from "../../hooks/feedback/usePublicFeedbackGetInfo";
import useAddPublicFeedback from "../../hooks/feedback/useAddFeedback";
import {toast} from "react-toastify";

const PublicFeedbackPage: React.FC = () => {
    const {releaseNoteID, subscriberID} = useParams();
    const [showThankYou, setShowThankYou] = React.useState(false);

    const {data, isLoading} = usePublicFeedbackGetInfo({
        releaseNoteID: releaseNoteID ?? "",
        subscriberID: subscriberID ?? "",
    });

    const {mutate: addFeedback} = useAddPublicFeedback({
        onSuccess: (data) => {
            toast.success("Your feedback has been recorded.");
            setShowThankYou(true);
        },
        onError: (error) => {
            toast.error("Unable to record your feedback. Please try again in a while.");
        },
    });

    if (releaseNoteID === "test" && subscriberID === "test") {
        return (
            <div
                className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-r from-blue-100 to-orange-100">
                <div className="p-6 text-center bg-white shadow-xl rounded-lg">
                    <div className="mb-4">
                        <h1 className="text-4xl font-bold text-black mb-2">Hola!</h1>
                        <h2 className="text-lg italic text-orange-400">Hope your tests are running well</h2>
                    </div>
                    <p className="text-gray-700 text-base">
                        Unfortunately, feedback submission is not enabled for test emails.
                    </p>
                    <div className="mt-6">
                        <a href="/home"
                           className="inline-block bg-orange-600 text-white font-bold py-2 px-4 rounded hover:bg-orange-700 transition-colors duration-150">
                            Return Home
                        </a>
                    </div>
                </div>
            </div>
        );
    }


    if (isLoading || !data || !(data.subscriber?.Email)) {
        return <FeedbackPageSkeleton/>
    }


    if (showThankYou) {
        return (
            <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
                <div className="flex flex-col items-center justify-center space-y-4">
                    <h1 className="text-2xl font-bold">Thank you for your feedback!</h1>
                    <p className="text-gray-700">Your feedback has been recorded.</p>
                </div>
            </div>
        );
    }

    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
            <PublicFeedbackForm
                setShowThankYou={setShowThankYou}
                onSubmit={addFeedback}
                email={data.subscriber.Email}
                noteID={data.releaseNote.ROWID}
                productID={data.releaseNote.ProductID}
            />
        </div>
    );
};

export default PublicFeedbackPage;
