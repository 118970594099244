import {Button, Label, Modal, Select, TextInput} from "flowbite-react";
import {useState} from "react";
import {HiEnvelope, HiKey} from "react-icons/hi2";
import {useFormik} from "formik";
import {HiMail} from "react-icons/hi";
import * as Yup from "yup";
import useAddOrUpdateEmailConfig from "../../hooks/emailconfig/useAddOrUpdateEmailConfig";
import useValidateEmailConfig from "../../hooks/emailconfig/useValidateEmailConfig";
import {CircularProgressIndicator} from "../Shared/Button";

export default function OrganizationProductEmailConfigModal({productID}: { productID: string }) {
    const [openModal, setOpenModal] = useState(false);

    const {mutate, isLoading} = useAddOrUpdateEmailConfig({
        productID,
        onSuccess: () => {
            setOpenModal(false);
        },
        onError: () => {
            setOpenModal(false);
        }
    })

    const {
        data: emailConfigValidation,
        isLoading: emailConfigValLoading,
        isFetching: emailConfigValFetching,
    } = useValidateEmailConfig({productID: productID});


    const formik = useFormik({
        initialValues: {
            "type": "sendgrid-template",
            "fromEmail": "",
            "apiKey": "",
            "templateID": "",
        },
        validationSchema: Yup.object({
            type: Yup.string().oneOf(["sendgrid-api", "sendgrid-template"]).required("Required"),
            fromEmail: Yup.string().email("Invalid email").required("Required"),
            apiKey: Yup.string().required("Required"),
            templateID: Yup.string().when("type", (type: string[], schema) => {
                return type[0] === "sendgrid-template" ? schema.required("Required") : schema.notRequired();
            }),
        }),

        onSubmit: (values, formikHelpers) => {
            mutate({
                "type": values.type,
                "fromEmail": values.fromEmail,
                "apiKey": values.apiKey,
                "templateId": values.templateID,
            });
        }
    })


    return (
        <>
            <div className="relative group">
                <button
                    onClick={() => setOpenModal(true)}
                    className="text-gray-500 rounded-sm focus:outline-none"
                >
                    <HiEnvelope className="h-4 w-4"/>
                    <span
                        className="absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-6 w-auto px-2 py-1 bg-black text-white text-xs rounded hidden group-hover:block">
                      Configure_Sender
                </span>
                </button>
            </div>

            <Modal show={openModal} size="2xl" onClose={() => setOpenModal(false)}>
                <Modal.Header>
                    <h2 className="text-gray-700 font-bold ">Email Sender Configuration</h2>
                </Modal.Header>
                <Modal.Body>
                    {(emailConfigValLoading || !emailConfigValidation || emailConfigValFetching)
                        ? (
                            <div
                                className="h-[50vh] flex justify-center items-center p-4 m-1">
                                <CircularProgressIndicator/>
                            </div>
                        ) : (
                            <>
                                {emailConfigValidation?.helperText &&
                                    <div className="p-4 bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 mb-4">
                                        <h4 className="font-semibold mb-2">Important Information:</h4>
                                        <ul className="list-disc pl-5">
                                            <li>
                                                This product has been already configured to send
                                                from: {emailConfigValidation?.fromEmail}
                                            </li>
                                        </ul>
                                    </div>}


                                <form onSubmit={formik.handleSubmit}>
                                    <div className="mb-2">
                                        <div className="mb-2 block">
                                            <Label htmlFor="type" value="Select type of configuration"/>
                                        </div>
                                        <Select id="type"
                                                required
                                                color={formik.errors.type && formik.touched.type ? "failure" : "blue"}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.type}
                                                helperText={formik.errors.type && formik.touched.type ? "Invalid Selection" : ""}
                                        >
                                            <option value="sendgrid-api">Sendgrid Direct</option>
                                            <option value="sendgrid-template">Sendgrid Template</option>
                                        </Select>
                                    </div>
                                    <div className="mb-2">
                                        <div className="mb-2 block">
                                            <Label htmlFor="fromEmail" value="Your email"/>
                                        </div>
                                        <TextInput
                                            id="fromEmail"
                                            rightIcon={HiMail}
                                            placeholder="noreply@abcinc.com"
                                            required
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.fromEmail}
                                            color={formik.errors.fromEmail && formik.touched.fromEmail ? "failure" : "blue"}
                                            helperText={formik.errors.fromEmail && formik.touched.fromEmail ? "Invalid Email" : ""}
                                        />
                                    </div>
                                    <div className="mb-2">
                                        <div className="mb-2 block">
                                            <Label htmlFor="apiKey" value="API Key"/>
                                        </div>
                                        <TextInput
                                            id="apiKey"
                                            rightIcon={HiKey}
                                            required
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.apiKey}
                                            color={formik.errors.apiKey && formik.touched.apiKey ? "failure" : "blue"}
                                            helperText={formik.errors.apiKey && formik.touched.apiKey ? formik.errors.apiKey : ""}
                                        />
                                    </div>
                                    {formik.values.type === "sendgrid-template" && (
                                        <div className="mb-2">
                                            <div className="mb-2 block">
                                                <Label htmlFor="templateID" value="Sendgrid Template ID"/>
                                            </div>
                                            <TextInput
                                                id="templateID"
                                                rightIcon={HiMail}
                                                placeholder="sg-1100..."
                                                required
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.templateID}
                                                color={formik.errors.templateID && formik.touched.templateID ? "failure" : "blue"}
                                                helperText={formik.errors.templateID && formik.touched.templateID ? "Invalid templateID" : ""}
                                            />
                                        </div>
                                    )}
                                    <div className="mt-4">
                                        <Button color={"blue"} onBlur={formik.handleBlur} type="submit"
                                                isProcessing={isLoading}>
                                            Save Configuration
                                        </Button>
                                    </div>
                                </form>
                            </>
                        )
                    }
                </Modal.Body>
            </Modal>
        </>
    );
}