import API from "../../api";
import {useQuery} from "@tanstack/react-query";

async function getPublishedNoteStats(noteID: string) {
    const response = await API.get("/note/stats/" + noteID);
    const data = response.data.payload;

    return {
        publishedAt: new Date(data.publishedAt).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
        }),
        emailsSent: data.emailsSent,
        currentSubscribers: data.currentSubscribers,
        currentUnverifiedSubscribers: data.currentUnverifiedSubscribers,
    };
}

export default function useGetPublishedNoteStats(
    {
        noteID,
        onSuccess,
        onError,
    }: {
        noteID: string;
        onSuccess: (data: any) => void;
        onError: (error: any) => void;
    }) {
    return useQuery(["publishedNoteStats", noteID], () => getPublishedNoteStats(noteID), {
        onSuccess: onSuccess,
        onError: onError,
    });
}
