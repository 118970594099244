// Define the interface
interface IIntegrationEmbedConfig {
  ROWID: bigint;
  CREATORID: bigint;
  CREATEDTIME: string;
  MODIFIEDTIME: string;
  ProductID: string;
  Label: string;
  AllowedDomain: string;
  ThemeColor: string;
  TextColor: string;
  AccentColor: string;
  PriorityFilter: number | null;
  LanguageFilter: string | null;
  LowestDateFilter: string | null;
  HighestDateFilter: string | null;
  CSUserIDs: string | null;
  LowestVersionFilter: number | null;
  HighestVersionFilter: number | null;
  NoOfProducts: number | null;
  CSTagIDs: string | null;
}

export class IntegrationEmbedConfig implements IIntegrationEmbedConfig {
  ROWID: bigint;
  CREATORID: bigint;
  CREATEDTIME: string;
  MODIFIEDTIME: string;
  Label: string;
  ProductID: string;
  AllowedDomain: string;
  ThemeColor: string;
  TextColor: string;
  AccentColor: string;
  PriorityFilter: number | null;
  LanguageFilter: string | null;
  LowestDateFilter: string | null;
  HighestDateFilter: string | null;
  CSUserIDs: string | null;
  LowestVersionFilter: number | null;
  HighestVersionFilter: number | null;
  NoOfProducts: number | null;
  CSTagIDs: string | null;

  constructor(config: IIntegrationEmbedConfig) {
    this.ROWID = config.ROWID;
    this.CREATORID = config.CREATORID;
    this.CREATEDTIME = config.CREATEDTIME;
    this.MODIFIEDTIME = config.MODIFIEDTIME;
    this.ProductID = config.ProductID;
    this.AllowedDomain = config.AllowedDomain;
    this.ThemeColor = config.ThemeColor;
    this.TextColor = config.TextColor;
    this.AccentColor = config.AccentColor;
    this.PriorityFilter = config.PriorityFilter;
    this.LanguageFilter = config.LanguageFilter;
    this.LowestDateFilter = config.LowestDateFilter;
    this.HighestDateFilter = config.HighestDateFilter;
    this.CSUserIDs = config.CSUserIDs;
    this.LowestVersionFilter = config.LowestVersionFilter;
    this.HighestVersionFilter = config.HighestVersionFilter;
    this.NoOfProducts = config.NoOfProducts;
    this.CSTagIDs = config.CSTagIDs;
    this.Label = config.Label;
  }

  getTagFilterList(): string[] {
    return this.CSTagIDs?.split(",") ?? [];
  }

  getAllowedDomainList(): string[] {
    return this.AllowedDomain.split(",");
  }

  getCSUserIDsList(): string[] {
    return this.CSUserIDs?.split(",") ?? [];
  }

  getCreatedAt(): Date {
    return new Date(this.CREATEDTIME);
  }

  getModifiedAt(): Date {
    return new Date(this.MODIFIEDTIME);
  }
}
