import { Table } from "flowbite-react";
import { IntegrationEmbedConfig } from "../../../types/integrationEmbeds";
import { DeleteIntegrationEmbedModalComponent } from "./DeleteIntegrationEmbedModalComponent";

const getPriorityLabel = (level: string) => {
  switch (level) {
    case '0':
      return 'All Releases';
    case '1':
      return 'Patch Release and above';
    case '3':
      return 'Minor Release and above';
    case '5':
      return 'Major Releases only';
    default:
      return `Level ${level}`;
  }
};


export default function EmbedsListTableComponent({
  embeds,
}: {
  embeds: IntegrationEmbedConfig[];
}) {
  return (
    <>
      <Table>
        <Table.Head>
          <Table.HeadCell className="bg-gray-200">ID</Table.HeadCell>
          <Table.HeadCell className="bg-gray-200">Label</Table.HeadCell>
          <Table.HeadCell className="bg-gray-200">Created At</Table.HeadCell>
          <Table.HeadCell className="bg-gray-200">
            Version Filter
          </Table.HeadCell>
          <Table.HeadCell className="bg-gray-200">Items Limit</Table.HeadCell>
          <Table.HeadCell className="bg-gray-200">
            Allowed Domain
          </Table.HeadCell>
          <Table.HeadCell className="bg-gray-200">
            Granularity
          </Table.HeadCell>
          <Table.HeadCell className="bg-gray-200">
            <span className="sr-only">Actions</span>
          </Table.HeadCell>
        </Table.Head>
        <Table.Body className="divide-y">
          {embeds.map((embed) => (
            <Table.Row
              key={embed.ROWID.toString()}
              className="bg-gray-50 dark:border-gray-700 dark:bg-gray-800"
            >
              <Table.Cell>{embed.ROWID.toString()}</Table.Cell>
              <Table.Cell>{embed.Label}</Table.Cell>
              <Table.Cell>{embed.getCreatedAt().toLocaleString()}</Table.Cell>
              <Table.Cell>
                {embed.LowestVersionFilter} - {embed.HighestVersionFilter}
              </Table.Cell>
              <Table.Cell>{embed.NoOfProducts}</Table.Cell>
              <Table.Cell>{embed.AllowedDomain}</Table.Cell>
              <Table.Cell>{getPriorityLabel((embed.PriorityFilter ?? 0).toString())}</Table.Cell>
              <Table.Cell className="flex space-x-1">
                <DeleteIntegrationEmbedModalComponent
                  productID={embed.ProductID.toString()}
                  embedID={embed.ROWID.toString()}
                  embedLabel={embed.Label}
                />
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </>
  );
}
