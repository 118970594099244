import { Select } from "flowbite-react";
import { Column, Table } from "@tanstack/react-table";
import { ReleaseNoteWithRowId } from "../../../types/releaseNotes";

interface StatusFilterDropDownProps {
  table: Table<ReleaseNoteWithRowId>;
}

export default function StatusFilterDropDown({
  table,
}: StatusFilterDropDownProps) {
  const handleVisibltiyFilterChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const visiblityFilter = e.target.value !== "" ? e.target.value : "all";
    const visiblityColumn = table
      .getAllColumns()
      .find((c: Column<ReleaseNoteWithRowId>) => c.id === "isPublic");

    if (visiblityColumn) visiblityColumn.setFilterValue(visiblityFilter);
  };

  return (
    <div className="my-2">
      <Select id="tag-filter" onChange={handleVisibltiyFilterChange}>
        <option selected disabled>
          Filter by visiblity
        </option>
        <option selected value={"all"}>
          All
        </option>
        <option value={"draft"}>Draft Only</option>
        <option value={"public"}>Public Only</option>
      </Select>
    </div>
  );
}
