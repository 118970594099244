import { Alert, Button, Modal } from "flowbite-react";
import { useFormik } from "formik";
import { useState } from "react";
import { HiPencil } from "react-icons/hi";
import * as Yup from "yup";
import useUpdateMemberRole from "../../../../hooks/members/useUpdateMemberRole";
import { toast } from "react-toastify";
import { useQueryClient } from "@tanstack/react-query";

interface Props {
  memberId: string;
  name: string;
  currentRole: string;
  orgId: string;
}

export default function OrganizationMemberUpdateRoleModel({
  memberId,
  orgId,
  name,
  currentRole,
}: Props) {
  const [isOpen, setIsOpen] = useState(false);

  const queryClient = useQueryClient();

  const { mutate, isLoading, isError } = useUpdateMemberRole({
    onSuccess: (data) => {
      toast.success("Role updated successfully");
      queryClient.invalidateQueries(["members", orgId]);
      setIsOpen(false);
    },
    onError: (error) => {
      toast.error("Failed to update role");
    },
  });

  const formikForm = useFormik({
    initialValues: {
      role: currentRole,
    },
    validationSchema: Yup.object({
      role: Yup.string().oneOf(["admin", "editor"], "Invalid role selected"),
    }),
    onSubmit: (values) => {
      mutate({ memberId, newRole: values.role, orgId: orgId });
    },
  });

  return (
    <div>
      <div
        className="relative group"
        onClick={() => {
          formikForm.setFieldValue("role", currentRole);
          setIsOpen(true);
        }}
      >
        <HiPencil className="h-4 w-4 text-gray-500 hover:text-gray-900 mx-1" />
        <span className="absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-6 w-auto px-2 py-1 bg-black text-white text-xs rounded hidden group-hover:block">
          Edit
        </span>
      </div>
      <Modal show={isOpen} onClose={() => setIsOpen(false)}>
        <Modal.Header>Edit Role for {name}</Modal.Header>
        <Modal.Body>
          {isError && (
            <Alert color="failure" className="mt-2 mb-4">
              <h6 className="font-bold">Error:</h6>
              <p className="text-sm">
                Unable to update role. Please try again later.
              </p>
            </Alert>
          )}
          <form onSubmit={formikForm.handleSubmit}>
            <div className="mb-4">
              <label
                htmlFor="role"
                className="block text-gray-700 text-sm font-bold mb-2"
              >
                Role
              </label>
              <select
                id="role"
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                {...formikForm.getFieldProps("role")}
              >
                <option
                  value="admin"
                  selected={formikForm.values.role === "admin"}
                >
                  Admin
                </option>
                <option
                  value="editor"
                  selected={formikForm.values.role === "admin"}
                >
                  Editor
                </option>
              </select>
              {formikForm.touched.role && formikForm.errors.role ? (
                <div className="text-red-500 text-xs italic">
                  {formikForm.errors.role}
                </div>
              ) : null}
            </div>
            <div className="flex justify-end mt-4">
              <Button
                isProcessing={isLoading}
                type="submit"
                color="blue"
                size="sm"
              >
                Update
              </Button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
}
