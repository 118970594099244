import useAddProduct from "../../hooks/product/useAddProduct";
import {OrganizationProductForm} from "./OrganizationProductForm";

interface OrganizationProductAddFormProps {
    organizationID: string;
    onError: () => void;
    onSuccess: () => void;
}

export default function OrganizationProductAddForm(
    {
        organizationID,
        onError,
        onSuccess,
    }: OrganizationProductAddFormProps) {
    const {mutate: addProduct} = useAddProduct({
        onSuccess: onSuccess,
        onError: onError,
    });

    function onSubmit(values: any) {
        addProduct({
            orgID: organizationID,
            logo: values.logoFileID,
            name: values.name,
            description: values.description,
            privacyPolicy: values.privacyPolicy,
            socialsTwitter: values.socialsTwitter,
            socialsFacebook: values.socialsFacebook,
            socialsInstagram: values.socialsInstagram,
            socialsLinkedIn: values.socialsLinkedIn,
            customerContactUs: values.customerContactUs,
        });
    }

    return (
        <div>
            <OrganizationProductForm
                initialValues={{
                    doubleOptIn: true,
                    logoFileID: "",
                    name: "",
                    description: "",
                }}
                onSubmit={onSubmit}
            />
        </div>
    );
}
