import {useParams} from "react-router-dom";
import SubscriberPreferenceForm from "../../components/SubscriberPreference/SubscriberPreferenceForm";
import usePublicUpdateSubscriptionPreference from "../../hooks/subscription/usePublicUpdateSubscriptionPreference";
import {toast} from "react-toastify";

export default function SubscriberPreferencePage() {
    const {subscriberID} = useParams();


    const {mutate: updatePreferences, isLoading} = usePublicUpdateSubscriptionPreference({
        onSuccess: (data) => {
            toast.success('Preferences updated successfully');
        },
        onError: () => {
            toast.error('Failed to update preferences');
        }
    });


    return (
        <div
            className="relative py-8 min-h-screen bg-gradient-to-r from-blue-50 to-indigo-50 flex align-middle justify-center items-center">
            <div className="w-full max-w-2xl mx-auto shadow-lg rounded-lg p-8 bg-white">
                <h1 className="text-2xl font-bold mb-4">Customize Your Subscription Preferences</h1>
                <p className="text-sm text-gray-600 mb-6">Select your preferences for email notifications
                    about product releases. You can update these settings at any time.</p>

                {subscriberID && <SubscriberPreferenceForm
                    initialValues={{
                        minPriority: "0",
                        language: "en",
                        minVersion: null,
                        maxVersion: null,
                    }}
                    onSubmit={(values) => {
                        updatePreferences({
                            subscriberID: subscriberID,
                            minPriority: values.minPriority,
                            language: values.language,
                            minVersion: values.minVersion,
                            maxVersion: values.maxVersion
                        });
                    }}
                    isSubmitting={isLoading}
                />}
            </div>
        </div>
    );
}
