import { useMutation } from "@tanstack/react-query";
import API from "../../../api";
import { OrganizationInfo } from "../../../types/organization";

async function updateOrganization(organization: OrganizationInfo) {
  try {
    const response = await API.put(
      `organization/${organization.id}`,
      organization,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data.payload;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export function useUpdateOrganization(
  onSuccess: (data: any) => void,
  onError: (error: any) => void
) {
  return useMutation(updateOrganization, {
    onError: onError,
    onSuccess: onSuccess,
  });
}
