import { useMutation, useQuery } from '@tanstack/react-query';
import API from '../../../api';
import { IntegrationEmbedConfig } from '../../../types/integrationEmbeds';
import { IntegrationEmbedTheme } from '../../../types/embedThemes';

const fetchIntegrationEmbedConfigsForProduct = async (productId: string): Promise<IntegrationEmbedConfig[]> => {
    const response = await API.get(`/embed/product/${productId}`);
    return response.data.payload.embeds.map((e: any) => {
        return new IntegrationEmbedConfig(e);
    });
};

const useListIntegrationEmbedConfigsForProduct = (productId: string) => {
    return useQuery(['integration', 'embeds', productId], () => fetchIntegrationEmbedConfigsForProduct(productId), {
    });
};


const fetchIntegrationEmbedThemesForProduct = async (productId: string): Promise<IntegrationEmbedTheme[]> => {
    const response = await API.get(`/embed/themes/product/${productId}`);
    return response.data.payload.embeds.map((e: any) => {
        return e as IntegrationEmbedTheme;
    });
};

const useListIntegrationEmbedThemesForProduct = (productId: string) => {
    return useQuery(['integration', 'embeds', 'themes', productId], () => fetchIntegrationEmbedThemesForProduct(productId), {
    });
};


const postIntegrationEmbedTheme = async (data: any) => {
    const response = await API.post(`/embed/themes`, data);
    return response.data.payload;
}


const useAddIntegrationEmbedTheme = ({onSuccess, onError}: {onSuccess: (data: any) => void, onError: (data: any) => void}) => {
    return useMutation(postIntegrationEmbedTheme, {
        onSuccess: onSuccess,
        onError: onError
    });
}

export {useListIntegrationEmbedConfigsForProduct, useListIntegrationEmbedThemesForProduct,useAddIntegrationEmbedTheme};
