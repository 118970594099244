import { Button, Datepicker, Label } from "flowbite-react";
import { useEffect, useState } from "react";
import TimePicker from "../../../Shared/TimePicker";
import { useScheduleReleaseNote } from "../../../../pages/ReleaseNotes/hooks/useReleaseNotes";
import { toast } from "react-toastify";

export default function UpdateModalScheduleSendEmailComponent({
  releaseNoteID,
  initialScheduledDateTime,
  isLoading,
  setIsLoading,
}: {
  releaseNoteID: string;
  initialScheduledDateTime: Date;
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
}) {
  const [scheduledDateTime, setScheduledDateTime] = useState<Date>(
    initialScheduledDateTime
  );

  const [currentLocalDateTime] = useState<Date>(new Date());

  useEffect(() => {
    setScheduledDateTime(initialScheduledDateTime);
  }, [initialScheduledDateTime]);

  const {
    mutate,
    isError,
    error,
    isLoading: updateRequestLoading,
  } = useScheduleReleaseNote({
    onSuccess: () => {
      toast.success("Email rescheduling successful.");
    },
    onError: () => {
      toast.error("Error updating scheduled email.");
    },
  });

  function calculateRelativeTimeFromNow(targetTime: Date) {
    const now = new Date();
    const diff = targetTime.getTime() - now.getTime();

    console.log(now, targetTime, now.getTime(), targetTime.getTime(), diff);

    if (diff < 0) {
      return "Time is in the past" + diff.toString();
    }

    // Calculate days, hours, and minutes
    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    const hours = Math.floor((diff / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((diff / (1000 * 60)) % 60);

    let relativeTimeStr = "";

    if (days > 0) {
      relativeTimeStr += `${days} day${days > 1 ? "s" : ""} `;
    }
    relativeTimeStr += `${hours} hr${hours !== 1 ? "s" : ""} `;
    relativeTimeStr += `${minutes} min${minutes !== 1 ? "s" : ""} from now`;

    return relativeTimeStr;
  }

  const handleUpdateScheduleSend = () => {
    // Check if scheduledDateTime is valid
    const now = new Date();
    const twentyMinutesLater = new Date(now.getTime() + 20 * 60000); // 20 minutes in milliseconds

    if (scheduledDateTime <= twentyMinutesLater) {
      toast.error("Scheduled time must be at least 20 minutes in the future.");
      return;
    }

    toast.info(
      `Scheduling email for ${calculateRelativeTimeFromNow(scheduledDateTime)}`
    );

    // Convert to UTC
    const scheduledDateTimeUTC = new Date(
      scheduledDateTime.getTime()
    ).toISOString();

    setIsLoading(true);
    mutate({
      releaseNoteID: releaseNoteID,
      scheduledDateTime: scheduledDateTimeUTC,
    });
  };

  const handleDateChange = (date: Date) => {
    setScheduledDateTime((prevDateTime) => {
      const updatedDateTime = new Date(prevDateTime);
      updatedDateTime.setFullYear(
        date.getFullYear(),
        date.getMonth(),
        date.getDate()
      );
      return updatedDateTime;
    });
  };

  const handleTimeChange = (time: Date) => {
    setScheduledDateTime((prevDateTime) => {
      const updatedDateTime = new Date(prevDateTime);
      updatedDateTime.setHours(time.getHours(), time.getMinutes());
      return updatedDateTime;
    });
  };

  return (
    <form
      className="flex space-x-3"
      onSubmit={(e) => {
        e.preventDefault();
        if (e.currentTarget.checkValidity()) {
          handleUpdateScheduleSend();
        }
      }}
    >
      <div className="flex-1 flex flex-col space-y-2">
        <Label htmlFor="scheduleDate">Schedule Send Email</Label>
        {isError && (
          <p className="text-red-500 text-sm">
            Error scheduling email due to {`${error}`}. Please try again.
          </p>
        )}
        <div className="flex space-x-2">
          <div className="relative z-100">
            <Datepicker
              required
              minDate={currentLocalDateTime}
              id="scheduleDate"
              onSelectedDateChanged={handleDateChange}
            />
          </div>
          <TimePicker
            resolution={5}
            selectedDate={scheduledDateTime}
            onTimeChange={handleTimeChange}
          />
        </div>
      </div>
      <div className="mt-auto">
        <Button
          color="dark"
          type="submit"
          processingLabel="Sending..."
          isProcessing={updateRequestLoading}
          disabled={isLoading}
        >
          <div className="w-32">Schedule Email</div>
        </Button>
      </div>
    </form>
  );
}
