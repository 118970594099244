import { useFormik } from "formik";
import * as Yup from "yup";
import { OrganizationInfo } from "../../types/organization";
import { Button } from "flowbite-react";
import { AvatarUploadComponent } from "../Shared/AvatarUploadComponent";

export default function OrganizationProfileForm({
  organization = undefined,
  onSubmit,
}: {
  organization?: OrganizationInfo;
  onSubmit: (values: any) => void;
}) {
  const formik = useFormik({
    initialValues: {
      name: organization ? organization.name : "",
      phone: organization ? organization.phone : "",
      address: organization ? organization.address : "",
      city: organization ? organization.city : "",
      state: organization ? organization.state : "",
      zip: organization ? organization.zip : "",
      description: organization ? organization.description : "",
      logoFileID: "",
    },
    onSubmit: onSubmit,
    validationSchema: Yup.object({
      name: Yup.string().required("Organization name is required"),
      phone: Yup.string().required("Phone number is required"),
      address: Yup.string().required("Address is required"),
      city: Yup.string().required("City is required"),
      state: Yup.string().required("State is required"),
      zip: Yup.string().required("Zip is required"),
    }),
  });

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <div className="mb-4 flex align-middle justify-center">
          <div className="flex flex-col">
            <AvatarUploadComponent
              defaultImage={organization?.logo ?? ""}
              onImageUpload={(fileID) => {
                console.log("Uploaded File File ID is: ", fileID);
                formik.setFieldValue("logoFileID", fileID);
              }}
              emptyImageLabel="Click Me"
            />
            <label
              htmlFor="name"
              className="mx-auto mt-2 block text-gray-700 text-sm font-bold mb-2"
            >
              Logo
            </label>
            {formik.errors.logoFileID && (
              <div className="text-red-500 text-xs">
                {formik.errors.logoFileID}
              </div>
            )}
          </div>
        </div>
        <div className="mb-4">
          <label
            htmlFor="name"
            className="block text-gray-700 text-sm font-bold mb-2"
          >
            Organization Name
          </label>
          <input
            id="name"
            name="name"
            type="text"
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
          {formik.touched.name && formik.errors.name && (
            <div className="text-red-500 text-xs">{formik.errors.name}</div>
          )}
        </div>
        <div className="mb-4">
          <label
            htmlFor="name"
            className="block text-gray-700 text-sm font-bold mb-2"
          >
            Description
          </label>
          <input
            id="description"
            name="description"
            type="text"
            value={formik.values.description}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
          {formik.touched.description && formik.errors.description && (
            <div className="text-red-500 text-xs">
              {formik.errors.description}
            </div>
          )}
        </div>
        <div className="mb-4">
          <label
            htmlFor="phone"
            className="block text-gray-700 text-sm font-bold mb-2"
          >
            Phone Number
          </label>
          <input
            id="phone"
            name="phone"
            type="text"
            value={formik.values.phone}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
          {formik.touched.phone && formik.errors.phone && (
            <div className="text-red-500 text-xs">{formik.errors.phone}</div>
          )}
        </div>
        <div className="flex flex-row space-x-2">
          <div className="mb-4 flex-1">
            <label
              htmlFor="address"
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              Street Address
            </label>
            <input
              id="address"
              name="address"
              type="text"
              value={formik.values.address}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
            {formik.touched.address && formik.errors.address && (
              <div className="text-red-500 text-xs">
                {formik.errors.address}
              </div>
            )}
          </div>
          <div className="mb-4 flex-1">
            <label
              htmlFor="city"
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              City
            </label>
            <input
              id="city"
              name="city"
              type="text"
              value={formik.values.city}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
            {formik.touched.city && formik.errors.city && (
              <div className="text-red-500 text-xs">{formik.errors.city}</div>
            )}
          </div>
          <div className="mb-4 flex-1">
            <label
              htmlFor="state"
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              State
            </label>
            <input
              id="state"
              name="state"
              type="text"
              value={formik.values.state}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
            {formik.touched.state && formik.errors.state && (
              <div className="text-red-500 text-xs">{formik.errors.state}</div>
            )}
          </div>
        </div>
        <div className="mb-4">
          <label
            htmlFor="state"
            className="block text-gray-700 text-sm font-bold mb-2"
          >
            ZIP Code
          </label>
          <input
            id="zip"
            name="zip"
            type="text"
            value={formik.values.zip}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
          {formik.touched.zip && formik.errors.zip && (
            <div className="text-red-500 text-xs">{formik.errors.zip}</div>
          )}
        </div>
        <div>
          <Button
            type="submit"
            size={"sm"}
            color={"blue"}
            isProcessing={formik.isSubmitting}
          >
            Save
          </Button>
        </div>
      </form>
    </div>
  );
}
