import { Button, Modal } from "flowbite-react";
import { useState } from "react";
import { HiPlusCircle } from "react-icons/hi";
import AddIntegrationEmbedForm from "./AddIntegrationEmbedForm";
import useAddIntegrationEmbedConfigSelectInputValues from "../../../hooks/integrations/embeds/useListIntegrationEmbedConfigSelectValues";
import { toast } from "react-toastify";
import { IntegrationEmbedLanguageOption } from "../../../types/embeds";

const languageList: IntegrationEmbedLanguageOption[] = [
  { code: "en", language: "English" },
  { code: "zh", language: "Chinese (Simplified)" },
  { code: "es", language: "Spanish" },
  { code: "hi", language: "Hindi" },
  { code: "ar", language: "Arabic" },
  { code: "pt", language: "Portuguese" },
  { code: "bn", language: "Bengali" },
  { code: "ru", language: "Russian" },
  { code: "ja", language: "Japanese" },
  { code: "jv", language: "Javanese" },
  { code: "de", language: "German" },
  { code: "ko", language: "Korean" },
  { code: "fr", language: "French" },
  { code: "te", language: "Telugu" },
  { code: "mr", language: "Marathi" },
  { code: "tr", language: "Turkish" },
  { code: "ta", language: "Tamil" },
  { code: "vi", language: "Vietnamese" },
  { code: "ur", language: "Urdu" },
  { code: "el", language: "Greek" },
  { code: "fa", language: "Persian" },
  { code: "gu", language: "Gujarati" },
  { code: "th", language: "Thai" },
  { code: "it", language: "Italian" },
  { code: "uk", language: "Ukrainian" },
  { code: "ml", language: "Malayalam" },
  { code: "kn", language: "Kannada" },
  { code: "pl", language: "Polish" },
  { code: "pa", language: "Punjabi" },
  { code: "ro", language: "Romanian" },
];

export default function AddIntegrationEmbedModalComponent({
  productID,
}: {
  productID: string;
}) {
  const [showModal, setShowModal] = useState(false);

  const {
    data: embedConfigSelectInputValues,
    isLoading,
    isError,
  } = useAddIntegrationEmbedConfigSelectInputValues({
    productId: productID,
    onSuccess: () => {},
    onError: () => {},
  });

  return (
    <div>
      <Button
        color="blue"
        isProcessing={isLoading}
        onClick={() => {
          if (isLoading) {
            toast.info("Loading values; please wait few seconds!");
          } else if (isError) {
            toast.error(
              "Error loading embed configuration select input values; refresh and try again"
            );
          } else {
            setShowModal(true);
          }
        }}
      >
        <div className="flex space-x-1 align-center justify-center">
          <HiPlusCircle className="h-5 w-5" />
          <span>Add Embeds</span>
        </div>
      </Button>
      <Modal show={showModal} size="4xl" onClose={() => setShowModal(false)}>
        <Modal.Header>
          <h2 className="text-gray-700 font-bold ">Add Embed Configuration</h2>
        </Modal.Header>
        <Modal.Body>
          <AddIntegrationEmbedForm
            productID={productID}
            afterSubmit={() => {
              setShowModal(false);
            }}
            onCancel={() => {}}
            themeList={embedConfigSelectInputValues?.themes ?? []}
            tagList={embedConfigSelectInputValues?.tags ?? []}
            languageList={languageList}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
}
