import LandingNavbar from "../../components/Landing/navbar";
import LandingSignupForm from "../../components/Landing/signup/form";
import TeamEffortSvg from "./../../assets/landing/home/team_effort.svg";
import SignupSVG from "./../../assets/landing/home/singup.svg";
import LoginSVG from "./../../assets/landing/home/login.svg";
import useIsAuthenticated from "../../hooks/useIsAuthenticated";
import { Link } from "react-router-dom";

export default function LandingSignUp() {
  const { loading, isAuthenticated } = useIsAuthenticated();

  return (
    <div className="">
      <LandingNavbar />
      <div className="py-12">
        <div className="container flex flex-wrap items-center justify-between px-4 mx-auto text-justify">
          <div className="w-full space-y-6 lg:w-1/2">
            <h1 className="text-3xl font-bold text-gray-800">
              Join Our Community
            </h1>
            <p className="text-gray-600">
              By signing up, you're not just getting an account. You're taking
              the first step towards a transformative experience. Our platform
              is designed to cater to your needs, ensuring you get the best out
              of what we offer.
            </p>
            <h2 className="text-2xl font-semibold text-gray-800">
              Why Choose ReleaseBlast?
            </h2>
            <ul className="pl-5 space-y-2 text-gray-600 list-disc">
              <li>
                Automated Release Updates: Keep everyone in the loop without
                lifting a finger.
              </li>
              <li>
                User Feedback Collection: Understand your users better with
                direct feedback mechanisms.
              </li>
              <li>
                Embeddable Widgets: Seamlessly integrate our tools into your own
                site.
              </li>
              <li>
                Real-time Engagement: Interact with your users in real-time,
                gather feedback, and ensure alignment with every product update.
              </li>
            </ul>
          </div>
          <div className="w-full mt-8 lg:w-1/2 lg:mt-0">
            <img
              src={TeamEffortSvg}
              alt="Visual Graphics"
              className="p-10 rounded"
            />
          </div>
        </div>
      </div>
      <div className="container flex h-full min-h-[40vh] p-10 mx-auto space-x-4">
        {loading ? (
          <div className="flex items-center justify-between w-full h-full space-x-16">
            {/* Skeleton for Image */}
            <div className="w-1/2 bg-gray-200 rounded h-96 animate-pulse"></div>

            {/* Skeleton for Content */}
            <div className="flex flex-col justify-center flex-1 w-1/2 space-y-4">
              <div className="w-3/4 h-6 bg-gray-200 rounded animate-pulse"></div>
              <div className="w-3/4 h-6 bg-gray-200 rounded animate-pulse"></div>
              <div className="w-3/4 h-6 bg-gray-200 rounded animate-pulse"></div>
              <div className="w-2/3 h-4 bg-gray-200 rounded animate-pulse"></div>
              <div className="w-1/2 h-10 bg-gray-200 rounded animate-pulse"></div>
            </div>
          </div>
        ) : isAuthenticated ? (
          <div className="flex items-center space-x-4 fade-in">
            <div className="flex-1">
              <img src={LoginSVG} alt="login" />
            </div>
            <div className="flex flex-col justify-center flex-1 w-full h-full p-10 space-y-4">
              <h2 className="text-xl font-semibold text-gray-800">
                You are already logged in!
              </h2>
              <p className="text-gray-600">
                To sign up with a different account, please log out first.
              </p>
              <Link
                to="/app/redirect"
                className="px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
              >
                Go to Dashboard
              </Link>
            </div>
          </div>
        ) : (
          // <div className="flex items-center space-x-4 fade-in">
          //   <div className="flex-1">
          //     <div className="container px-4 py-4 mx-auto mb-4 space-y-4">
          //       <h1 className="text-3xl font-semibold text-gray-800">
          //         Start your journey today
          //       </h1>
          //     </div>
          //     <img src={SignupSVG} alt="Signup Illustration" className="p-10" />
          //   </div>

          //   <div className="flex flex-col flex-1 p-8 bg-gray-100 rounded-lg shadow-md">
          //     <LandingSignupForm />
          //   </div>
          // </div>
          <div className="flex items-center space-x-4 fade-in">
            <div className="flex-1">
              <img src={LoginSVG} alt="login" />
            </div>
            <div className="flex flex-col justify-center flex-1 w-full h-full p-10 space-y-4">
              <h2 className="text-xl font-semibold text-gray-800">Hello!</h2>
              <p className="text-gray-600">
                Ready to get strated? Please sign up.
              </p>
              <a
                href="/__catalyst/auth/signup"
                className="px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
              >
                Signup Account
              </a>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
