import React from "react";
import Select from "react-select";

export interface LanguageOption {
    value: string;
    label: string;
}

interface LanguageSelectProps {
    selectedLanguage: string | null;
    setSelectedLanguage: (selected: string | null) => void;
    className?: string;
    label?: string;
    required?: boolean;
}

const LanguageSelect: React.FC<LanguageSelectProps> = ({
                                                           selectedLanguage,
                                                           setSelectedLanguage,
                                                           className,
                                                           label = "Language",
                                                           required = false
                                                       }) => {
    const options: LanguageOption[] = [
        {value: "en", label: "English"},
        {value: "zh", label: "Chinese (Simplified)"},
        {value: "es", label: "Spanish"},
        {value: "hi", label: "Hindi"},
        {value: "ar", label: "Arabic"},
        {value: "pt", label: "Portuguese"},
        {value: "bn", label: "Bengali"},
        {value: "ru", label: "Russian"},
        {value: "ja", label: "Japanese"},
        {value: "jv", label: "Javanese"},
        {value: "de", label: "German"},
        {value: "ko", label: "Korean"},
        {value: "fr", label: "French"},
        {value: "te", label: "Telugu"},
        {value: "mr", label: "Marathi"},
        {value: "tr", label: "Turkish"},
        {value: "ta", label: "Tamil"},
        {value: "vi", label: "Vietnamese"},
        {value: "ur", label: "Urdu"},
        {value: "el", label: "Greek"},
        {value: "fa", label: "Persian"},
        {value: "gu", label: "Gujarati"},
        {value: "th", label: "Thai"},
        {value: "it", label: "Italian"},
        {value: "uk", label: "Ukrainian"},
        {value: "ml", label: "Malayalam"},
        {value: "kn", label: "Kannada"},
        {value: "pl", label: "Polish"},
        {value: "pa", label: "Punjabi"},
        {value: "ro", label: "Romanian"},
    ];

    const findOption = (value: string | null) =>
        options.find((option) => option.value === value) || null;

    const handleChange = (selectedOption: LanguageOption | null) => {
        setSelectedLanguage(selectedOption ? selectedOption.value : null);
    };

    return (
        <div className={className ?? ""}>
            <label className="block text-gray-700 text-md font-semibold mb-2">
                {label}{required && <span className="text-red-500">*</span>}
            </label>
            <Select
                value={findOption(selectedLanguage)}
                onChange={handleChange}
                options={options}
                isSearchable
                placeholder="Select a language..."
            />
        </div>
    );
};

export default LanguageSelect;
