import React from "react";
import UserInfo from "../../../types/user";
import UserProfileEditModal from "./UserProfileEditModal";
import GenericDateComponent from "../../Shared/GenericDateComponent";


type UserLogoCardProps = {
    user: UserInfo;
}


const UserLogoCard: React.FC<UserLogoCardProps> = (
    {
        user,
    }) => {

    return (
        <div className="flex flex-col bg-white shadow rounded-lg p-4 w-full h-full">
            <div className="flex-1 mx-auto w-full">
                <div
                    className="w-full h-64 bg-cover bg-center rounded-lg relative mb-24"
                    style={{
                        backgroundImage: `url(https://images.unsplash.com/photo-1499336315816-097655dcfbda?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2710&q=80)`,
                    }}
                >
                    <div className="absolute bottom-16 left-0 right-0 flex justify-center">
                        <img
                            src={user.profilePicture}
                            alt="Organization Logo"
                            className="w-32 h-32 rounded-full z-10 mb-[-25%] border border-gray-200"
                        />
                    </div>
                    <div className="absolute top-0 right-0">
                        <UserProfileEditModal lastName={user.lastName} firstName={user.firstName}/>
                    </div>
                </div>
                <div className="text-center mt-12 w-full mx-auto">
                    <h3 className="text-4xl font-semibold leading-normal text-blue-gray-700 mb-2">
                        {user.firstName} {user.lastName}
                    </h3>
                    <div className="text-sm leading-normal mt-0 mb-2 text-blue-gray-400 font-bold lowercase">
                        {user.email}
                    </div>
                </div>
            </div>
            <div className="flex-1 mx-48 mt-10 py-10 border-t border-blue-gray-200 px-4">
                <div className="mx-auto w-full">
                    <h1 className="text-xl font-bold">General information</h1>
                    <div className="flex mt-8">
                        <div className="flex-1 space-y-2">
                            <div className="flex space-x-2">
                                <p className="text-blue-gray-500">Email: </p>
                                <p className="text-blue-gray-800 font-semibold">
                                    {user.email}
                                </p>
                            </div>
                            <div className="flex space-x-2">
                                <p className="text-blue-gray-500">Active Since: </p>
                                <p className="text-blue-gray-800 font-semibold">
                                    <GenericDateComponent date={user.activeSince}/>
                                </p>
                            </div>
                        </div>
                        <div className="flex-1 space-y-2">
                            <div className="flex space-x-2">
                                <p className="text-blue-gray-500">Organizations (as admin): </p>
                                <p className="text-blue-gray-800 font-semibold">
                                    {user.countOrgAsAdmin || 0}
                                </p>
                            </div>
                            <div className="flex space-x-2">
                                <p className="text-blue-gray-500">Organizations (as editor): </p>
                                <p className="text-blue-gray-800 font-semibold">
                                    {user.countOrgAsEditor || 0}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UserLogoCard;
