import { MdErrorOutline } from "react-icons/md";
import { MdSmartButton } from "react-icons/md";
import CircularProgressIndicator from "./CircularLoading";

interface LoadingPageProps {
  label?: string;
  isError?: boolean;
  errorMessage?: string;
  onRetry?: () => void;
  fullHeight?: boolean;
}

export default function LoadingPage(props: LoadingPageProps) {
  return (
    <div
      className="bg-background dark:bg-slate-700 dark:text-onBackgroundDark w-full flex justify-center items-center flex-col gap-4"
      style={{
        height: props.fullHeight ? "100vh" : "75vh",
      }}
    >
      {!props.isError && <CircularProgressIndicator />}
      {props.label && !props.isError && (
        <h1 className="font-bold text-sm">{props.label}</h1>
      )}
      {props.isError && (
        <div className="bg-error dark:bg-errorDark text-error dark:text-errorDark bg-opacity-10 dark:bg-opacity-10 text-7xl p-8 rounded-full">
          <MdErrorOutline />
        </div>
      )}
      {props.isError && (
        <h1 className="font-bold text-sm">
          {props.errorMessage ?? "Something went wrong"}
        </h1>
      )}

      {props.isError && props.onRetry && <MdSmartButton>Retry</MdSmartButton>}
    </div>
  );
}
