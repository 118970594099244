import {Button, Modal} from "flowbite-react";
import React from "react";
import OrganizationProfileForm from "./OrganizationProfileForm";
import {OrganizationInfo} from "../../types/organization";
import {useQueryClient} from "@tanstack/react-query";
import {toast} from "react-toastify";
import {useAddOrganization} from "../../hooks/organization/useAddOrganization";
import useUserOrgStore from "../../hooks/store/useUserOrgStore";
import {HiPlusCircle} from "react-icons/hi";

export default function OrganizationAddModal() {
    const [showModal, setShowModal] = React.useState(false);

    const {
        addUserOrg
    } = useUserOrgStore()

    const queryClient = useQueryClient();

    const {mutate: addOrganization} = useAddOrganization(
        (data) => {
            setShowModal(false);
            queryClient.invalidateQueries(["organizations"]);
            toast.success("Organization Updated Successfully");

            console.log(data)

            addUserOrg({
                organizationID: data.organization.ROWID,
                organizationName: data.organization.name,
                userRole: "admin"
            });
        },
        (error) => {
            toast.error("Error updating organization");
        }
    );

    function onSubmit(values: OrganizationInfo) {
        addOrganization(values);
    }

    return (
        <div>
            <div>
                <Button color={"blue"} onClick={() => setShowModal(true)} className={""}>
                    <HiPlusCircle className="h-5 w-5 mr-2" />
                    Add Organization
                </Button>
            </div>
            <div>
                <Modal show={showModal} size="3xl" onClose={() => setShowModal(false)}>
                    <Modal.Header>
                        <h2 className="text-gray-700 font-bold">
                            Add New Organization
                        </h2>
                    </Modal.Header>
                    <Modal.Body>
                        <OrganizationProfileForm
                            organization={{
                                description: "",
                                id: "",
                                phone: "",
                                name: "",
                                address: "",
                                city: "",
                                state: "",
                                zip: "",
                                logo: "",
                            }}
                            onSubmit={onSubmit}
                        />
                    </Modal.Body>
                </Modal>
            </div>
        </div>
    );
}
