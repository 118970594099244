import { useParams } from "react-router-dom";
import useUnsubscriberSubscriber from "./hooks/useUnsubscriberSubscriber";

export default function UnsubscribePage() {
  const { subscriberID } = useParams();

  const { mutate } = useUnsubscriberSubscriber();

  const handleUnsubscribe = () => {
    mutate(subscriberID ?? "");
  };

  return (
    <div className="flex items-center justify-center h-screen bg-gray-100">
      <div className="max-w-md w-full bg-white rounded-lg shadow-md p-6">
        <h1 className="text-2xl font-bold text-center text-gray-800 mb-4">
          Unsubscribe
        </h1>
        <p className="text-gray-600 text-sm text-center">
          We're sorry to see you go! If you no longer wish to receive our
          emails, you can unsubscribe below.
        </p>
        <div className="mt-6 flex justify-center">
          <button
            onClick={handleUnsubscribe}
            className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out"
          >
            Unsubscribe
          </button>
        </div>
      </div>
    </div>
  );
}
