import { useParams } from "react-router-dom";
import SettingsOrganizationLayout from "../../../components/Settings/Organization/SettingsOrganizationLayout";
import { useProducts } from "../../Products/hooks/useProducts";
import SettingsOrganizationProductListTable from "../../../components/Settings/Organization/Product/SettingsOrganizationProductListTable";
import OrganizationProductAddModal from "../../../components/Product/OrganizationProductAddModal";
import TableSkeletonLoader from "../../../components/Shared/TableSkeletonLoader";
import useGetOrgRoleForUser from "../../../hooks/store/useGetOrgRoleForUser";

export default function OrgSettingsProductsPage() {
  const { orgId } = useParams();
  const {
    data: products,
    isLoading: productIsLoading,
    isFetching: productIsFetching,
    isError: productIsError,
  } = useProducts(orgId ?? "");

  const userRole = useGetOrgRoleForUser(orgId ?? "");

  if (productIsError) {
    return (
      <>
        <SettingsOrganizationLayout>
          An Error has occoured
        </SettingsOrganizationLayout>
      </>
    );
  }

  return (
    <SettingsOrganizationLayout>
      <div className="shadow-md p-8 bg-white rounded-md h-full">
        <div className="flex flex-row">
          <h1 className="text-2xl font-bold mb-6 text-gray-700 flex-1">
            All Products
          </h1>
          {userRole === "admin" && (
            <div>
              <OrganizationProductAddModal organizationID={orgId ?? ""} />
            </div>
          )}
        </div>
        <div className="bg-gray-200 mt-2">
          {productIsLoading || productIsFetching || !products ? (
            <TableSkeletonLoader />
          ) : (
            <SettingsOrganizationProductListTable
              products={products}
              orgID={orgId ?? ""}
            />
          )}
        </div>
      </div>
    </SettingsOrganizationLayout>
  );
}
