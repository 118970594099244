import React, { ReactNode } from "react";
import SettingsOrganizationSidebar from "./SettingsOrganizationSidebar";

type SettingsOrganizationLayoutProps = {
  children: ReactNode;
};

const SettingsOrganizationLayout: React.FC<SettingsOrganizationLayoutProps> = ({
  children,
}) => {
  return (
    <div className="flex h-full">
      <SettingsOrganizationSidebar />
      <div className="flex-1 p-4 h-full">
        <div className="h-full">{children}</div>
      </div>
    </div>
  );
};

export default SettingsOrganizationLayout;
