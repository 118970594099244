import { Button, Label, TextInput } from "flowbite-react";
import { useState } from "react";
import useSendTestEmail from "../../../../pages/Products/hooks/useProducts";
import { toast } from "react-toastify";

export default function PublishModalSendTestEmailComponent({
  releaseNoteID,
  isLoading,
  setIsLoading,
}: {
  releaseNoteID: string;
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
}) {
  const [testEmailAddress, setTestEmailAddress] = useState("");
  const { sendEmail, isSending, error } = useSendTestEmail(onSuccess, onError);

  function onSuccess() {
    setTestEmailAddress("");
    toast.success("Email sent");
    setIsLoading(false);
  }

  function onError() {
    setTestEmailAddress("");
    toast.error("Unable to send Email");
    setIsLoading(false);
  }

  function handleSendTestEmail() {
    setIsLoading(true);
    sendEmail({
      toUser: testEmailAddress,
      noteID: releaseNoteID,
    });
  }

  if (error) {
    return (
      <div>
        <div className="text-red-500 text-sm">{`${error}`}</div>
      </div>
    );
  }

  return (
    <>
      <div className="">
        <form
          className="flex space-x-6"
          onSubmit={(e) => {
            e.preventDefault();
            if (e.currentTarget.checkValidity()) {
              handleSendTestEmail();
            }
          }}
        >
          <div className="flex-1 flex flex-col space-y-2">
            <Label htmlFor="testEmail">Send Test Email</Label>
            <p className="text-sm text-gray-600">
              Use this option to send the release note to a specific email
              address.
            </p>
            <TextInput
              id="testEmail"
              type="email"
              placeholder="Enter email address"
              required
              value={testEmailAddress}
              onChange={(e) => setTestEmailAddress(e.target.value)}
            />
          </div>
          <div className="mt-auto">
            <Button
              type="submit"
              color="dark"
              isProcessing={isSending}
              disabled={isLoading || isSending}
            >
              <div className="w-32">Send Test Email</div>
            </Button>
          </div>
        </form>
      </div>
    </>
  );
}
