import { useFormik } from "formik";
import * as Yup from "yup";
import useAddMember from "../../hooks/members/useAddMember";
import { toast } from "react-toastify";
import { Button } from "flowbite-react";

type OrganizationMemberFromPrams = {
  email: string;
  role: string;

  orgID: string;
  existingMemberEmailList: string[];
  onSuccess: () => void;
  onError: () => void;
};

export default function OrganizationMemberForm({
  email,
  role,
  orgID,
  existingMemberEmailList,
  onSuccess,
  onError,
}: OrganizationMemberFromPrams) {
  const { mutate: addMember } = useAddMember({
    onSuccess: () => {
      toast.success("Member has been invited!");
      onSuccess();
    },
    onError: () => {
      toast.error("Error inviting member");
      onError();
    },
  });

  const formik = useFormik({
    initialValues: {
      email: email,
      role: role,
    },
    onSubmit: (values) => {
      addMember({
        email: values.email,
        role: values.role,
        orgId: orgID,
      });
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email()
        .required("Email is required")
        .lowercase()
        .notOneOf(
          existingMemberEmailList.map((m) => m.toLowerCase()),
          "Email already exists"
        ),
      role: Yup.string()
        .required("Role is required")
        .oneOf(["admin", "editor"], "Role must be admin or editor"),
    }),
  });

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <div className="mb-4">
          <label
            htmlFor="email"
            className="block text-gray-700 text-sm font-bold mb-2"
          >
            Email
          </label>
          <input
            type="text"
            id="email"
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            {...formik.getFieldProps("email")}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="text-red-500 text-xs italic">
              {formik.errors.email}
            </div>
          ) : null}
        </div>
        <div className="mb-4">
          <label
            htmlFor="role"
            className="block text-gray-700 text-sm font-bold mb-2"
          >
            Role
          </label>
          <select
            id="role"
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            {...formik.getFieldProps("role")}
          >
            <option value="admin" selected={formik.values.role === "admin"}>
              Admin
            </option>
            <option value="editor" selected={formik.values.role === "admin"}>
              Editor
            </option>
          </select>
          {formik.touched.role && formik.errors.role ? (
            <div className="text-red-500 text-xs italic">
              {formik.errors.role}
            </div>
          ) : null}
        </div>

        <Button
          type="submit"
          isProcessing={formik.isSubmitting}
          color="blue"
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          Submit
        </Button>
      </form>
    </div>
  );
}
