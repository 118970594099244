import React from 'react';

export default function PublishedModalEmailNotConfiguredComponent() {
    return (
        <div className="flex items-center justify-center h-[30vh]">
            <div className="space-y-6 px-2">
                <h2 className="text-center text-xl font-semibold">Email Client Not Configured</h2>
                <p>Your email client is not configured, and you're unable to send emails at this moment.</p>
                <p>Please configure your email sending configurations, including the address to send using, and other
                    relevant credentials. This can be done from the settings page (icon visible on the left
                    side of the dashboard).
                    <br/>
                    <br/>
                    Once you've navigated to the product list page, click on the envelope (email) icon next to the
                    product you'd like to configure.
                </p>
            </div>
        </div>
    );
}
