import { useEffect, useState } from "react";
import UpdateModalScheduleSendEmailComponent from "./UpdateModalScheduleSendEmailComponent";
import { ReleaseNoteWithRowId } from "../../../../types/releaseNotes";

export default function UpdateScheduledEmailModalBodyComponent({
  note,
}: {
  note: ReleaseNoteWithRowId;
}) {
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const [initialScheduledAtRelativeTime, setInitialScheduledAtRelativeTime] =
    useState<string>("");

  function calculateRelativeTimeFromNow(targetTime: Date) {
    const now = new Date();
    const diff = targetTime.getTime() - now.getTime();

    console.log(now, targetTime, now.getTime(), targetTime.getTime(), diff);

    if (diff < 0) {
      return "Time is in the past" + diff.toString();
    }

    // Calculate days, hours, and minutes
    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    const hours = Math.floor((diff / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((diff / (1000 * 60)) % 60);

    let relativeTimeStr = "";

    if (days > 0) {
      relativeTimeStr += `${days} day${days > 1 ? "s" : ""} `;
    }
    relativeTimeStr += `${hours} hr${hours !== 1 ? "s" : ""} `;
    relativeTimeStr += `${minutes} min${minutes !== 1 ? "s" : ""} from now`;

    return relativeTimeStr;
  }

  useEffect(() => {
    if (note.scheduleSendAt) {
      const utcDate = new Date(note.scheduleSendAt);
      const localDate = new Date(
        utcDate.getTime() - utcDate.getTimezoneOffset() * 60 * 1000
      );

      setInitialScheduledAtRelativeTime(
        calculateRelativeTimeFromNow(localDate)
      );
    }
  }, [note.scheduleSendAt]);

  const handleEditClick = () => {
    setIsEditing(!isEditing);
  };

  if (!note.scheduleSendAt) {
    return <></>;
  }

  const renderDisclaimer = () => (
    <div className="mt-6 p-4 bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700">
      <h4 className="font-semibold mb-2">Important Information:</h4>
      <ul className="list-disc pl-5">
        <li>
          Your email was originally scheduled to be sent at:{" "}
          <strong>
            {new Date(note.scheduleSendAt ?? "").toLocaleString()} UTC
          </strong>{" "}
          which is <strong>{initialScheduledAtRelativeTime}</strong>. Any
          changes made will update this schedule.
        </li>
        <li>
          You cannot modify the schedule if the email is set to be sent within
          the next 20 minutes.
        </li>
        <li>
          The email will be sent to all subscribers who are registered at the
          time the email is dispatched.
        </li>
        <li>
          Ensure that the new scheduled time is accurate and intended, as this
          action cannot be undone.
        </li>
        <li>
          Review your email content and recipient list before confirming the
          schedule update.
        </li>
      </ul>
    </div>
  );

  return (
    <div className={`${isEditing ? "h-[50vh]" : ""}`}>
      <div className="mb-4 bg-white">
        {isEditing ? (
          <div className="space-y-6">
            <UpdateModalScheduleSendEmailComponent
              releaseNoteID={note.ROWID}
              initialScheduledDateTime={new Date(note.scheduleSendAt)}
              isLoading={false}
              setIsLoading={setIsEditing}
            />
            {renderDisclaimer()}
          </div>
        ) : (
          <>
            <h3 className="text-lg font-semibold text-gray-700">
              Scheduled Email Details
            </h3>
            <p className="text-gray-600">
              Scheduled Time: {initialScheduledAtRelativeTime}
            </p>
          </>
        )}
        <button
          onClick={handleEditClick}
          className="mt-4 py-2 px-4 bg-gray-500 hover:bg-gray-700 text-white font-bold rounded"
        >
          {isEditing ? "Cancel" : "Edit Schedule"}
        </button>
      </div>
    </div>
  );
}
