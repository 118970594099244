// AddNotePage.tsx
import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { usePublishReleaseNote } from "../hooks/useReleaseNotes";
import ReleaseNoteForm, {
  ReleaseNoteData,
} from "../../../components/Note/ReleaseNoteForm";
import { toast } from "react-toastify";

const AddNotePage: React.FC = () => {
  const { prodId, orgId } = useParams<{ prodId: string; orgId: string }>();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { mutateAsync: publishReleaseNote } = usePublishReleaseNote();

  if (!prodId) {
    return <></>;
  }

  const handleSave = async (releaseNoteData: ReleaseNoteData) => {
    try {
      await publishReleaseNote({
        prodId,
        releaseNote: {
          version: parseFloat(releaseNoteData.version),
          priority: parseInt(releaseNoteData.priority),
          isPublic: releaseNoteData.visibility === "public",
          tag: releaseNoteData.tags,
          title: releaseNoteData.title,
          content: releaseNoteData.content,
          language: releaseNoteData.language ?? "en",
          BlockNoteEditorContent: releaseNoteData.BlockNoteEditorContent ?? "",
        },
      });

      toast.success(
        `Release note has been ${
          releaseNoteData.visibility === "public"
            ? "published"
            : "saved to draft"
        } successfully`
      );
      queryClient.invalidateQueries(["releaseNotes", prodId]);
      navigate(`/app/${orgId}/notes/${prodId}`);
    } catch (error) {
      toast.error("An error occurred while saving the release note.");

      console.error("Error publishing release note:", error);
    }
  };

  return <ReleaseNoteForm localStorageKey="new" onSave={handleSave} />;
};

export default AddNotePage;
