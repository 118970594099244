import { useQuery } from "@tanstack/react-query";
import API from "../../../api";
import { AxiosError } from "axios";

export type SubscriberProduct = {
  id: string;
  name: string;
  logo: string;
  verified: boolean;
};

interface ErrorResponse {
  error: string;
}

export const useSubscriberProduct = (subscriberID: string) => {
  const fetchProductForSubscribers = async (): Promise<SubscriberProduct> => {
    const response = await API.get(`/subscribers/product/${subscriberID}`);
    return response.data.product;
  };

  const {
    data: product,
    isLoading: loading,
    error,
  } = useQuery<SubscriberProduct, AxiosError>(
    ["subscriberProduct", subscriberID],
    fetchProductForSubscribers,
    {
      onError: (error: unknown) => {
        console.log(`ERROR: ${error}`);
      },
    }
  );

  let errorMessage = null;
  if (error) {
    const errorResponse = error.response?.data as ErrorResponse | undefined;
    errorMessage = errorResponse?.error || "An unexpected error occurred";
  }

  return { product, loading, error: errorMessage };
};
