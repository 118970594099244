import { useState } from 'react';
import API from '../../../api';

interface UseConfirmRejectSubscriptionProps {
  productId: string | null; 
  token: string | null;
  subscriberID: string;
  onSuccess: (didConfirm: boolean) => void;
  onError: (error: string) => void;
}

export const useConfirmRejectSubscription = ({
  productId,
  token,
  subscriberID,
  onSuccess,
  onError,
}: UseConfirmRejectSubscriptionProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const sendRequest = async (action: 'confirm' | 'reject') => {
    if (!productId) return;
    if (!token) return;

    setIsSubmitting(true);
    try {
      const response = await API.post('/subscriber/verify', {
        productId,
        token,
        subscriberID,
        action,
      });

      if (response.status === 200) {
        onSuccess(action === "confirm");
      } else {
        throw new Error('Token not found. Make sure that the URL is correct and try again.');
      }
    } catch (error) {
      onError(`Token not found. Make sure that the URL is correct and try again.`);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleConfirm = () => sendRequest('confirm');
  const handleReject = () => sendRequest('reject');

  return { handleConfirm, handleReject, isSubmitting };
};



