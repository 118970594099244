import React from 'react';

const FeedbackPageSkeleton: React.FC = () => {
    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
            <div className="p-6 bg-white rounded-lg shadow-md text-center w-1/3 animate-pulse">
                <div className="h-8 mb-4 bg-gray-300 rounded w-3/4 mx-auto"></div>
                {/* Header placeholder */}
                <div className="flex justify-center space-x-4 mb-4">
                    {/* Emoji buttons placeholders */}
                    <div className="flex flex-col space-y-2">
                        <div className="h-10 w-10 bg-gray-300 rounded-full mx-auto"></div>
                        <div className="h-4 bg-gray-300 rounded w-14 mx-auto"></div>
                    </div>
                    <div className="flex flex-col space-y-2">
                        <div className="h-10 w-10 bg-gray-300 rounded-full mx-auto"></div>
                        <div className="h-4 bg-gray-300 rounded w-14 mx-auto"></div>
                    </div>
                </div>
                <div className="mt-8 flex flex-col space-y-6">
                    {/* Email input placeholder */}
                    <div className="h-10 bg-gray-300 rounded w-full"></div>
                    {/* Feedback textarea placeholder */}
                    <div className="h-32 bg-gray-300 rounded w-full"></div>
                    {/* Buttons placeholders */}
                    <div className="flex justify-center space-x-2">
                        <div className="h-10 w-40 bg-gray-300 rounded"></div>
                        <div className="h-10 w-40 bg-gray-300 rounded"></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FeedbackPageSkeleton;
