import React, { useState } from "react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(relativeTime);

interface NoteDateComponentProps {
  date: string;
}

const NoteDateComponent: React.FC<NoteDateComponentProps> = ({ date }) => {
  const [isHovered, setIsHovered] = useState(false);

  const formatDate = () => {
    const dateObj = new Date(date);
    const dateObjLocal = new Date(
      dateObj.getTime() - dateObj.getTimezoneOffset() * 60000
    );

    return dayjs().to(dateObjLocal);
  };

  return (
    <div
      className="group relative inline-block"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <span className="text-sm text-gray-500 dark:text-gray-400">
        {formatDate()}
      </span>
      {isHovered && (
        <div className="absolute left-1/2 transform -translate-x-1/2 mt-2 p-2 bg-gray-800 text-white text-xs rounded shadow-lg whitespace-nowrap">
          <span>{dayjs(date).format("MMMM DD, YYYY")}</span>
        </div>
      )}
    </div>
  );
};

export default NoteDateComponent;
