import { useParams } from "react-router-dom";
import TableSkeletonLoader from "../../../../components/Shared/TableSkeletonLoader";
import AddIntegrationEmbedThemeModalComponent from "../../../../components/Integration/Embed/Theme/AddIntegrationEmbedThemeModalComponent";
import { useListIntegrationEmbedThemesForProduct } from "../../../../hooks/integrations/embeds/useListIntegrationEmbedConfigsForProduct";
import EmbedsThemeListTableComponent from "../../../../components/Integration/Embed/Theme/EmbedThemeListTableComponent";

export default function IntegrationEmbedThemeListView() {
  const { prodId } = useParams();

  const {
    data: embedThemes,
    isLoading: loading,
    isError,
  } = useListIntegrationEmbedThemesForProduct(prodId ?? "");

  if (isError) {
    return <div>"Error occoured"</div>;
  }

  return (
    <div className="mx-1 my-2 bg-white p-6 rounded-lg h-full">
      <div className="flex">
        <div className="flex-1">
          <h1 className="text-gray-800 text-2xl font-semibold mb-4">
            Embed Themes
          </h1>
        </div>
        <div>
          <AddIntegrationEmbedThemeModalComponent productID={prodId ?? ""} />
        </div>
      </div>
      <div className="mt-2">
        {loading ? (
          <TableSkeletonLoader />
        ) : (
          <div>
            <EmbedsThemeListTableComponent
              embedThemes={embedThemes}
              productId={prodId ?? ""}
            />
          </div>
        )}
      </div>
    </div>
  );
}
