import { ButtonHTMLAttributes, DetailedHTMLProps } from "react";

import { BiLoaderAlt } from "react-icons/bi";

export function CircularProgressIndicator() {
  return <BiLoaderAlt className="animate-spin" />;
}

type ButtonProps<T> = DetailedHTMLProps<
  ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> & {
  size?: "normal" | "small" | "square" | "large";
  variant?: "primary" | "bordered" | "flat" | "transparent";
  children: React.ReactNode;
  isLoading?: boolean;
  leadingIcon?: React.ReactNode;
};

function Button<T>({
  children,
  size = "normal",
  variant = "primary",
  className,
  isLoading,
  leadingIcon,
  disabled,
  ...props
}: ButtonProps<T>) {
  return (
    <button
      {...props}
      disabled={disabled || isLoading}
      className={`flex rounded-md items-center justify-center font-semibold gap-2 capitalize disabled:cursor-not-allowed disabled:text-gray-500 disabled:dark:text-gray-500 disabled:bg-gray-300 dark:disabled:bg-gray-300 transition-all  
      ${size === "large" && "px-4 py-3 text-base"}
      ${size === "normal" && "px-4 py-2 text-sm"} ${
        size === "small" && "text-xs px-3 py-2"
      } ${size === "square" && "p-2 text-sm aspect-square h-full "} ${
        variant === "primary" && "bg-primary text-onPrimary  dark:bg-slate-800"
      } ${
        variant === "flat" &&
        "text-black  hover:text-onPrimary dark:hover:text-white hover:bg-primaryHover hover:dark:bg-primaryDark !hover:bg-opacity-5 !dark:hover:bg-opacity-5"
      } ${
        variant === "bordered" &&
        "hover:bg-primary text-primary dark:text-primaryDark hover:dark:bg-primaryDark hover:bg-opacity-10 hover:dark:bg-opacity-10 border border-primary border-opacity-10 dark:border-primaryDark dark:border-opacity-10 disabled:text-gray-500 disabled:dark:text-gray-400 disabled:hover:bg-transparent"
      } ${
        variant === "transparent" &&
        "hover:bg-primary border-0 text-primary dark:text-primaryDark hover:dark:bg-primaryDark capitalize hover:bg-opacity-10 hover:dark:bg-opacity-10  disabled:text-gray-500 disabled:dark:text-gray-400 disabled:hover:bg-transparent dark:disabled:hover:bg-transparent"
      }  ${className}`}
    >
      {isLoading && <CircularProgressIndicator />}
      {!isLoading && leadingIcon && leadingIcon}
      {children}
    </button>
  );
}

export default Button;
