import { Button, Modal } from "flowbite-react";
import { useState } from "react";
import { HiPlusCircle } from "react-icons/hi";
import AddIntegrationEmebedThemeForm from "./AddIntegrationEmbedThemeForm";

export default function AddIntegrationEmbedThemeModalComponent({
  productID,
}: {
  productID: string;
}) {
  const [showModal, setShowModal] = useState(false);

  return (
    <div>
      <Button
        color="blue"
        onClick={() => {
          setShowModal(true);
        }}
      >
        <div className="flex space-x-1 align-center justify-center">
          <HiPlusCircle className="h-5 w-5" />
          <span>Add Theme for Embeds</span>
        </div>
      </Button>
      <Modal show={showModal} size="4xl" onClose={() => setShowModal(false)}>
        <Modal.Header>
          <h2 className="text-gray-700 font-bold ">Add Embed Configuration</h2>
        </Modal.Header>
        <Modal.Body className="bg-gray-50">
          <AddIntegrationEmebedThemeForm
            productID={productID}
            setShowModal={setShowModal}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
}
