import React from 'react';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {useWriteWithRAI} from "../../../../hooks/ai/useRAI";
import {useParams} from "react-router-dom";
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import {toast} from "react-toastify";
import useEditorStore from "../../../../state/useEditorStore";

const WriteWithRAIForm = (
    {
        closeModal
    }: {
        closeModal: () => void;
    }) => {
    const {prodId} = useParams<{ prodId: string }>();
    const [aiResponse, setAiResponse] = React.useState<string | null>(null);
    const {requestReplaceContent, requestAppendContent} = useEditorStore();

    const {mutate, isLoading} = useWriteWithRAI(
        (value) => {
            setAiResponse(value);
            formik.resetForm();
        },
        (error) => {
            toast.error("Error generating content");
        }
    );

    const formik = useFormik({
        initialValues: {
            content: '',
        },
        validationSchema: Yup.object({
            content: Yup.string().required('Content is required'),
        }),
        onSubmit: (values) => {
            mutate({
                productID: prodId ?? "",
                prompt: values.content,
                previousContent: aiResponse ?? ""
            });
        },
    })

    return (
        <div className="mx-auto">
            <div className="flex flex-row space-x-2">
                <h1 className="text-2xl font-bold mb-4 flex-1">Write with RAI</h1>
                <div className="flex flex-row space-x-2">
                    {aiResponse && (
                        <>
                            <button
                                onClick={(e) => {
                                    e.preventDefault();
                                    requestReplaceContent(aiResponse)
                                    closeModal()
                                }}
                                className="mb-4 py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            >
                                Replace
                            </button>
                            <button
                                onClick={(e) => {
                                    e.preventDefault();
                                    requestAppendContent(aiResponse)
                                    closeModal()
                                }}
                                className="mb-4 py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            >
                                Append
                            </button>
                        </>
                    )}
                </div>
            </div>
            <div
                className={`mb-4 transition-height duration-500 ease-in-out overflow-y-scroll bg-gray-100 rounded-lg ${aiResponse ? 'max-h-[40vh]' : 'max-h-0'}`}>
                {aiResponse ? (
                    <ReactMarkdown
                        remarkPlugins={[remarkGfm]}
                        className="prose p-[20px] text-gray-600 dark:text-gray-400 max-w-none"
                    >
                        {aiResponse}
                    </ReactMarkdown>
                ) : null
                }
            </div>

            <form onSubmit={formik.handleSubmit}>
                <div className="mb-4">
                    <label htmlFor="content" className="block text-sm font-medium text-gray-700">
                        {aiResponse ? "Update response: " : "Prompt:"}
                    </label>
                    <textarea
                        id="content"
                        name="content"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.content}
                        className="mt-1 p-2 block w-full border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
                        placeholder={aiResponse ? "e.g. Please expand a bit more on the abc ..." : "e.g. Write about a new release which increases the performance of xyz by 30% and ..."}
                    >
                    </textarea>
                    {formik.touched.content && formik.errors.content && (
                        <div className="text-red-500 mt-1 text-sm">{formik.errors.content}</div>
                    )}
                </div>
                <button
                    type="submit"
                    className="w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    disabled={isLoading}
                >
                    {isLoading ? 'Generating...' : aiResponse ? 'Regenerate' : 'Generate'}
                </button>
            </form>
        </div>
    );
};

export default WriteWithRAIForm;
