import API from "../../api";
import {useMutation} from "@tanstack/react-query";

async function updateSubscriptionPreference(
    {
        subscriberID,
        minPriority,
        language,
        minVersion,
        maxVersion
    }: {
        subscriberID: string
        minPriority?: number
        language?: string
        minVersion?: number
        maxVersion?: number
    }
) {

    var data = {
        minPriority: minPriority ? minPriority : null,
        language: language && language !== '' ? language : null,
        minVersion: minVersion ? minVersion : null,
        maxVersion: maxVersion ? maxVersion : null,
    }

    const response = await API.patch(`/public/subscriber/preferences/${subscriberID}`, data);
    return response.data;
}


const usePublicUpdateSubscriptionPreference = ({onError, onSuccess}: {
    onSuccess?: (data: any) => void;
    onError?: (error: any) => void;
}) => {
    return useMutation(updateSubscriptionPreference, {
        onSuccess: onSuccess, onError: onError
    });
}

export default usePublicUpdateSubscriptionPreference;