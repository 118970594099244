import { useState } from "react";
import { useNavigate } from "react-router-dom";

interface Plan {
  name: string;
  monthlyPrice: number;
  annualPrice: number;
  projects: number;
  releaseNotes: number;
  subscribers: number;
}

const plans: Plan[] = [
  {
    name: "START",
    monthlyPrice: 20,
    annualPrice: 20 * 12 * 0.85,
    projects: 5,
    releaseNotes: 10,
    subscribers: 100,
  },
  {
    name: "PRO",
    monthlyPrice: 40,
    annualPrice: 40 * 12 * 0.85,
    projects: 10,
    releaseNotes: 20,
    subscribers: 500,
  },
  {
    name: "BUSINESS",
    monthlyPrice: 50,
    annualPrice: 50 * 12 * 0.85,
    projects: 20,
    releaseNotes: 40,
    subscribers: 1000,
  },
];

export default function LandingHomePricing() {
  const navigate = useNavigate();
  const [isAnnual, setIsAnnual] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState("BUSINESS");

  const getPrice = (plan: Plan) => {
    return isAnnual ? plan.annualPrice : plan.monthlyPrice;
  };

  const handlePurchase = () => {
    navigate(`/app/new?plan=${selectedPlan}`);
  };

  return (
    <section className="overflow-hidden text-gray-600 body-font">
      <div className="container px-5 py-8 mx-auto">
        <div className="flex flex-col w-full mb-20 text-center">
          <h1 className="mb-2 text-3xl font-medium text-gray-900 sm:text-4xl title-font">
            Pricing
          </h1>
          <p className="mx-auto text-base leading-relaxed text-gray-500 lg:w-2/3">
            Choose the best plan that fits your needs.
          </p>
          <div className="flex mx-auto mt-6 overflow-hidden border-2 border-indigo-500 rounded">
            <button
              className={`px-4 py-1 ${
                isAnnual ? "" : "bg-indigo-500 text-white"
              }`}
              onClick={() => setIsAnnual(false)}
            >
              Monthly
            </button>
            <button
              className={`px-4 py-1 ${
                isAnnual ? "bg-indigo-500 text-white" : ""
              }`}
              onClick={() => setIsAnnual(true)}
            >
              Annually (Save 15%)
            </button>
          </div>
        </div>
        <div className="flex flex-wrap -m-4">
          {plans.map((plan) => (
            <div key={plan.name} className="w-full p-4 xl:w-1/4 md:w-1/2">
              <div
                className={`relative flex flex-col h-full p-6 overflow-hidden border-2 ${
                  selectedPlan === plan.name
                    ? "border-indigo-500"
                    : "border-gray-300"
                } rounded-lg`}
                onClick={() => setSelectedPlan(plan.name)}
              >
                {selectedPlan === plan.name && (
                  <span className="absolute top-0 right-0 px-3 py-1 text-xs tracking-widest text-white bg-indigo-500 rounded-bl">
                    SELECTED
                  </span>
                )}
                <h2 className="mb-1 text-sm font-medium tracking-widest title-font">
                  {plan.name}
                </h2>
                <h1 className="flex items-center pb-4 mb-4 text-5xl leading-none text-gray-900 border-b border-gray-200">
                  <span>${getPrice(plan)}</span>
                  <span className="ml-1 text-lg font-normal text-gray-500">
                    /{isAnnual ? "yr" : "mo"}
                  </span>
                </h1>
                <p className="mb-2 text-gray-600">Projects: {plan.projects}</p>
                <p className="mb-2 text-gray-600">
                  Release Notes/Month: {plan.releaseNotes}
                </p>
                <p className="mb-6 text-gray-600">
                  Subscribers/Month: {plan.subscribers}
                </p>
                <button className="flex items-center w-full px-4 py-2 mt-auto text-white bg-indigo-500 border-0 rounded focus:outline-none hover:bg-indigo-600">
                  Select Plan
                </button>
                <p className="mt-3 text-xs text-gray-500">
                  15 days trial for all versions.
                </p>
              </div>
            </div>
          ))}
          <div className="w-full p-4 xl:w-1/4 md:w-1/2">
            <div className="relative flex flex-col h-full p-6 overflow-hidden border-2 border-gray-300 rounded-lg">
              <h2 className="mb-1 text-sm font-medium tracking-widest title-font">
                ENTERPRISE
              </h2>
              <p className="flex-grow mt-6 mb-6 text-gray-600">
                Custom solutions for large organizations and unique
                requirements.
              </p>
              <button className="flex items-center w-full px-4 py-2 mt-auto text-white bg-gray-400 border-0 rounded focus:outline-none hover:bg-gray-500">
                Contact Us
              </button>
              <p className="mt-3 text-xs text-gray-500">
                or email us at sales@releasenotes.io
              </p>
            </div>
          </div>
        </div>
        <div className="flex justify-center mt-8">
          <button
            className="px-6 py-3 text-lg text-white bg-indigo-500 border-0 rounded focus:outline-none hover:bg-indigo-600"
            onClick={handlePurchase}
          >
            Create Organization
          </button>
        </div>
      </div>
    </section>
  );
}
