import {useMutation} from "@tanstack/react-query";
import API from "../../api";

type EditProductInfo = {
    productID: string;
    logo: string;
    name: number;
    description: string;
    privacyPolicy?: string;
    socialsTwitter?: string;
    socialsFacebook?: string;
    socialsInstagram?: string;
    socialsLinkedIn?: string;
    customerContactUs?: string;
    doubleOptIn: boolean;
};

async function editProduct(
    {
        productID,
        logo,
        name,
        description,
        privacyPolicy,
        socialsInstagram,
        socialsTwitter,
        socialsFacebook,
        socialsLinkedIn,
        customerContactUs,
        doubleOptIn
    }: EditProductInfo) {
    const response = await API.put(`/products/${productID}`, {
        logo: logo,
        name: name,
        description: description,
        privacyPolicy: privacyPolicy,
        socialsInstagram: socialsInstagram,
        socialsTwitter: socialsTwitter,
        socialsFacebook: socialsFacebook,
        socialsLinkedIn: socialsLinkedIn,
        customerContactUs: customerContactUs,
        doubleOptIn: doubleOptIn
    });

    return response.data.payload;
}

export default function useEditProduct(
    {
        onSuccess,
        onError,
    }: {
        onSuccess: () => void;
        onError: () => void;
    }) {
    return useMutation(editProduct, {
        onSuccess: () => {
            onSuccess();
        },
        onError: () => {
            onError();
        },
    });
}
