import {Button, Label, TextInput, Textarea, ToggleSwitch, Select} from "flowbite-react";
import {AvatarUploadComponent} from "../Shared/AvatarUploadComponent";
import {useFormik} from "formik";
import * as Yup from "yup";
import {useState} from "react";

interface OrganizationProductFormProps {
    initialValues: {
        logoFileID: string | undefined;
        name: string;
        doubleOptIn: boolean;
        description: string;
        logoFileURL?: string;
        privacyPolicy?: string;
        socialsTwitter?: string;
        socialsFacebook?: string;
        socialsInstagram?: string;
        socialsLinkedIn?: string;
        customerContactUs?: string;
    };

    onSubmit: (values: any) => void;
}

export function OrganizationProductForm(
    {
        initialValues,
        onSubmit,
    }: OrganizationProductFormProps) {

    const [showSocials, setShowSocials] = useState(false);

    const formik = useFormik({
        initialValues: initialValues,
        onSubmit: onSubmit,
        validationSchema: Yup.object({
            name: Yup.string().required("Name is required."),
            doubleOptIn: Yup.boolean().required("Double Opt In is required."),
            description: Yup.string().required("Description is required."),
            privacyPolicy: Yup.string().url("Privacy Policy must be a valid URL.").required("Privacy Policy is required."),
            socialsTwitter: Yup.string().url("Twitter must be a valid URL."),
            socialsFacebook: Yup.string().url("Facebook must be a valid URL."),
            socialsInstagram: Yup.string().url("Instagram must be a valid URL."),
            socialsLinkedIn: Yup.string().url("LinkedIn must be a valid URL."),
            customerContactUs: Yup.string().url("Product Contact must be a valid URL.").required("Product Contact is required."),
        }),
    });

    return (
        <form onSubmit={formik.handleSubmit} className="flex flex-col gap-4">
            <div className="flex">
                <div className="flex-1"></div>
                <ToggleSwitch checked={showSocials} label="Socials" onChange={setShowSocials}/>
            </div>
            <div className="mx-auto space-y-2">
                <AvatarUploadComponent
                    defaultImage={formik.values.logoFileURL}
                    onImageUpload={(fileID) => {
                        formik.setFieldValue("logoFileID", fileID);
                    }}
                    emptyImageLabel="Click Me"
                />
                <p>
                    <span className="text-gray-800">Product Logo</span>
                </p>
                {formik.touched.logoFileID && formik.errors.logoFileID ? (
                    <div className="text-red-500 text-xs italic">
                        {formik.errors.logoFileID}
                    </div>
                ) : null}
            </div>
            <div>
                <div className="mb-2 block">
                    <Label htmlFor="name" value="Product Name"/>
                </div>
                <TextInput
                    id="name"
                    type="text"
                    placeholder="My Super Product"
                    onChange={formik.handleChange}
                    value={formik.values.name}
                    onBlur={formik.handleBlur}
                    required
                />
                {formik.touched.name && formik.errors.name ? (
                    <div className="text-red-500 text-xs italic">
                        {formik.errors.name}
                    </div>
                ) : null}
            </div>
            <div className="flex flex-col">
                <div className="mb-2 block">
                    <Label htmlFor="name" value="Subscriber Opt-in"/>
                </div>
                <Select
                    id="doubleOptIn"
                    onChange={(e) => {
                        formik.setFieldValue("doubleOptIn", e.target.value === "double");
                    }}
                    value={formik.values.doubleOptIn ? "double" : "single"}
                    onBlur={formik.handleBlur}
                    required
                >
                    <option value="double">Double Opt-In</option>
                    <option value="single">Single Opt-In</option>
                </Select>
            </div>
            <div>
                <div className="mb-2 block">
                    <Label htmlFor="name" value="Privacy Policy URL"/>
                </div>
                <TextInput
                    id="privacyPolicy"
                    type="text"
                    placeholder="https://example.com/privacy-policy"
                    onChange={formik.handleChange}
                    value={formik.values.privacyPolicy}
                    onBlur={formik.handleBlur}
                    required
                />
                {formik.touched.privacyPolicy && formik.errors.privacyPolicy ? (
                    <div className="text-red-500 text-xs italic">
                        {formik.errors.privacyPolicy}
                    </div>
                ) : null}
            </div>
            <div>
                <div className="mb-2 block">
                    <Label htmlFor="name" value="Product Contact"/>
                </div>
                <TextInput
                    id="customerContactUs"
                    type="text"
                    placeholder="https://your-product.com/contact-us"
                    onChange={formik.handleChange}
                    value={formik.values.customerContactUs}
                    onBlur={formik.handleBlur}
                    required
                />
                {formik.touched.customerContactUs && formik.errors.customerContactUs ? (
                    <div className="text-red-500 text-xs italic">
                        {formik.errors.customerContactUs}
                    </div>
                ) : null}
            </div>
            <div>
                <div className="mb-2 block">
                    <Label htmlFor="description" value="Description"/>
                </div>
                <Textarea
                    id="description"
                    placeholder="Describe your product..."
                    required
                    onChange={formik.handleChange}
                    value={formik.values.description}
                    onBlur={formik.handleBlur}
                    rows={4}
                />
                {formik.touched.description && formik.errors.description ? (
                    <div className="text-red-500 text-xs italic">
                        {formik.errors.description}
                    </div>
                ) : null}
            </div>


            {showSocials && <div className="">
                <div>
                    <div className="mb-2 block">
                        <Label htmlFor="name" value="Twitter URL"/>
                    </div>
                    <TextInput
                        id="socialsTwitter"
                        type="text"
                        placeholder="https://twitter.com/your-product"
                        onChange={formik.handleChange}
                        value={formik.values.socialsTwitter}
                        onBlur={formik.handleBlur}
                    />
                    {formik.touched.socialsTwitter && formik.errors.socialsTwitter ? (
                        <div className="text-red-500 text-xs italic">
                            {formik.errors.socialsTwitter}
                        </div>
                    ) : null}
                </div>
                <div>
                    <div className="mb-2 block">
                        <Label htmlFor="name" value="LinkedIn URL"/>
                    </div>
                    <TextInput
                        id="socialsLinkedIn"
                        type="text"
                        placeholder="https://linkedin.com/company/your-product"
                        onChange={formik.handleChange}
                        value={formik.values.socialsLinkedIn}
                        onBlur={formik.handleBlur}
                    />
                    {formik.touched.socialsLinkedIn && formik.errors.socialsLinkedIn ? (
                        <div className="text-red-500 text-xs italic">
                            {formik.errors.socialsLinkedIn}
                        </div>
                    ) : null}
                </div>
                <div>
                    <div className="mb-2 block">
                        <Label htmlFor="name" value="Instagram URL"/>
                    </div>
                    <TextInput
                        id="socialsInstagram"
                        type="text"
                        placeholder="https://instagram.com/your-product"
                        onChange={formik.handleChange}
                        value={formik.values.socialsInstagram}
                        onBlur={formik.handleBlur}
                    />
                    {formik.touched.socialsInstagram && formik.errors.socialsInstagram ? (
                        <div className="text-red-500 text-xs italic">
                            {formik.errors.socialsInstagram}
                        </div>
                    ) : null}
                </div>
                <div>
                    <div className="mb-2 block">
                        <Label htmlFor="name" value="Facebook URL"/>
                    </div>
                    <TextInput
                        id="socialsFacebook"
                        type="text"
                        placeholder="https://facebook.com/your-product"
                        onChange={formik.handleChange}
                        value={formik.values.socialsFacebook}
                        onBlur={formik.handleBlur}
                    />
                    {formik.touched.socialsFacebook && formik.errors.socialsFacebook ? (
                        <div className="text-red-500 text-xs italic">
                            {formik.errors.socialsFacebook}
                        </div>
                    ) : null}
                </div>
            </div>}

            <Button type="submit" color="blue" isProcessing={formik.isSubmitting}>
                Submit
            </Button>
        </form>
    );
}