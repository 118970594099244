import {Pagination, Table, TextInput} from "flowbite-react";
import {ReleaseNoteWithRowId} from "../../../types/releaseNotes";
import {
    flexRender,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    useReactTable,
} from "@tanstack/react-table";
import {HiPencilAlt, HiSearch} from "react-icons/hi";
import DeleteReleaseNoteModal from "../ReleaseNoteArchiveModal";
import {useMemo, useState} from "react";
import {useNavigate} from "react-router-dom";
import NoteDateComponent from "./NoteDate";
import {ReleaseNoteViewRenderHTMLModal} from "./ReleaseNoteViewRenderHTMLModal";
import TagFilterDropdown from "./TagFilterDropDown";
import StatusFilterDropDown from "./StatusFilterDropDown";
import SendReleaseNoteModal from "../SendReleaseNoteModal";
import ToggleReleaseNoteStatus from "../Action/ToggleReleaseNoteStatus";
import {useGetUnsavedNoteIds} from "../../../hooks/releasenote/useFormStateLocalStorage";
import {toast} from "react-toastify";

const filterByTags = (row: any, columnId: any, filterValue: any) => {
    const rowTags = row.original?.[columnId];
    return filterValue.every((tag: any) => rowTags.includes(tag));
};

export function ReleaseNoteListTable(
    {
        isFetching,
        releaseNotes,
        prodId,
        orgId,
    }: {
        isFetching: boolean;
        releaseNotes: ReleaseNoteWithRowId[];
        prodId: string;
        orgId: string;
    }) {
    const navigate = useNavigate();

    const [selectedTag, setSelectedTag] = useState<string>("all");
    const unsavedNoteIDs = useGetUnsavedNoteIds();

    const columns = useMemo(
        () => [
            {
                accessorKey: "title",
                header: "Title",
                cell: (props: any) => {
                    const title = props.getValue();
                    const trimmedTitle = title.length > 30 ? title.substring(0, 30) + "..." : title;

                    return <ReleaseNoteViewRenderHTMLModal releaseNote={props.row.original} ButtonComponent={
                        () => <b className="cursor-pointer">{trimmedTitle}</b>
                    }/>;
                },
            },
            {
                accessorKey: "addedDate",
                header: "Added Date",
                cell: (props: any) => (
                    <NoteDateComponent date={props.getValue().toString() ?? ""}/>
                ),
            },
            {
                accessorKey: "publishedAt",
                header: "Published Date",
                cell: (props: any) =>
                    props.getValue()?.toString() ? (
                        <NoteDateComponent date={props.getValue().toString()}/>
                    ) : (
                        <span className="text-sm text-gray-500 dark:text-gray-400">
              Not Published Yet
            </span>
                    ),
            },
            {
                accessorKey: "isPublic",
                header: "Status",
                cell: (props: any) => {
                    const isPublic = props.getValue();
                    const rowID = props.row.original.ROWID;
                    const statusText = isPublic ? "Public" : "Draft";
                    const isUnsaved = unsavedNoteIDs.includes(rowID);

                    return (
                        <div className="flex items-center space-x-2 dark:text-gray-400">
                            <p>{statusText}</p>
                            {isUnsaved && (
                                <span
                                    className="text-xs text-gray-700 bg-gray-100 rounded-full px-2 py-1 dark:text-gray-400 dark:bg-gray-700">
                              Unsaved
                            </span>
                            )}
                        </div>
                    );
                },
                filterFn: (row: any, columnId: any, filterValue: any) => {
                    const isPublic = row.original?.[columnId];
                    if (filterValue === "all") return true;
                    if (filterValue === "draft") return !isPublic;
                    if (filterValue === "public") return isPublic;

                    return false;
                },
            },
            {
                accessorKey: "tag",
                header: "Tags",
                filterFn: filterByTags,
                cell: (props: any) => (
                    <div className="flex flex-wrap -mx-1 overflow-hidden w-36">
                        {props.getValue()?.map((v: string) => (
                            <div key={v} className="my-1 px-1">
                                <button
                                    onClick={() => {
                                        props.column.setFilterValue([v]);
                                        setSelectedTag(v);
                                    }}
                                    className="text-sm bg-gray-200 hover:bg-gray-300 dark:bg-gray-700 dark:hover:bg-gray-600 px-2 py-1 rounded-md w-full transition-colors duration-150 ease-in-out dark:text-gray-400"
                                >
                                    #{v}
                                </button>
                            </div>
                        ))}
                    </div>
                ),
            },
            {
                accessorKey: "feedbackCount",
                header: "Feedback",
                cell: (props: any) => (
                    <div className="w-max relative group">
                        <div
                            className="bg-gray-200 rounded-md text-sm px-2 py-1 text-gray-700 dark:text-gray-400 dark:bg-gray-700"
                            onClick={() => {
                                if (props.getValue().positive + props.getValue().negetive > 0)
                                    navigate(
                                        `/app/${orgId}/${prodId}/feedback?release_note=${props.row.original.ROWID}`
                                    );
                                else
                                    toast.error("No feedbacks available for this release note");
                            }}
                        >
                            {props.getValue().positive}/{props.getValue().negetive}
                        </div>
                        <span
                            className="absolute top-0 -right-2 transform translate-x-full w-auto px-2 py-1 bg-black text-white text-xs rounded hidden group-hover:block">
                          <span>Positive: {props.getValue().positive}</span>
                          <br/>
                          <span>Negative: {props.getValue().negetive}</span>
                        </span>
                    </div>
                ),
            },
            {
                header: "Actions",
                cell: ({row}: any) => (
                    <div className="flex space-x-2">
                        {row.original.isPublic ? (
                            <div
                                className="bg-gray-200 text-gray-500 hover:text-gray-900 rounded-md px-2 pt-2 pb-1 dark:text-white dark:bg-gray-700">
                                <SendReleaseNoteModal note={row.original}/>
                            </div>
                        ) : (
                            <div className="bg-gray-200 rounded-md text-xl px-1 pt-1 dark:text-white dark:bg-gray-700">
                                <ToggleReleaseNoteStatus
                                    releaseNote={row.original}
                                    prodID={prodId}
                                />
                            </div>
                        )}
                        <button
                            className="bg-gray-200 text-gray-500 hover:text-gray-900 rounded-md px-2 py-1 dark:text-white dark:bg-gray-700"
                            onClick={() => {
                                navigate(
                                    `/app/${orgId}/notes/${prodId}/edit/${row.original.ROWID}`
                                );
                            }}
                        >
                            <div className="relative group">
                                <HiPencilAlt className="h-4 w-4 text-gray-500 hover:text-gray-900"/>
                                <span
                                    className="absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-6 w-auto px-2 py-1 bg-black text-white text-xs rounded hidden group-hover:block">
                  Edit
                </span>
                            </div>
                        </button>

                        <div className="bg-gray-200 rounded-md text-xl px-1 pt-1 dark:text-white dark:bg-gray-700">
                            <DeleteReleaseNoteModal
                                releaseNote={row.original}
                                releaseNoteID={row.original.ROWID}
                                prodID={prodId}
                            />
                        </div>
                    </div>
                ),
            },
        ],
        [prodId, orgId, navigate, unsavedNoteIDs]
    );

    const table = useReactTable({
        data: releaseNotes,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        initialState: {
            pagination: {
                pageSize: 6,
            },
        },
    });

    // Dynamically get the tag options from the Data
    const tagsOptions = useMemo(() => {
        const tags = new Set<string>();
        releaseNotes.forEach((releaseNote) => {
            releaseNote.tag.forEach((tag) => {
                tags.add(tag);
            });
        });
        return Array.from(tags);
    }, [releaseNotes]);

    return (
        <div className="overflow-x-auto bg-white h-full flex flex-col dark:bg-gray-800 dark:text-white">
            <div className="pb-4 flex space-x-2 pr-2">
                <div>
                    <span className="text-gray-400 text-xs">Filter by Status: </span>
                    <StatusFilterDropDown table={table}/>
                </div>
                <div>
                    <span className="text-gray-400 text-xs">Filter by Tag: </span>
                    <TagFilterDropdown selectedTagOption={selectedTag}
                                       setSelectedTagOption={setSelectedTag}
                                       tagsOptions={tagsOptions}
                                       table={table}
                    />
                </div>
                <div className="flex-1"></div>
                <div className="flex flex-col">
                    <div className="flex-1"></div>
                    <div className="mt-auto mb-2">
                        <TextInput
                            id="search"
                            type="text"
                            icon={HiSearch}
                            placeholder="Search"
                            required
                            size={35}
                            onChange={(e) => {
                                table.setGlobalFilter(e.target.value);
                            }}
                        />
                    </div>
                </div>
            </div>
            <div className="flex-1">
                <Table hoverable className="">
                    {table.getHeaderGroups().map((headerGroup) => (
                        <Table.Head key={headerGroup.id}>
                            {headerGroup.headers.map((header) => (
                                <Table.HeadCell key={header.id}>
                                    <p>{header.column.columnDef.header?.toString() ?? ""}</p>
                                </Table.HeadCell>
                            ))}
                        </Table.Head>
                    ))}
                    <Table.Body className="divide-y dark:border dark:border-gray-700 rounded-xl">
                        {table.getRowModel().rows.map((row) => (
                            <Table.Row
                                className="bg-white dark:border-gray-700 dark:bg-gray-800"
                                key={row.id}
                            >
                                {row.getVisibleCells().map((cell) => (
                                    <Table.Cell
                                        key={cell.id}
                                        className="whitespace-nowrap font-medium text-gray-900 dark:text-white"
                                    >
                                        {isFetching ? (
                                            <>
                                                <div
                                                    className="animate-pulse bg-gray-200 h-4 my-2 w-20 rounded-md"></div>
                                            </>
                                        ) : (
                                            flexRender(cell.column.columnDef.cell, cell.getContext())
                                        )}
                                    </Table.Cell>
                                ))}
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>
            </div>

            <div className="flex ml-auto mt-2">
                <Pagination
                    currentPage={table.getState().pagination.pageIndex + 1}
                    totalPages={table.getPageCount()}
                    onPageChange={(page) => {
                        table.setPageIndex(page - 1);
                    }}
                />
            </div>
        </div>
    );
}
