import useUserOrgStore from "./useUserOrgStore";

function useGetOrgRoleForUser(orgID: string) {
  const userRole = useUserOrgStore((state) => {
    try {
      return state.userOrgs.filter(
        (userOrg) => userOrg.organizationID === orgID
      )[0].userRole;
    } catch (e) {
      return "none";
    }
  });

  return userRole;
}

export default useGetOrgRoleForUser;
