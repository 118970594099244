import DefaultSettingLayout from "../../../components/Settings/SettingsDefaultLayout";

export default function DefaultSettingSecurityPage() {
  return (
    <>
      <DefaultSettingLayout>
        <div>Security Page</div>
      </DefaultSettingLayout>
    </>
  );
}
