import { useFormik } from "formik";
import {
  IntegrationEmbedLanguageOption,
  IntegrationEmbedTagOption,
  IntegrationEmbedThemeOption,
} from "../../../types/embeds";
import * as Yup from "yup";
import Select from "react-select";
import { Button } from "flowbite-react";
import useAddIntegrationEmbedConfig from "../../../hooks/integrations/embeds/useAddIntegrationEmbedConfig";
import { useQueryClient } from "@tanstack/react-query";
import { Link } from "react-router-dom";
import React from "react";

type AddIntegrationEmbedFormProps = {
  afterSubmit: (data: any) => void;
  onCancel: () => void;
  themeList: IntegrationEmbedThemeOption[];
  tagList: IntegrationEmbedTagOption[];
  languageList: IntegrationEmbedLanguageOption[];
  isEdit?: boolean;
  productID: string;
};

// const getPriorityLabel = (level: string) => {
//   switch (level) {
//     case '1':
//       return 'Patch Release';
//     case '3':
//       return 'Minor Release';
//     case '5':
//       return 'Major Release';
//     default:
//       return `Level ${level}`;
//   }
// };

export default function AddIntegrationEmbedForm({
  afterSubmit,
  onCancel,
  themeList,
  productID,
  tagList,
  languageList,
}: AddIntegrationEmbedFormProps) {
  const queryClient = useQueryClient();

  const {
    mutate: addIntegrationEmbedConfig,
    isLoading,
    isError,
    error,
  } = useAddIntegrationEmbedConfig({
    onSuccess: () => {
      queryClient.invalidateQueries(["integration", "embeds", productID]);
      afterSubmit({});
    },
    onError: () => {
      onCancel();
    },
  });

  const formik = useFormik({
    initialValues: {
      embedName: "",
      theme: "",
      language: "",
      tags: [],
      releaseCountLimit: undefined,
      priorityFilter: undefined,
      lowestVersionFilter: undefined,
      higestVersionFilter: undefined,
      lowestDateFilter: undefined,
      highestDateFilter: undefined,
    },
    onSubmit: (values) => {
      console.log(values);
      addIntegrationEmbedConfig({
        formInput: values,
        productId: productID,
      });
    },
    validationSchema: Yup.object({
      embedName: Yup.string().required(
        "Required: Every embed must be associated with a label"
      ),
      theme: Yup.string()
        .required("Required: Every embed must be associated with a theme")
        .oneOf(themeList.map((theme) => theme.id)),
    }),
  });

  function getTagValue(baseValue: string[]) {
    return baseValue.map((tag) => {
      return { value: tag, label: tag };
    });
  }

  function getLanguageValue(baseValue: string) {
    return {
      value: baseValue,
      label: languageList.find((l) => l.code === baseValue)?.language,
    };
  }

  function getThemeValue(baseValue: string) {
    return {
      value: baseValue,
      label: themeList.find((t) => t.id === baseValue)?.name,
    };
  }

  return (
    <div>
      {isError && <div className="text-red-500">{`${error}`}</div>}

      <form onSubmit={formik.handleSubmit} className="flex flex-col space-y-2">
        {/* Name */}
        <div>
          <label
              htmlFor="embedName"
              className="block text-sm font-medium text-gray-700"
          >
            Embed Name
          </label>
          <div className="mt-1">
            <input
                type="text"
                name="embedName"
                id="embedName"
                onChange={formik.handleChange}
                value={formik.values.embedName}
                onBlur={formik.handleBlur}
                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            />
            {formik.errors.embedName && formik.touched.embedName && (
                <div className="text-red-500">{formik.errors.embedName}</div>
            )}
          </div>
        </div>

        {/* Theme from themeList */}
        <div>
          <label
              htmlFor="theme"
              className="block text-sm font-medium text-gray-700"
          >
            Theme
          </label>
          <div className="mt-1">
            <Select
                options={themeList.map((theme) => {
                  return {
                    value: theme.id,
                    label: theme.name,
                  };
                })}
                isMulti={false}
                name="theme"
                onChange={(e) => {
                  formik.setFieldValue("theme", e?.value);
                }}
                value={getThemeValue(formik.values.theme)}
                onBlur={formik.handleBlur}
            />
            {themeList.length === 0 && (
                <div className="text-red-400">
                  No themes found. Please create a theme first from{" "}
                  <Link to={`theme`} className="text-red-500">
                    here
                  </Link>
                  .
                </div>
            )}
            {formik.errors.theme && formik.touched.theme && (
                <div className="text-red-500">{formik.errors.theme}</div>
            )}
          </div>
        </div>

        {/* Language from languageList */}
        <div>
          <label
              htmlFor="language"
              className="block text-sm font-medium text-gray-700"
          >
            Language
          </label>
          <div className="mt-1">
            <Select
                options={languageList.map((language) => {
                  return {
                    value: language.code,
                    label: language.language,
                  };
                })}
                isMulti={false}
                name="language"
                onChange={(e) => {
                  formik.setFieldValue("language", e?.value);
                }}
                value={getLanguageValue(formik.values.language)}
                onBlur={formik.handleBlur}
            />

            {formik.errors.language && formik.touched.language && (
                <div className="text-red-500">{formik.errors.language}</div>
            )}
          </div>
        </div>

        {/* Release Count Limit */}
        <div>
          <label
              htmlFor="releaseCountLimit"
              className="block text-sm font-medium text-gray-700"
          >
            Release Count Limit
          </label>
          <div className="mt-1">
            <input
                type="number"
                name="releaseCountLimit"
                id="releaseCountLimit"
                onChange={formik.handleChange}
                value={formik.values.releaseCountLimit}
                onBlur={formik.handleBlur}
                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            />
            {formik.errors.releaseCountLimit &&
                formik.touched.releaseCountLimit && (
                    <div className="text-red-500">
                      {formik.errors.releaseCountLimit}
                    </div>
                )}
          </div>
        </div>
        <div>
          <label
              htmlFor="priorityFilter"
              className="block text-sm font-medium text-gray-700"
          >
            Update Granularity
          </label>
          <div className="mt-1">
            <select
                id="priority"
                required
                className="w-full my-1 border-solid border-2 border-gray-300 rounded p-2"
                value={formik.values.priorityFilter}
                onBlur={formik.handleBlur}
                onChange={(e) => {
                  formik.setFieldValue("priorityFilter", Number(e.target.value));
                }}
            >
              <option value="5">Major Releases only</option>
              <option value="3">Minor Release and above</option>
              <option value="1">Patch Releases and above</option>
              <option value="0" selected>All Releases</option>
            </select>
            {formik.errors.priorityFilter && formik.touched.priorityFilter && (
                <div className="text-red-500">{formik.errors.priorityFilter}</div>
            )}
          </div>
        </div>


        {/* Tag Multi Select from tagList */}
        <div>
          <label
              htmlFor="tags"
              className="block text-sm font-medium text-gray-700"
          >
            Tags
          </label>
          <div className="mt-1">
            <Select
                options={tagList.map((tag) => {
                  return {value: tag.label, label: tag.label};
                })}
                isMulti={true}
                name="tags"
                onChange={(e) => {
                  formik.setFieldValue(
                      "tags",
                      e.map((v) => {
                        return v.value;
                      })
                  );
                }}
                value={getTagValue(formik.values.tags)}
                onBlur={formik.handleBlur}
            />

            {formik.errors.tags && formik.touched.tags && (
                <div className="text-red-500"></div>
            )}
          </div>
        </div>

        <div className="flex space-x-2">
          {/* Lowest Version Filter */}
          <div className="flex-1">
            <label
                htmlFor="lowestVersionFilter"
                className="block text-sm font-medium text-gray-700"
            >
              Lowest Version Filter
            </label>
            <div className="mt-1">
              <input
                  type="number"
                  name="lowestVersionFilter"
                  id="lowestVersionFilter"
                  onChange={formik.handleChange}
                  value={formik.values.lowestVersionFilter}
                  onBlur={formik.handleBlur}
                  className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              />
              {formik.errors.lowestVersionFilter &&
                  formik.touched.lowestVersionFilter && (
                      <div className="text-red-500">
                        {formik.errors.lowestVersionFilter}
                      </div>
                  )}
            </div>
          </div>

          {/* Highest Version Filter */}
          <div className="flex-1">
            <label
                htmlFor="higestVersionFilter"
                className="block text-sm font-medium text-gray-700"
            >
              Highest Version Filter
            </label>
            <div className="mt-1">
              <input
                  type="number"
                  name="higestVersionFilter"
                  id="higestVersionFilter"
                  onChange={formik.handleChange}
                  value={formik.values.higestVersionFilter}
                  onBlur={formik.handleBlur}
                  className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              />
              {formik.errors.higestVersionFilter &&
                  formik.touched.higestVersionFilter && (
                      <div className="text-red-500">
                        {formik.errors.higestVersionFilter}
                      </div>
                  )}
            </div>
          </div>
        </div>

        <div className="flex space-x-2">
          {/* Lowest Date Filter */}
          <div className="flex-1">
            <label
                htmlFor="lowestDateFilter"
                className="block text-sm font-medium text-gray-700"
            >
              Lowest Date Filter
            </label>
            <div className="mt-1">
              <input
                  type="date"
                  name="lowestDateFilter"
                  id="lowestDateFilter"
                  onChange={formik.handleChange}
                  value={formik.values.lowestDateFilter}
                  onBlur={formik.handleBlur}
                  className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              />
              {formik.errors.lowestDateFilter &&
                  formik.touched.lowestDateFilter && (
                      <div className="text-red-500">
                        {formik.errors.lowestDateFilter}
                      </div>
                  )}
            </div>
          </div>

          {/* Highest Date Filter */}
          <div className="flex-1">
            <label
                htmlFor="highestDateFilter"
                className="block text-sm font-medium text-gray-700"
            >
              Highest Date Filter
            </label>
            <div className="mt-1">
              <input
                  type="date"
                  name="highestDateFilter"
                  id="highestDateFilter"
                  onChange={formik.handleChange}
                  value={formik.values.highestDateFilter}
                  onBlur={formik.handleBlur}
                  className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              />
              {formik.errors.highestDateFilter &&
                  formik.touched.highestDateFilter && (
                      <div className="text-red-500">
                        {formik.errors.highestDateFilter}
                      </div>
                  )}
            </div>
          </div>
        </div>

        <div className="mt-4">
          <Button color={"blue"} type="submit" isProcessing={isLoading}>
            Submit
          </Button>
        </div>
      </form>
    </div>
  );
}
