import API from "../../api";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {toast} from "react-toastify";

async function addOrUpdateEmailConfig({productID, data}: { productID: string, data: any }) {
    const response = await API.post(`/email-config/sendgrid/${productID}`, {
        ...data,
    });

    return response.data;
}

export default function useAddOrUpdateEmailConfig({productID, onSuccess, onError}: {
    productID: string
    onSuccess: () => void,
    onError: () => void
}) {
    const queryClient = useQueryClient();
    return useMutation(async (data: any) => {
        await addOrUpdateEmailConfig({productID, data})
    }, {
        onSuccess: () => {
            toast.success("Email Configuration Updated");
            queryClient.invalidateQueries(['emailconfig-validation', productID]);
            onSuccess();
        },
        onError: () => {
            toast.error("Error Updating Email Configuration. Please try again later.");
            onError();
        },
    });
}