import { Button, Modal } from "flowbite-react";
import { useState } from "react";
import { HiPlusCircle } from "react-icons/hi";
import OrganizationMemberForm from "./OrganizationMemberForm";

export default function OrganizationMemberAddModal({
  organizationID,
  membersList,
}: {
  organizationID: string;
  membersList: string[];
}) {
  const [openModal, setOpenModal] = useState(false);

  return (
    <div>
      <div>
        <Button color="blue" onClick={() => setOpenModal(true)}>
          <div className="flex space-x-1 align-center justify-center">
            <HiPlusCircle className="h-5 w-5" />
            <span>Add New</span>
          </div>
        </Button>
      </div>
      <Modal show={openModal} dismissible onClose={() => setOpenModal(false)}>
        <Modal.Header>Add a new member to your organization</Modal.Header>
        <Modal.Body>
          <OrganizationMemberForm
            onSuccess={() => setOpenModal(false)}
            onError={() => setOpenModal(false)}
            email={""}
            orgID={organizationID}
            role={"admin"}
            existingMemberEmailList={membersList}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
}
