import { useMutation } from "@tanstack/react-query";
import API from "../../api";
import useUserOrgStore from "../store/useUserOrgStore";

interface UpdateMemberRoleParams {
  memberId: string;
  orgId: string;
  newRole: string;
}

async function getUserOrgDetails() {
  const data = await API.get("/user-orgs");
  return data.data.payload;
}

const updateMemberRole = async ({
  memberId,
  orgId,
  newRole,
}: UpdateMemberRoleParams) => {
  const response = await API.patch(`/users/${orgId}/members/${memberId}`, {
    role: newRole,
  });

  if (response.status !== 200) {
    throw new Error("Failed to update member role");
  }

  return response.data;
};

function useUpdateMemberRole({
  onSuccess,
  onError,
}: {
  onSuccess: (data: any) => void;
  onError: (error: any) => void;
}) {
  const addUserOrg = useUserOrgStore((state) => state.addUserOrg);
  const resetUserOrgs = useUserOrgStore((state) => state.resetUserOrgs);

  return useMutation(updateMemberRole, {
    onSuccess: (v) => {
      getUserOrgDetails()
        .then((userOrgs) => {
          resetUserOrgs();
          userOrgs.forEach((userOrg: any) => {
            addUserOrg({
              organizationID: userOrg.organizationID,
              organizationName: userOrg.name,
              userRole: userOrg.role,
            });
          });
          onSuccess(v);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onError: onError,
  });
}

export default useUpdateMemberRole;
