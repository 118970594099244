import { Navigate } from "react-router-dom";
import API from "../../api";
import { useEffect, useState } from "react";
import LoadingPage from "../../components/Shared/LoadingPage";

const RedirectRoute = () => {
  const [orgID, setOrgID] = useState<string | null>(
    localStorage.getItem("orgID") || null
  );

  const [prodID, setProdID] = useState<string | null>(
    localStorage.getItem("prodID") || null
  );

  const [showProcessing, setShowProcessing] = useState<boolean>(false);

  useEffect(() => {
    const fetchDefaultOrgAndProduct = async (): Promise<void> => {
      try {
        const response = await API.get("defaultOrgProduct", {
          headers: {
            "Content-Type": "application/json",
          },
        });

        const orgs: { organizationId: string; ROWID: string } =
          response.data.payload;

        if (orgs.organizationId && orgs.ROWID) {
          if (!orgID || !prodID) {
            const defaultOrgID = orgs.organizationId;
            localStorage.setItem("orgID", defaultOrgID);
            setOrgID(defaultOrgID);

            const defaultProdID = orgs.ROWID;
            localStorage.setItem("prodID", defaultProdID);
            setProdID(defaultProdID);
          }
        } else {
          setShowProcessing(true);
        }
      } catch (error) {
        console.error(
          "Failed to fetch default organization and product:",
          error
        );

        setShowProcessing(true);
      }
    };

    fetchDefaultOrgAndProduct();
  }, [showProcessing, orgID, prodID]);

  if (showProcessing) {
    return (
      <div className="flex items-center justify-center h-screen bg-gray-100">
        <div className="text-center">
          <h1 className="text-4xl font-bold text-gray-800 mb-4">
            We are setting up your account...
          </h1>
          <p className="text-lg text-gray-700 mb-6">
            Please try again in a while
          </p>
          <div className="animate-spin mx-auto rounded-full h-12 w-12 border-t-2 border-b-2 border-gray-500"></div>
        </div>
      </div>
    );
  }

  if (orgID === null || prodID === null) {
    return <LoadingPage />;
  }

  return <Navigate to={`/app/${orgID}/notes/${prodID}/dash`} replace />;
};

export default RedirectRoute;
