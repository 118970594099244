import { toast } from "react-toastify";
import GenericDeleteModal from "../../../Shared/GenericDeleteModal";
import { useQueryClient } from "@tanstack/react-query";

export default function DeleteIntegrationEmbedThemeModalComponent({
  embedThemeName,
  embedThemeID,
  productID,
}: {
  embedThemeName: string;
  embedThemeID: string;
  productID: string;
}) {
  const queryClient = useQueryClient();

  return (
    <GenericDeleteModal
      apiUrl={`/embed/themes/${embedThemeID}`}
      onDeleteSuccess={() => {
        toast.success("Embed theme deleted");
        queryClient.invalidateQueries([
          "integration",
          "embeds",
          "themes",
          productID,
        ]);
      }}
      titleText={embedThemeName}
    />
  );
}
