import { Button, Label } from "flowbite-react";
import { useScheduleReleaseNoteImmidiateSend } from "../../../../pages/ReleaseNotes/hooks/useReleaseNotes";
import { toast } from "react-toastify";

export default function PublishModalImmidiateSendEmailComponent({
  releaseNoteID,
  isLoading,
  setIsLoading,
}: {
  releaseNoteID: string;
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
}) {
  const {
    mutate: scheduleReleaseNoteImmidiateSend,
    isLoading: isScheduleReleaseNoteImmidiateSendLoading,
    isError: isScheduleReleaseNoteImmidiateSendError,
    error: scheduleReleaseNoteImmidiateSendError,
  } = useScheduleReleaseNoteImmidiateSend({
    onError: () => {
      setIsLoading(false);
      toast.error("Error sending email");
    },
    onSuccess: () => {
      setIsLoading(false);
      toast.success("Email sent successfully");
    },
  });

  const handleImmidiateEmailSend = () => {
    setIsLoading(true);
    scheduleReleaseNoteImmidiateSend({ releaseNoteID: releaseNoteID });
  };

  if (isScheduleReleaseNoteImmidiateSendError) {
    return <div>Error: {`${scheduleReleaseNoteImmidiateSendError}`}</div>;
  }
  return (
    <div className="flex flex-row space-y-3">
      <div className="flex-1 flex flex-col space-y-1">
        <Label className="mb-2">Send Release Note Immediately</Label>
        <p className="text-sm text-gray-600">
          Use this option to send the release note to all intended recipients
          immediately.
        </p>
      </div>
      <Button
        onClick={handleImmidiateEmailSend}
        color="dark"
        className="w-max"
        isProcessing={isScheduleReleaseNoteImmidiateSendLoading}
        disabled={isLoading || isScheduleReleaseNoteImmidiateSendLoading}
      >
        <div className="w-32">Send Now</div>
      </Button>
    </div>
  );
}
