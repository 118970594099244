import API from "../../api";
import {useMutation} from "@tanstack/react-query";


async function translateWithRAI({message, targetLanguage}: {
    message: string,
    targetLanguage: string,
}) {
    if (!message) {
        throw new Error("Product ID is required");
    }

    if (!targetLanguage) {
        throw new Error("Prompt is required");
    }

    const response = await API.post(`ai/translate-with-rai`, {
        "message": message,
        "targetLanguage": targetLanguage,
        "orinalLanguage": "auto-detect",
    });

    return await response.data.payload
}

export function useTranslateWithRAI(
    onSuccess: (value: any) => void,
    onError: (error: any) => void,
) {
    return useMutation(translateWithRAI, {
        onSuccess: onSuccess,
        onError: onError
    });
}
