import {useQuery} from "@tanstack/react-query";
import API from "../../api";

async function getEmailConfigValidationStatus({productID}: { productID: string }) {
    const response = await API.get(`/email-config/validate/${productID}`);
    return response.data.payload;
}

export default function useValidateEmailConfig({productID}: { productID: string }) {
    const {
        data,
        isLoading,
        error,
        isFetching
    } = useQuery(['emailconfig-validation', productID], () => getEmailConfigValidationStatus({productID}), {
        cacheTime: 60,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
    })

    return {
        data,
        isLoading,
        error,
        isFetching
    }
}
