import React, { useEffect } from "react";
import LoadingPage from "../../components/Shared/LoadingPage";

const Login: React.FC = () => {
  useEffect(() => {
    window.location.reload();
  }, []);

  return <LoadingPage fullHeight={true} />;
};

export default Login;
