import { create } from "zustand";

type UserOrg = {
  organizationID: string;
  organizationName: string;
  userRole: string;
};

type UserOrgStore = {
  userOrgs: UserOrg[];
  addUserOrg: (userOrg: UserOrg) => void;
  resetUserOrgs: () => void;
};

const useUserOrgStore = create<UserOrgStore>()((set) => {
  return {
    userOrgs: [],
    addUserOrg: (userOrg: UserOrg) =>
      set((state) => ({
        userOrgs: [...state.userOrgs, userOrg],
      })),
    resetUserOrgs: () => set({ userOrgs: [] }),
  };
});

export default useUserOrgStore;
