import API from "../../api";
import {useMutation} from "@tanstack/react-query";


async function writeWithRAI({prompt, productID, previousContent}: {
    prompt: string,
    productID: string,
    previousContent: string
}) {
    if (!productID) {
        throw new Error("Product ID is required");
    }

    if (!prompt) {
        throw new Error("Prompt is required");
    }

    const response = await API.post(`ai/${productID}/write-with-rai`, {
        "prompt": prompt,
        "previousContent": previousContent
    });

    return await response.data.payload
}

export function useWriteWithRAI(
    onSuccess: (value: any) => void,
    onError: (error: any) => void,
) {
    return useMutation(writeWithRAI, {
        onSuccess: onSuccess,
        onError: onError
    });
}
