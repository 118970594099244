import {Table} from "flowbite-react";
import {Product} from "../../../../pages/Products/hooks/useProducts";
import OrganizationProductDeleteModal from "../../../Product/OrganizationProductDeleteModal";
import OrganizationProductEditModal from "../../../Product/OrganizationProductEditModal";
import useGetOrgRoleForUser from "../../../../hooks/store/useGetOrgRoleForUser";
import OrganizationProductEmailConfigModal from "../../../Product/OrganizationProductEmailConfigModal";

export default function SettingsOrganizationProductListTable(
    {
        products,
        orgID,
    }: {
        products: Product[];
        orgID: string;
    }) {
    const userRole = useGetOrgRoleForUser(orgID ?? "");

    return (
        <>
            <Table>
                <Table.Head>
                    <Table.HeadCell className="bg-gray-200">ID</Table.HeadCell>
                    <Table.HeadCell className="bg-gray-200">Logo</Table.HeadCell>
                    <Table.HeadCell className="bg-gray-200">Name</Table.HeadCell>
                    <Table.HeadCell className="bg-gray-200">
                        Subscriber Opt-In
                    </Table.HeadCell>
                    <Table.HeadCell className="bg-gray-200">
                        Draft Releases
                    </Table.HeadCell>
                    <Table.HeadCell className="bg-gray-200">
                        Public Releases
                    </Table.HeadCell>
                    {userRole === "admin" && (
                        <Table.HeadCell className="bg-gray-200">
                            <span className="sr-only">Edit</span>
                        </Table.HeadCell>
                    )}
                </Table.Head>
                <Table.Body className="divide-y">
                    {products.map((product) => (
                        <Table.Row className="bg-gray-50 dark:border-gray-700 dark:bg-gray-800">
                            <Table.Cell>{product.ROWID}</Table.Cell>
                            <Table.Cell>
                                <img
                                    src={product.logo}
                                    className="w-6 h-6 rounded-full"
                                    alt="Product Logo"
                                />
                            </Table.Cell>
                            <Table.Cell>{product.name}</Table.Cell>
                            <Table.Cell>{(product.doubleOptIn) ? "Double": "Single"}</Table.Cell>
                            <Table.Cell>{product.private_releases}</Table.Cell>
                            <Table.Cell>{product.public_releases}</Table.Cell>

                            {userRole === "admin" && (
                                <Table.Cell className="flex space-x-1">
                                    <div className="flex-1"></div>
                                    <div className="flex space-x-1">
                                        <div>
                                            <OrganizationProductEditModal
                                                organizationID={product.orgID}
                                                productID={product.ROWID}
                                                initialValues={{
                                                    logoURL: product.logo,
                                                    name: product.name,
                                                    doubleOptIn: product.doubleOptIn,
                                                    description: product.description,
                                                    privacyPolicy: product.privacyPolicy,
                                                    socialsTwitter: product.socialsTwitter ?? "",
                                                    socialsFacebook: product.socialsFacebook ?? "",
                                                    socialsInstagram: product.socialsInstagram ?? "",
                                                    socialsLinkedIn: product.socialsLinkedIn ?? "",
                                                    customerContactUs: product.customerContactUs,
                                                }}
                                            />
                                        </div>
                                        <div>
                                            <OrganizationProductEmailConfigModal productID={product.ROWID}/>
                                        </div>
                                        {products.length > 1 && (
                                            <div>
                                                <OrganizationProductDeleteModal
                                                    organizationID={product.orgID}
                                                    productID={product.ROWID}
                                                    productName={product.name}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </Table.Cell>
                            )}
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
        </>
    );
}
