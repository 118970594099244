import { BlockPicker } from "react-color";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useAddIntegrationEmbedTheme } from "../../../../hooks/integrations/embeds/useListIntegrationEmbedConfigsForProduct";
import { Button } from "flowbite-react";
import { useQueryClient } from "@tanstack/react-query";

export default function AddIntegrationEmbedThemeForm({
  productID,
  setShowModal,
}: {
  productID: string;
  setShowModal: (show: boolean) => void;
}) {
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useAddIntegrationEmbedTheme({
    onError: () => {},
    onSuccess: () => {
      queryClient.invalidateQueries([
        "integration",
        "embeds",
        "themes",
        productID,
      ]);
      queryClient.invalidateQueries([
        "listIntegrationEmbedConfigSelectInputValues",
        productID,
      ]);
      setShowModal(false);
    },
  });

  const validationSchema = Yup.object({
    themeName: Yup.string().required("Theme name is required"),
    accentColor: Yup.string().required("Accent color is required"),
    themeColor: Yup.string().required("Theme color is required"),
    textColor: Yup.string().required("Text color is required"),
  });

  const formik = useFormik({
    initialValues: {
      themeName: "",
      accentColor: "",
      themeColor: "",
      textColor: "",
      accentTextColor: "",
    },
    validationSchema,
    onSubmit: (values) => {
      mutate({
        productID: productID,
        name: values.themeName,
        accentColor: values.accentColor,
        themeColor: values.themeColor,
        textColor: values.textColor,
        accentTextColor: values.accentTextColor,
      });
    },
  });

  return (
    <div className="flex flex-col p-4">
      <form onSubmit={formik.handleSubmit}>
        <div className="mb-4">
          <label
            htmlFor="themeName"
            className="block text-gray-700 text-sm font-bold mb-2"
          >
            Theme Name
          </label>
          <input
            id="themeName"
            type="text"
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.themeName}
          />
          {formik.touched.themeName && formik.errors.themeName ? (
            <div className="text-red-500 text-xs italic">
              {formik.errors.themeName}
            </div>
          ) : null}
        </div>

        <div className="flex flex-row space-x-3">
          <div className="mb-4 ">
            <label
              htmlFor="accentColor"
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              Accent Color
            </label>
            <BlockPicker
              color={formik.values.accentColor}
              onChangeComplete={(color: any) =>
                formik.setFieldValue("accentColor", color.hex)
              }
            />
            {formik.touched.accentColor && formik.errors.accentColor ? (
              <div className="text-red-500 text-xs italic">
                {formik.errors.accentColor}
              </div>
            ) : null}
          </div>

          <div className="mb-4">
            <label
              htmlFor="themeColor"
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              Theme Color
            </label>
            <BlockPicker
              color={formik.values.themeColor}
              onChangeComplete={(color: any) =>
                formik.setFieldValue("themeColor", color.hex)
              }
            />
            {formik.touched.themeColor && formik.errors.themeColor ? (
              <div className="text-red-500 text-xs italic">
                {formik.errors.themeColor}
              </div>
            ) : null}
          </div>

          <div className="mb-4">
            <label
              htmlFor="textColor"
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              Text Color
            </label>
            <BlockPicker
              color={formik.values.textColor}
              onChangeComplete={(color: any) =>
                formik.setFieldValue("textColor", color.hex)
              }
            />
            {formik.touched.textColor && formik.errors.textColor ? (
              <div className="text-red-500 text-xs italic">
                {formik.errors.textColor}
              </div>
            ) : null}
          </div>
          <div className="mb-4">
            <label
              htmlFor="accentTextColor"
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              Accent Text Color
            </label>
            <BlockPicker
              color={formik.values.accentTextColor}
              onChangeComplete={(color: any) =>
                formik.setFieldValue("accentTextColor", color.hex)
              }
            />
            {formik.touched.accentTextColor && formik.errors.accentTextColor ? (
              <div className="text-red-500 text-xs italic">
                {formik.errors.accentTextColor}
              </div>
            ) : null}
          </div>
        </div>

        <Button isProcessing={isLoading} color="blue" type="submit">
          Submit
        </Button>
      </form>
    </div>
  );
}
