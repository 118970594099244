import { Modal } from "flowbite-react";
import React from "react";
import { HiOutlinePencil } from "react-icons/hi";
import OrganizationProfileForm from "./OrganizationProfileForm";
import { useParams } from "react-router-dom";
import { useOrganizations } from "../../pages/Organizations/hooks/useOrganizations";
import { Organization } from "../../hooks/store/useOrgStore";
import { useUpdateOrganization } from "../../pages/Organizations/hooks/useUpdateOrganization";
import { OrganizationInfo } from "../../types/organization";
import { useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

export default function OrganizationEditModal() {
  const [showModal, setShowModal] = React.useState(false);

  const [organization, setOrganization] = React.useState<
    Organization | undefined
  >();

  const { orgId } = useParams();

  const { data: organizations, isLoading: organizationDataLoading } =
    useOrganizations();

  React.useEffect(() => {
    const organization = organizations?.find((e) => {
      return e.ROWID === orgId;
    });

    setOrganization(organization);
  }, [orgId, organizations]);

  const queryClient = useQueryClient();

  const { mutate: updateOrganization } = useUpdateOrganization(
    (data) => {
      setShowModal(false);
      queryClient.invalidateQueries(["organizations"]);
      toast.success("Organization Updated Successfully");
    },
    (error) => {
      console.log(error);
      toast.error("Error updating organization");
    }
  );

  function onSubmit(values: OrganizationInfo) {
    updateOrganization({
      id: orgId,
      ...values,
    });
  }

  return (
    <div>
      <div>
        <button
          onClick={() => {
            setShowModal(true);
          }}
          className="group p-2 pl-4 bg-blue-500 rounded-full mt-2 mr-2 inline-flex items-center justify-center overflow-hidden hover:w-auto transition-all duration-300 ease-in-out"
        >
          <HiOutlinePencil className="w-4 h-4 text-white" />
          <span className="ml-2 group-hover:mr-2 text-white max-w-0 group-hover:max-w-xs overflow-hidden transition-all duration-300 ease-in-out">
            Organization
          </span>
        </button>
      </div>
      <div>
        <Modal show={showModal} size="3xl" onClose={() => setShowModal(false)}>
          <Modal.Header>
            <h2 className="text-gray-700 font-bold">
              Edit Organization Details
            </h2>
          </Modal.Header>
          <Modal.Body>
            {organizationDataLoading || !organization ? (
              <div>Loading</div>
            ) : (
              <OrganizationProfileForm
                organization={{
                  description: organization.description,
                  id: organization.ROWID,
                  phone: organization.phone,
                  name: organization.name,
                  address: organization.address,
                  city: organization.city,
                  state: organization.state,
                  zip: organization.zipCode,
                  logo: organization.logo,
                }}
                onSubmit={onSubmit}
              />
            )}
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
}
