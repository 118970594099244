import { useQuery } from "@tanstack/react-query";
import API from "../../../api";
import { Organization } from "../../../hooks/store/useOrgStore";

const getOrganizations = async (): Promise<Organization[]> => {
  try {
    const response = await API.get("organizations", {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data.payload;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export function useOrganizations() {
  return useQuery({
    queryKey: ["organizations"],
    queryFn: getOrganizations,
    staleTime: 1000 * 60 * 15,
  });
}
