import { createContext, useState, useContext, useEffect } from "react";

export type Theme = "dark" | "light";

interface IThemeProviderReturnProps {
  theme: Theme;
  toggleTheme: (theme: Theme) => void;
}

export const ThemeContext = createContext<IThemeProviderReturnProps | null>(
  null
);

interface IThemeProvider {
  children: React.ReactNode;
}

export const ThemeProvider = (props: IThemeProvider) => {
  const currentTheme: Theme = localStorage.getItem("theme") as Theme;
  const [theme, setTheme] = useState<Theme>(currentTheme || "light");

  const toggleTheme = (theme: Theme) => {
    setTheme(theme);
    localStorage.setItem("theme", theme);
  };

  useEffect(() => {
    if (theme === "dark") {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  }, [theme]);

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      {props.children}
    </ThemeContext.Provider>
  );
};

export const useTheme: () => IThemeProviderReturnProps = () => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error("useTheme must be used within a ThemeProvider");
  }
  return context;
};
