import {Button, Label, Select, TextInput} from "flowbite-react";
import LanguageSelect from "../Note/Add/lanugage";
import {useFormik} from "formik";
import * as Yup from "yup";

export default function SubscriberPreferenceForm(
    {
        initialValues,
        onSubmit,
        isSubmitting = false
    }: {
        initialValues: {
            minPriority: string;
            language: string;
            minVersion: number | null;
            maxVersion: number | null;
        };
        onSubmit: (values: any) => void;
        isSubmitting?: boolean;
    }) {

    const formik = useFormik({
        initialValues: {
            ...initialValues,
            minVersion: initialValues.minVersion || "",
            maxVersion: initialValues.maxVersion || "",
        },
        validationSchema: Yup.object({
            minPriority: Yup.string().required('Required'),
            language: Yup.string().required('Required'),
            minVersion: Yup.number().nullable().transform(value => (isNaN(value) ? null : value)).min(0, 'Must be greater than or equal to 0'),
            maxVersion: Yup.number().nullable().transform(value => (isNaN(value) ? null : value)).min(Yup.ref('minVersion'), 'Must be greater than min version'),
        }),
        onSubmit: values => {
            onSubmit(values);
        },
    });


    return (
        <form onSubmit={formik.handleSubmit} className="space-y-6">
            <div>
                <Label htmlFor="minPriority">Release Note Granularity</Label>
                <Select
                    id="minPriority"
                    name="minPriority"
                    onChange={formik.handleChange}
                    value={formik.values.minPriority}
                    placeholder="Select a granularity..."
                    onBlur={formik.handleBlur}
                >
                    <option value="0">All Releases</option>
                    <option value="1">Patch Release and above</option>
                    <option value="3">Minor Release and above</option>
                    <option value="5">Major Releases only</option>
                </Select>
                {formik.touched.minPriority && formik.errors.minPriority ? (
                    <div className="text-red-500 text-sm">{formik.errors.minPriority}</div>
                ) : null}
            </div>

            <div>
                <LanguageSelect
                    selectedLanguage={formik.values.language}
                    setSelectedLanguage={(selected: string | null) => {
                        formik.setFieldValue('language', selected)
                    }}
                />
                {formik.touched.language && formik.errors.language ? (
                    <div className="text-red-500 text-sm">{formik.errors.language}</div>
                ) : null}
            </div>

            <div className="flex flex-row space-x-2">
                <div className="flex-1">
                    <Label htmlFor="minVersion">Product Version (min)</Label>
                    <TextInput
                        type="number"
                        id="minVersion"
                        name="minVersion"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.minVersion}
                    />
                    {formik.touched.minVersion && formik.errors.minVersion ? (
                        <div className="text-red-500 text-sm">{formik.errors.minVersion}</div>
                    ) : null}
                </div>
                <div className="flex-1">
                    <Label htmlFor="maxVersion">Product Version (max)</Label>
                    <TextInput
                        type="number"
                        id="maxVersion"
                        name="maxVersion"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.maxVersion}
                    />
                    {formik.touched.maxVersion && formik.errors.maxVersion ? (
                        <div className="text-red-500 text-sm">{formik.errors.maxVersion}</div>
                    ) : null}
                </div>
            </div>

            <Button type="submit" color="blue" isProcessing={isSubmitting}>Save Preferences</Button>
        </form>
    )
}