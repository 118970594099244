import React from "react";
import GenericDeleteModal from "../Shared/GenericDeleteModal";
import { useQueryClient } from "@tanstack/react-query";
import { ReleaseNote } from "../../types/releaseNotes";

interface Props {
  releaseNoteID: string;
  releaseNote: ReleaseNote;
  prodID: string;
}

const DeleteReleaseNoteModal: React.FC<Props> = ({
  releaseNote,
  releaseNoteID,
  prodID,
}) => {
  const queryClient = useQueryClient();

  const apiUrl = `/releaseNote/${releaseNoteID}`;

  const handleDeleteSuccess = () => {
    queryClient.invalidateQueries(["releaseNotes", prodID]);
  };

  const handleDeleteError = (error: any) => {
    console.error("Error deleting the category:", error);
  };

  return (
    <GenericDeleteModal
      apiUrl={apiUrl}
      onDeleteSuccess={handleDeleteSuccess}
      onDeleteError={handleDeleteError}
      titleText={releaseNote.title}
    />
  );
};

export default DeleteReleaseNoteModal;
