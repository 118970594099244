import { useState, useEffect } from "react";

export const useFormStateLocalStorage = (key: string, initialState: any) => {
  const lsKey = `releaseNoteFormState-${key}`;
  const indexKey = `releaseNoteFormState-keys`; // Special key to keep track of all individual keys

  const [state, setState] = useState(() => {
    const savedState = localStorage.getItem(lsKey);
    return savedState ? JSON.parse(savedState) : initialState;
  });

  useEffect(() => {
    const savedState = localStorage.getItem(lsKey);
    if (savedState) {
      const existingKeys = JSON.parse(localStorage.getItem(indexKey) || "[]");
      if (!existingKeys.includes(lsKey)) {
        existingKeys.push(lsKey);
        localStorage.setItem(indexKey, JSON.stringify(existingKeys));
      }
    }
  }, [lsKey, indexKey]);

  useEffect(() => {
    localStorage.setItem(lsKey, JSON.stringify(state));
  }, [lsKey, state]);

  const clearState = () => {
    localStorage.removeItem(lsKey);
    setState(initialState);
    // Also remove this key from the index of keys
    const existingKeys = JSON.parse(localStorage.getItem(indexKey) || "[]");
    const newKeys = existingKeys.filter(
      (existingKey: string) => existingKey !== lsKey
    );
    localStorage.setItem(indexKey, JSON.stringify(newKeys));
  };

  return [state, setState, clearState];
};

export const useGetUnsavedNoteIds = (): string[] => {
  const indexKey = `releaseNoteFormState-keys`;
  const allKeys = JSON.parse(localStorage.getItem(indexKey) || "[]");
  return allKeys.map((key: string) => key.replace("releaseNoteFormState-", ""));
};
