import { Link } from "react-router-dom";

export default function LandingNavbar() {
  return (
    <header className="text-gray-600 body-font">
      <div className="container flex flex-col flex-wrap items-center p-5 mx-auto md:flex-row">
        <Link
          to={`/app/`}
          className="flex items-center mb-4 font-medium text-gray-900 title-font md:mb-0"
        >
          <h1 className="p-2 text-xl text-blue-900 bg-blue-100 rounded-full">
            RB
          </h1>
          <span className="ml-2 text-xl">ReleaseBlast</span>
        </Link>
        <nav className="flex flex-wrap items-center justify-center text-base md:ml-auto"></nav>
        <div className="flex space-x-2">
          <div className="space-x-2">
            <a
              href="/__catalyst/auth/login"
              className="inline-flex items-center px-3 py-1 mt-4 text-base bg-gray-100 border-0 rounded focus:outline-none hover:bg-gray-200 md:mt-0"
            >
              Sign In
            </a>
          </div>
          <div className="space-x-2">
            <a
              href="/__catalyst/auth/signup"
              className="inline-flex items-center px-3 py-1 mt-4 text-base bg-gray-100 border-0 rounded focus:outline-none hover:bg-gray-200 md:mt-0"
            >
              Sign Up
            </a>
          </div>
        </div>
      </div>
    </header>
  );
}
