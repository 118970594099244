import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Dropdown } from "flowbite-react";
import { Product, useProducts } from "../../pages/Products/hooks/useProducts";

export function ProductSelect({ orgID }: { orgID: string }) {
  const navigate = useNavigate();
  const [selectedProductName, setSelectedProductName] = useState("");

  const { data: products, isLoading: loading } = useProducts(orgID);

  useEffect(() => {
    if (!loading && products) {
      const storedProdID = localStorage.getItem("prodID");
      if (storedProdID) {
        const selectedProd = products.find(
          (prod) => prod.ROWID === storedProdID
        );
        setSelectedProductName(selectedProd ? selectedProd.name : "");
      } else {
        setSelectedProductName(products[0].ROWID);
      }
    }
  }, [loading, products]);

  const handleSelect = (product: Product) => {
    localStorage.setItem("prodID", product.ROWID);
    const orgID = localStorage.getItem("orgID");
    setSelectedProductName(product.name);
    navigate(`/app/${orgID}/notes/${product.ROWID}`);
  };

  return (
    <div className="w-max">
      <Dropdown
        label={loading ? "Loading .." : selectedProductName || "Change Product"}
        inline
      >
        {products?.map((product) => (
          <Dropdown.Item
            key={product.ROWID}
            onClick={() => handleSelect(product)}
          >
            {product.name}
          </Dropdown.Item>
        ))}
      </Dropdown>
    </div>
  );
}
