import {useMutation} from "@tanstack/react-query";
import {OrganizationInfo} from "../../types/organization";
import API from "../../api";

async function addOrganization(organization: OrganizationInfo) {
    try {
        const response = await API.post(
            `organizations`,
            organization,
            {
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );
        return response.data.payload;
    } catch (error) {
        console.log(error);
        throw error;
    }
}

export function useAddOrganization(
    onSuccess: (data: any) => void,
    onError: (error: any) => void
) {
    return useMutation(addOrganization, {
        onError: onError,
        onSuccess: onSuccess,
    });
}
