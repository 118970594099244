import React, {useEffect, useState} from "react";
import MarkdownEditor from "../../components/Note/Add/markdowneditor";
import {PriorityComponent} from "./Add/priority";
import {VersionComponent} from "./Add/version";
import {VisibilityComponent} from "./Add/visiblity";
import {TagComponent} from "./Add/tags";
import LanguageSelect from "../../components/Note/Add/lanugage";
import {Modal} from "flowbite-react";
import {toast} from "react-toastify";
import {LoaderIcon} from "react-hot-toast";
import ReleaseNoteEditorMenuButton from "./ReleaseNoteEditorMenuButton";
import {HiOutlineDocumentRemove, HiOutlineDocumentText, HiOutlinePaperAirplane} from "react-icons/hi";
import {useFormStateLocalStorage} from "../../hooks/releasenote/useFormStateLocalStorage";
import {FiAlertTriangle} from "react-icons/fi";
import WriteWithRAIModal from "./Add/AI/WriteWithRAIModal";
import {HiMiniPaperAirplane} from "react-icons/hi2";

type ReleaseNoteVisibility = "public" | "draft";

export type ReleaseNoteData = {
    title: string;
    content: string;
    visibility: ReleaseNoteVisibility;
    version: string;
    priority: string;
    tags: string[];
    language: string | null;
    BlockNoteEditorContent: string | null;
};

type ReleaseNoteFormProps = {
    localStorageKey: string;
    defaultTitle?: string;
    defaultContent?: string;
    defaultVisibility?: ReleaseNoteVisibility;
    defaultVersion?: string;
    defaultPriority?: string;
    defaultTags?: string[];
    defaultLanguage?: string | null;

    onSave: (releaseNote: ReleaseNoteData) => Promise<void>;
};

function countWords(word: string) {
    const words = word.match(/\w+/g);
    return words ? words.length : 0;
}

const ReleaseNoteForm: React.FC<ReleaseNoteFormProps> = ({
                                                             localStorageKey,
                                                             defaultTitle = "",
                                                             defaultContent = "",
                                                             defaultVisibility = "draft",
                                                             defaultVersion = "1.0",
                                                             defaultPriority = "1",
                                                             defaultTags = [],
                                                             defaultLanguage = "en",
                                                             onSave,
                                                         }) => {
    const [title, setTitle] = useState(defaultTitle);
    const [content, setContent] = useState("");
    const [visibility, setVisibility] =
        useState<ReleaseNoteVisibility>(defaultVisibility);
    const [version, setVersion] = useState(defaultVersion);
    const [priority, setPriority] = useState(defaultPriority);
    const [tags, setTags] = useState(defaultTags);
    const [selectedLanguage, setSelectedLanguage] = useState(defaultLanguage);
    const [loading, setLoading] = useState(false);
    const [openModal, setOpenModal] = useState<String | undefined>();
    const [blockNoteEditorContent, setBlockNoteEditorContent] =
        useState(defaultContent);

    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

    const [formState, setFormState, clearFormState] = useFormStateLocalStorage(
        localStorageKey,
        defaultContent
    );

    useEffect(() => {
        if (formState !== defaultContent) {
            setHasUnsavedChanges(true);
        } else {
            setHasUnsavedChanges(false);
        }
    }, [formState, defaultContent]);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        if (!title) {
            toast.error("Release note is missing a heading!");
            return;
        }

        if (!content) {
            toast.error("Release note does not have any contents!");
            return;
        }

        setLoading(true);

        try {
            await onSave({
                title,
                content,
                visibility,
                version,
                priority,
                tags,
                language: selectedLanguage,
                BlockNoteEditorContent: blockNoteEditorContent,
            });
            clearFormState();
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <Modal
                show={openModal === "form-elements"}
                size="2xl"
                popup
                onClose={() => setOpenModal(undefined)}
            >
                <Modal.Header/>
                <Modal.Body>
                    <div className="">
                        <LanguageSelect
                            className=""
                            selectedLanguage={selectedLanguage}
                            setSelectedLanguage={setSelectedLanguage}
                        />
                        <PriorityComponent value={priority} onChange={setPriority}/>
                        <TagComponent tags={tags} onTagsChange={setTags}/>
                        <VersionComponent value={version} onChange={setVersion}/>
                        {defaultVisibility !== "public" && (
                            <VisibilityComponent
                                value={visibility}
                                onChange={setVisibility}
                            />
                        )}
                        <button
                            disabled={loading}
                            onClick={handleSubmit}
                            className={
                                loading
                                    ? "rounded-lg px-4 py-2 bg-gray-700 text-white"
                                    : "rounded-lg px-4 py-2 bg-blue-700 text-white"
                            }
                        >
                            {loading ? (
                                <LoaderIcon className="mx-3 my-1 w-4 h-4"/>
                            ) : visibility === "draft" ? (
                                <span>Save Draft</span>
                            ) : (
                                <span>Publish</span>
                            )}
                        </button>
                    </div>
                </Modal.Body>
            </Modal>

            <form
                className="mx-1 py-6 pl-16 bg-white h-full rounded-xl flex flex-col"
                onSubmit={handleSubmit}
            >
                <div className="pl-[1.4rem] mr-8 flex space-x-1">
                    <div className="flex-1"></div>
                    <div className="flex space-x-1">
                        <button
                            disabled={loading}
                            onClick={(e) => {
                                e.preventDefault();
                                if (!title) {
                                    toast.error("Release note is missing a heading!");
                                    return;
                                } else if (!content) {
                                    toast.error("Release note is missing the content!");
                                    return;
                                } else {
                                    setOpenModal("form-elements");
                                }
                            }}
                            className={
                                "bg-gray-100 text-gray-800 px-3 py-2 flex space-x-1 rounded-lg items-center justify-center" +
                                (!title || !content ? " bg-gray-50 text-gray-600" : "")
                            }
                        >
                            {loading ? (
                                <LoaderIcon className="w-4 h-4"/>
                            ) : !title || !content ? (
                                <HiOutlineDocumentRemove className="w-4 h-4"/>
                            ) : (
                                <HiOutlineDocumentText className="w-4 h-4"/>
                            )}
                            <span className="text-sm text-gray-500">{
                                visibility === "draft" ? "Save" : "Publish"
                            }</span>
                        </button>
                        <div
                            className="bg-gray-100 text-gray-800 px-3 py-2 rounded-lg flex items-center justify-center">
                            <ReleaseNoteEditorMenuButton markdownContents={`# ${title}\n` + content}/>
                        </div>
                    </div>
                </div>
                <div className="mx-auto flex-1 flex min-w-full h-full">
                    <div className="w-full pl-4 flex-1">
                        <MarkdownEditor
                            defaultTitle={defaultTitle}
                            setTitle={setTitle}
                            setMarkdownContent={setContent}
                            blockNoteEditorContent={formState}
                            setBlockNoteEditorContent={(value: string) => {
                                setFormState(value);
                                setBlockNoteEditorContent(value);
                            }}
                        />
                    </div>
                </div>
                <div className="flex items-center space-x-1">
                    <div className="bg-gray-100 text-sm px-2 py-1 rounded-md">
                        Words: {countWords(content)}
                    </div>
                    <div className="bg-gray-100 text-sm px-2 py-1 rounded-md">
                        Chars: {content.replaceAll("\n", "").length}
                    </div>

                    {hasUnsavedChanges ? (
                        <div className="bg-gray-100 text-sm px-2 py-1 rounded-md">
                            {visibility === "draft" ? "Draft: Saved Locally" : "Unpublished Saved Changes"}
                        </div>
                    ) : (
                        <div className="bg-gray-100 text-sm px-2 py-1 rounded-md">
                            {visibility === "draft" ? "Draft: Saved to cloud" : "Saved and Published"}
                        </div>
                    )}
                    {(title.length === 0 || content.length === 0) && (
                        <div
                            className="bg-gray-100 flex space-x-2 justify-center align-middle items-center text-red-600 text-sm px-2 py-1 rounded-md">
                            <FiAlertTriangle/>
                            <span>
                Empty{" "}
                                {title.length === 0 && content.length === 0
                                    ? "Title and Content"
                                    : title.length === 0
                                        ? "Title"
                                        : "Content"}
              </span>
                        </div>
                    )}
                </div>
            </form>
        </>
    );
};

export default ReleaseNoteForm;
