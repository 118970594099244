import { Table } from "flowbite-react";
import { IntegrationEmbedTheme } from "../../../../types/embedThemes";
import DeleteIntegrationEmbedThemeModalComponent from "./DeleteIntegrationEmbedThemeModalComponent";

export default function EmbedsThemeListTableComponent({
  embedThemes,
  productId,
}: {
  productId: string;
  embedThemes: IntegrationEmbedTheme[];
}) {
  return (
    <>
      <Table>
        <Table.Head>
          <Table.HeadCell className="bg-gray-200">ID</Table.HeadCell>
          <Table.HeadCell className="bg-gray-200">Name</Table.HeadCell>
          <Table.HeadCell className="bg-gray-200">Created At</Table.HeadCell>
          <Table.HeadCell className="bg-gray-200">Accent Color</Table.HeadCell>
          <Table.HeadCell className="bg-gray-200">Theme Color</Table.HeadCell>
          <Table.HeadCell className="bg-gray-200">Text Color</Table.HeadCell>
          <Table.HeadCell className="bg-gray-200">
            Accent Text Color
          </Table.HeadCell>
          <Table.HeadCell className="bg-gray-200">Actions</Table.HeadCell>
        </Table.Head>
        <Table.Body className="divide-y">
          {embedThemes.map((theme) => (
            <Table.Row
              key={theme.id}
              className="bg-gray-50 dark:border-gray-700 dark:bg-gray-800"
            >
              <Table.Cell>{theme.id}</Table.Cell>
              <Table.Cell>{theme.name}</Table.Cell>
              <Table.Cell>{theme.createdAt}</Table.Cell>
              <Table.Cell>
                <div className="flex items-center">
                  <span
                    className="block w-6 h-6 rounded-full border"
                    style={{ backgroundColor: theme.accentColor }}
                  ></span>
                  <span className="ml-2">{theme.accentColor}</span>
                </div>
              </Table.Cell>

              <Table.Cell>
                <div className="flex items-center">
                  <span
                    className="block w-6 h-6 rounded-full border"
                    style={{ backgroundColor: theme.themeColor }}
                  ></span>
                  <span className="ml-2">{theme.themeColor}</span>
                </div>
              </Table.Cell>

              <Table.Cell>
                <div className="flex items-center">
                  <span
                    className="block w-6 h-6 rounded-full border"
                    style={{ backgroundColor: theme.textColor }}
                  ></span>
                  <span className="ml-2">{theme.textColor}</span>
                </div>
              </Table.Cell>
              <Table.Cell>
                <div className="flex items-center">
                  <span
                    className="block w-6 h-6 rounded-full border"
                    style={{ backgroundColor: theme.accentTextColor }}
                  ></span>
                  <span className="ml-2">{theme.accentTextColor}</span>
                </div>
              </Table.Cell>
              <Table.Cell className="flex space-x-1">
                <DeleteIntegrationEmbedThemeModalComponent
                  embedThemeName={theme.name}
                  embedThemeID={theme.id}
                  productID={productId}
                />
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </>
  );
}
