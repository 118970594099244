import React, { useState } from "react";
import { Button, Modal } from "flowbite-react";
import { HiOutlineExclamationCircle, HiTrash } from "react-icons/hi";
import { useMutation } from "@tanstack/react-query";
import API from "../../api";

// Add a default DeleteButton component
const DeleteButton = () => (
  <div className="relative group">
    <HiTrash className="h-4 w-4 text-gray-500 hover:text-gray-900 mx-1" />
    <span className="absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-6 w-auto px-2 py-1 bg-black text-white text-xs rounded hidden group-hover:block">
      Delete
    </span>
  </div>
);

interface GenericDeleteModalProps {
  apiUrl: string;
  onDeleteSuccess: () => void;
  onDeleteError?: (error: any) => void;
  titleText: string;
  buttonElement?: React.ReactElement;
  titlePreText?: string;
  deleteButtonText?: string;
}

const GenericDeleteModal: React.FC<GenericDeleteModalProps> = ({
  apiUrl,
  onDeleteSuccess,
  onDeleteError,
  titleText,
  titlePreText = "Are you sure you want to delete",
  deleteButtonText = "Delete",
  buttonElement = <DeleteButton />,
}) => {
  const [showModal, setShowModal] = useState(false);

  const deleteMutation = useMutation(() => API.delete(apiUrl), {
    onSuccess: () => {
      onDeleteSuccess();
      setShowModal(false);
    },
    onError: (error: any) => {
      setShowModal(false);
      if (onDeleteError) {
        onDeleteError(error);
      }
    },
  });

  const handleDelete = () => deleteMutation.mutate();

  return (
    <>
      <button
        onClick={() => setShowModal(true)}
        className="text-gray-500 hover:text-gray-900 "
      >
        {buttonElement}
      </button>
      <Modal
        show={showModal}
        size="md"
        onClose={() => setShowModal(false)}
        popup
      >
        <Modal.Header />
        <Modal.Body>
          <div className="text-center">
            <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
              {titlePreText} <br /> <b>{titleText}</b>
            </h3>
            <div className="flex justify-center gap-4">
              <Button
                isProcessing={deleteMutation.isLoading}
                color="failure"
                onClick={handleDelete}
              >
                {deleteButtonText}
              </Button>
              <Button color="gray" onClick={() => setShowModal(false)}>
                No, cancel
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default GenericDeleteModal;
