import API from "../../api";
import {useQuery} from "@tanstack/react-query";
import UserInfo from "../../types/user";

async function getUserProfileInfo() {
    const response = await API.get("/users/me")
    return response.data.payload as UserInfo;
}

const useGetUserProfile = () => {
    return useQuery(["userProfile"], getUserProfileInfo, {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
        refetchInterval: false,
        refetchIntervalInBackground: false,
        cacheTime: Infinity
    });
}

export default useGetUserProfile;