import { Button, Modal } from "flowbite-react";
import React, { useState } from "react";

export function SendReleaseNoteGenericModalComponent({
  onClick,
  buttonHoverLabel,
  buttonIcon,
  modalBody: modal,
  modalHeaderText,
  disableFooter = false,
  footerContentOverride,
}: {
  onClick?: (setModalOpen: (arg0: boolean) => void) => void;
  buttonHoverLabel: string;
  buttonIcon: React.ReactNode;
  modalBody: React.ReactNode;
  modalHeaderText: string;
  disableFooter?: boolean;
  footerContentOverride?: React.ReactNode;
}) {
  const [openModal, setOpenModal] = useState<boolean>(false);

  return (
    <>
      <button
        className=""
        onClick={() => {
          if (onClick) {
            onClick(setOpenModal);
          } else {
            setOpenModal(true);
          }
        }}
      >
        <div className="relative group">
          {buttonIcon}
          <span className="absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-6 w-auto px-2 py-1 bg-black text-white text-xs rounded hidden group-hover:block">
            {buttonHoverLabel}
          </span>
        </div>
      </button>

      <Modal
        show={openModal}
        onClose={() => setOpenModal(false)}
        size={"4xl"}
        dismissible
      >
        <Modal.Header>{modalHeaderText}</Modal.Header>
        <Modal.Body>{modal}</Modal.Body>
        {!disableFooter && (
          <Modal.Footer>
            {footerContentOverride ? (
              footerContentOverride
            ) : (
              <Button color="failure" onClick={() => setOpenModal(false)}>
                Cancel
              </Button>
            )}
          </Modal.Footer>
        )}
      </Modal>
    </>
  );
}
