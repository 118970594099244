import React, { useState } from "react";
import { CircularProgressIndicator } from "./Button";

interface AvatarUploadComponentProps {
  onImageUpload: (fileID: string) => void;
  emptyImageLabel: string;
  defaultImage?: string;
}

export const AvatarUploadComponent: React.FC<AvatarUploadComponentProps> = ({
  onImageUpload,
  emptyImageLabel = "Click to upload image",
  defaultImage,
}) => {
  const [uploading, setUploading] = useState(false);
  const [avatarUrl, setAvatarUrl] = useState<string | null>(
    defaultImage ?? null
  );

  const handleImageChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files ? event.target.files[0] : null;
    if (file) {
      setUploading(true);
      try {
        const formData = new FormData();
        formData.append("data", file);

        const response = await fetch(
          "/server/blast_function/images/cf-upload",
          {
            method: "POST",
            body: formData,
          }
        );

        if (response.ok) {
          const data = await response.json();
          setAvatarUrl(data.url);
          onImageUpload(data.fileID);
        } else {
          console.error("Upload failed");
        }
      } catch (error) {
        console.error("Error uploading file:", error);
      }
      setUploading(false);
    }
  };

  return (
    <div className="relative">
      <input
        type="file"
        accept="image/*"
        onChange={handleImageChange}
        className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
      />
      <div
        className="w-24 h-24 rounded-full bg-gray-200 flex items-center justify-center text-gray-400 hover:text-gray-600"
        title="Click to upload image"
      >
        {uploading ? (
          <CircularProgressIndicator />
        ) : (
          !avatarUrl && (
            <span className="material-iconsb p-3 text-center align-middle">
              {emptyImageLabel}
            </span>
          )
        )}
        {avatarUrl && !uploading && (
          <img
            src={avatarUrl}
            alt="Avatar"
            className="w-full h-full rounded-full object-cover"
          />
        )}
      </div>
    </div>
  );
};
