import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import {
  useReleaseNoteById,
  useUpdateReleaseNote,
} from "../hooks/useReleaseNotes";
import ReleaseNoteForm, {
  ReleaseNoteData,
} from "../../../components/Note/ReleaseNoteForm";
import { toast } from "react-toastify";
import GenericSkeletonLoader from "../../../components/Shared/GenericSkeletonLoadingPage";

const EditReleaseNotePage: React.FC = () => {
  const { releaseNoteID, prodId, orgId } = useParams<{
    releaseNoteID: string;
    prodId: string;
    orgId: string;
  }>();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { mutateAsync: updateReleaseNote } = useUpdateReleaseNote({
    releaseNoteID: releaseNoteID ?? "",
  });
  const {
    data: releaseNote,
    isLoading,
    isError,
  } = useReleaseNoteById(releaseNoteID ?? "");

  if (isLoading || !prodId) {
    return <GenericSkeletonLoader />;
  }

  if (isError || !releaseNote) {
    return <div>Error loading release note or release note not found.</div>;
  }

  const handleSave = async (releaseNoteData: ReleaseNoteData) => {
    try {
      await updateReleaseNote({
        releaseNoteID: releaseNoteID ?? "",
        releaseNote: {
          version: parseFloat(releaseNoteData.version),
          priority: parseInt(releaseNoteData.priority),
          isPublic: releaseNoteData.visibility === "public",
          tag: releaseNoteData.tags,
          title: releaseNoteData.title,
          content: releaseNoteData.content,
          language: releaseNoteData.language ?? "en",
          BlockNoteEditorContent: releaseNoteData.BlockNoteEditorContent ?? "",
        },
      });
      queryClient.invalidateQueries(["releasenotes", prodId]);

      toast.success(
        `Release note has been ${
          releaseNoteData.visibility === "public"
            ? "published"
            : "saved to draft"
        } successfully`
      );

      navigate(`/app/${orgId}/notes/${prodId}`);
    } catch (error) {
      toast.error("An error occurred while saving the release note.");

      console.error("Error updating release note:", error);
    }
  };

  return (
    <ReleaseNoteForm
      localStorageKey={`${releaseNoteID}`}
      onSave={handleSave}
      defaultTitle={releaseNote.Title}
      defaultContent={releaseNote.BlockNoteEditorContent}
      defaultVisibility={releaseNote.IsPublic ? "public" : "draft"}
      defaultVersion={releaseNote.Version?.toString()}
      defaultPriority={releaseNote.ReleasePriority?.toString()}
      defaultLanguage={releaseNote?.Language}
      defaultTags={releaseNote.tag}
    />
  );
};

export default EditReleaseNotePage;
