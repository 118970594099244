import EmbedsListTableComponent from "../../../components/Integration/Embed/EmbedsListTableComponent";
import TableSkeletonLoader from "../../../components/Shared/TableSkeletonLoader";
import { useListIntegrationEmbedConfigsForProduct } from "../../../hooks/integrations/embeds/useListIntegrationEmbedConfigsForProduct";
import { useNavigate, useParams } from "react-router-dom";
import AddIntegrationEmbedModalComponent from "../../../components/Integration/Embed/AddIntegrationEmbedModalComponent";
import { Button } from "flowbite-react";
import { HiCog } from "react-icons/hi";

export default function IntegrationEmbedListView() {
  const { orgId, prodId } = useParams();

  const navigate = useNavigate();

  const {
    data: embedConfigs,
    isLoading: loading,
    isError,
  } = useListIntegrationEmbedConfigsForProduct(prodId ?? "");

  if (isError) {
    return <div>"Error occoured"</div>;
  }

  return (
    <div className="mx-1 my-2 bg-white p-6 rounded-lg h-full">
      <div className="flex">
        <div className="flex-1">
          <h1 className="text-gray-800 text-2xl font-semibold mb-4">Embeds</h1>
        </div>
        <div>
          <Button
            onClick={() => {
              navigate(`/app/${orgId}/${prodId}/integration/embeds/theme`);
            }}
            color="blue"
          >
            <div className="flex space-x-1 align-center justify-center">
              <HiCog className="h-5 w-5" />
              <span>Configure Themes</span>
            </div>
          </Button>
        </div>
        <div className="ml-2">
          <AddIntegrationEmbedModalComponent productID={prodId ?? ""} />
        </div>
      </div>
      <div className="mt-2">
        {loading ? (
          <TableSkeletonLoader />
        ) : (
          <div>
            <EmbedsListTableComponent embeds={embedConfigs} />
          </div>
        )}
      </div>
    </div>
  );
}
