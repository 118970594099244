import { toast } from "react-toastify";
import GenericDeleteModal from "../Shared/GenericDeleteModal";
import { useQueryClient } from "@tanstack/react-query";

export default function OrganizationProductDeleteModal({
  productName,
  productID,
  organizationID,
}: {
  organizationID: string;
  productID: string;
  productName: string;
}) {
  const queryClient = useQueryClient();

  return (
    <GenericDeleteModal
      apiUrl={`/products/${productID}`}
      onDeleteSuccess={function (): void {
        toast.success(`Product ${productName} deleted`);
        queryClient.invalidateQueries([organizationID, "products"]);
      }}
      onDeleteError={() => {
        toast.error(`Error deleting product ${productName}`);
      }}
      titleText={productName}
    />
  );
}
