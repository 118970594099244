import { PlusIcon } from "@heroicons/react/20/solid";
import { Link } from "react-router-dom";

export default function LandingHomeHero() {
  return (
    <section>
      <div className="container grid px-4 pt-10 pb-8 mx-auto lg:gap-8 xl:gap-0 lg:py-10 lg:grid-cols-12 lg:pt-10">
        <div className="mr-auto place-self-center lg:col-span-7">
          <h1 className="max-w-2xl mb-4 text-4xl font-extrabold leading-none tracking-tight md:text-5xl xl:text-6xl">
            Simplify Your Releases with ReleaseBlast
          </h1>
          <p className="max-w-2xl mb-6 font-light text-gray-500 lg:mb-8 md:text-lg lg:text-xl">
            Streamline your communication with customers. Write, embed, and
            manage release notes effortlessly. Let us handle the user
            management, emails, and feedback collection. Dive into a seamless
            experience with{" "}
            <a
              href="https://releaseblast.com"
              className="font-bold hover:underline"
            >
              ReleaseBlast
            </a>
            .
          </p>
          <div className="space-y-4 sm:flex sm:space-y-0 sm:space-x-4">
            <Link
              to="/app/new"
              className="inline-flex px-3 py-2 text-lg text-white bg-indigo-500 border-0 rounded focus:outline-none hover:bg-indigo-600"
            >
              <PlusIcon className="w-6 h-6 my-auto mr-2" />
              Start Now
            </Link>
          </div>
        </div>
        <div className="hidden lg:mt-0 lg:col-span-5 lg:flex">
          <img
            src="https://raw.githubusercontent.com/themesberg/landwind/main/images/hero.png"
            alt="hero image"
          />
        </div>
      </div>
    </section>
  );
}
