import { DashboardPayload } from '../../types/dashboard';
import { useQuery } from '@tanstack/react-query';
import API from '../../api';

type UseGetDashboardDataProps = {
  productId: string;
};


async function getDashboardData<DashboardPayload>(productId: string){
    const response = await API.get(`/product/${productId}/dashboard`);
    return response.data.payload as DashboardPayload;
}




export const useGetDashboardData = ({ productId }: UseGetDashboardDataProps) => {
  return useQuery(['dashboard', productId],
  () => getDashboardData(productId), {
    staleTime: Infinity,
  });
};