import React, { useEffect, useState } from "react";

import SignUp from "./SignUp";
import { useTheme } from "../../hooks/useTheme";

const LoginForm: React.FC = () => {
  const [openSignUp, setOpenSignUp] = useState(false);
  const { theme } = useTheme();
  useEffect(() => {
    //@ts-ignore
    document.getElementById("loginDivElementId").style =
      theme === "dark"
        ? "background: #4a5568;display: block; text-color: #fff"
        : "display: block;";
    //@ts-ignore
    document.getElementById("loginDivElementId").style.background =
      theme === "dark" ? "#4a5568" : "inherit";
    // document.getElementById("loginDivElementId").style.flexDirection = "column";
    //@ts-ignore
    window.catalyst.auth.signIn("loginDivElementId", config);
  }, [theme]);

  return (
    <div className="h-full w-full">
      {!openSignUp && (
        <div className="text-center">
          <p>
            Don't have an account?&nbsp;
            <span
              className="hover:text-green-600 hover:underline font-bold text-purple-800"
              onClick={() => {
                //@ts-ignore
                document.getElementById("loginDivElementId").style.display =
                  "none";
                setOpenSignUp(true);
              }}
            >
              Sign Up!
            </span>
            &nbsp; now
          </p>
        </div>
      )}
      <div className="row-start-2 row-span-2">{openSignUp && <SignUp />}</div>
    </div>
  );
};

function LoginContainer() {
  return (
    <div className="flex flex-col w-full bg-purple">
      <LoginForm />
    </div>
  );
}
export default LoginContainer;
