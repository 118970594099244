import API from "../../api";
import {useMutation, useQueryClient} from "@tanstack/react-query";

async function updateUserProfileName(
    {firstName, lastName}: { firstName: string, lastName: string }
) {
    const response = await API.patch("/users/me", {firstName, lastName});
    return response.data;
}

const useUpdateUserProfileName = (
    {
        onSuccess,
        onError
    }: {
        onSuccess: () => void,
        onError: () => void
    }
) => {

    const queryClient = useQueryClient();

    return useMutation(updateUserProfileName, {
        onSuccess: () => {
            queryClient.invalidateQueries(["userProfile"]);
            onSuccess()
        },
        onError: onError
    });
}

export default useUpdateUserProfileName;