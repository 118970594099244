import React, { useState } from "react";

const SignUp: React.FC = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");

  const signupAction = () => {
    //Create a JSON to use the details for the user sign-up
    const data = {
      first_name: firstName,
      last_name: lastName,
      email_id: email,
      platform_type: "web",
    };
    //@ts-ignore
    var auth = window.catalyst.auth;

    //The signup method will sign up the user with the specified data
    var signupPromise = auth.signUp(data);
    signupPromise
      .then((response: string) => {
        //If the signup is successful, the user will be asked to check their email
        document.body.innerHTML =
          "An account verification email has been sent to your email address";

        setTimeout(function () {
          //@ts-ignore
          catalyst.auth.signIn("login");
        }, 5000);
      })
      .catch((err: string) => {
        //If the signup is not successful, this alert will be displayed
        alert("Try again after some time");
        // location.reload();
      });
  };

  return (
    <>
      <div id="signup" className="flex items-center justify-center mt-[50px]">
        <form className="bg-white shadow-md rounded p-2 m-1 w-1/4">
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="firstName"
            >
              First Name
            </label>
            <input
              className=" shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="firstName"
              type="text"
              placeholder="John"
              value={firstName}
              onChange={(event) => setFirstName(event.target.value)}
            />
          </div>
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="lastName"
            >
              Last Name
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              type="text"
              placeholder="Doe"
              value={lastName}
              onChange={(event) => setLastName(event.target.value)}
            />
          </div>
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="email"
            >
              E-mail
            </label>
            <input
              className=" shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="email"
              type="text"
              placeholder="example@example.com"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
            />
          </div>

          <button
            className="flex items-center justify-center text-center font-bold text-sm text-blue-500 hover:text-blue-800 ml-[145px]"
            type="submit"
            onClick={(event) => {
              event.preventDefault();
              signupAction();
            }}
          >
            Sign Up
          </button>
        </form>
      </div>
    </>
  );
};
export default SignUp;
