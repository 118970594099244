import { toast } from "react-toastify";
import { useMutation } from "@tanstack/react-query";
import API from "../../../api";

const useUnsubscriberSubscriber = () => {
  const unsubscribeMutation = useMutation(
    async (subscriberId: string) => {
      const response = await API.post("/public/subscriber/unsubscribe", {
        subscriberId,
      });
      return response.data;
    },
    {
      onSuccess: () => {
        toast.success("Successfully unsubscribed!");
      },
      onError: (error: any) => {
        toast.error(`Error: ${error.message || "Failed to unsubscribe"}`);
      },
    }
  );

  return unsubscribeMutation;
};

export default useUnsubscriberSubscriber;
