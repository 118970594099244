import {
    BlockNoteView,
    BlockTypeDropdown,
    CreateLinkButton,
    DefaultSideMenu,
    DragHandleMenu,
    FormattingToolbarPositioner,
    FormattingToolbarProps,
    HyperlinkToolbarPositioner,
    ImageCaptionButton,
    ImageToolbarPositioner,
    RemoveBlockButton,
    ReplaceImageButton,
    SideMenuPositioner,
    SlashMenuPositioner,
    ToggledStyleButton,
    Toolbar,
    useBlockNote,
} from "@blocknote/react";
import {defaultBlockSpecs} from "@blocknote/core";

import "@blocknote/core/style.css";
import {toast} from "react-toastify";
import useEditorStore from "../../../state/useEditorStore";
import {useEffect} from "react";

function CustomFormattingToolbar(props: FormattingToolbarProps) {
    return (
        <Toolbar>
            <BlockTypeDropdown {...props} />
            <ImageCaptionButton editor={props.editor}/>
            <ReplaceImageButton editor={props.editor}/>

            <ToggledStyleButton editor={props.editor} toggledStyle={"bold"}/>
            <ToggledStyleButton editor={props.editor} toggledStyle={"italic"}/>
            <ToggledStyleButton editor={props.editor} toggledStyle={"underline"}/>
            <ToggledStyleButton editor={props.editor} toggledStyle={"strike"}/>
            <ToggledStyleButton editor={props.editor} toggledStyle={"code"}/>

            <CreateLinkButton editor={props.editor}/>
        </Toolbar>
    );
}

const CustomDragHandleMenu = (props: { editor: any; block: any }) => {
    return (
        <DragHandleMenu>
            <RemoveBlockButton {...props}>Delete</RemoveBlockButton>
        </DragHandleMenu>
    );
};

interface MarkdownEditorProps {
    defaultTitle: string;
    setTitle: (value: string) => void;
    blockNoteEditorContent: string;
    setMarkdownContent: (value: string) => void;
    setBlockNoteEditorContent: (value: string) => void;
}

export default function MarkdownEditor(
    {
        defaultTitle,
        setTitle,
        setMarkdownContent,
        blockNoteEditorContent,
        setBlockNoteEditorContent,
    }: MarkdownEditorProps) {
    const editor = useBlockNote({
        blockSpecs: {
            paragraph: defaultBlockSpecs.paragraph,
            heading: defaultBlockSpecs.heading,
            numberedListItem: defaultBlockSpecs.numberedListItem,
            bulletListItem: defaultBlockSpecs.bulletListItem,
            image: defaultBlockSpecs.image,
        },
        uploadFile: async (file) => {
            const formData = new FormData();
            formData.append("data", file);

            let imageUrl = "";

            await fetch("/server/blast_function/images/cf-upload", {
                method: "POST",
                body: formData,
            })
                .then((response) => response.json())
                .then((data) => {
                    if (data.url) {
                        toast.success("Image Upload Successful");
                        imageUrl = data.url;
                    } else {
                        toast.error("Error: No URL returned from server.");
                    }
                })
                .catch((error) => {
                    toast.error("Error uploading image: " + error.toString());
                });

            return imageUrl;
        },
        onEditorContentChange: async (editor) => {
            if (
                !(
                    editor.topLevelBlocks[0].type === "heading" &&
                    (editor.topLevelBlocks[0] as any).props.level === 1
                )
            ) {
                editor.insertBlocks(
                    [
                        {
                            type: "heading",
                            props: {
                                level: 1,
                            },
                        },
                    ],
                    editor.topLevelBlocks[0]
                );
            }

            const saveBlocksAsMarkdown = async () => {
                const topLevelBlocks = editor.topLevelBlocks;
                const totalBlocksLength = topLevelBlocks.length;

                const concernedBlocks = topLevelBlocks.slice(1, totalBlocksLength);

                const markdown: string =
                    await editor.blocksToMarkdownLossy(concernedBlocks);
                console.log(markdown);

                const html = await editor.blocksToHTMLLossy(concernedBlocks);
                console.log(html)

                console.log(concernedBlocks)

                setMarkdownContent(markdown);
            };

            saveBlocksAsMarkdown();
            setBlockNoteEditorContent(JSON.stringify(editor.topLevelBlocks));

            if (editor.topLevelBlocks[0]?.content) {
                const editorTitle = (editor.topLevelBlocks[0].content as any)[0]?.text;

                setTitle(editorTitle ?? "");
            }
        },
        onEditorReady(editor) {
            // Save the content as markdown locally
            const saveBlocksAsMarkdown = async () => {
                const markdown: string = await editor.blocksToMarkdownLossy(
                    JSON.parse(blockNoteEditorContent)
                );
                setMarkdownContent(markdown);
            };

            // Load the content from the database
            if (blockNoteEditorContent && blockNoteEditorContent !== "") {
                const allBlocks = editor.topLevelBlocks;
                editor.insertBlocks(JSON.parse(blockNoteEditorContent), allBlocks[0]);
            }

            if (blockNoteEditorContent !== "") {
                saveBlocksAsMarkdown();
            }

            if (
                !(
                    editor.topLevelBlocks[0].type === "heading" &&
                    (editor.topLevelBlocks[0] as any).props.level === 1
                )
            ) {
                editor.insertBlocks(
                    [
                        {
                            type: "heading",
                            props: {
                                level: 1,
                            },
                        },
                    ],
                    editor.topLevelBlocks[0]
                );
            }

            if (editor.topLevelBlocks[0]?.content) {
                const editorTitle = (editor.topLevelBlocks[0].content as any)[0]?.text;

                setTitle(editorTitle ?? "");
            }
        },
    });
    const appendBlocks = async (content: string) => {
        const blocks = await editor.tryParseMarkdownToBlocks(content);
        console.log(blocks)
        const allBlocks = editor.topLevelBlocks;

        const insertPosition = allBlocks.length > 0 ? allBlocks.length - 1 : 0;
        editor.insertBlocks(blocks, allBlocks[insertPosition]);
    };

    const replaceContentAtBeginning = async (content: string) => {
        const blocks = await editor.tryParseMarkdownToBlocks(content);
        const allBlocks = editor.topLevelBlocks;
        editor.removeBlocks(allBlocks.slice(
            1,
            allBlocks.length - 1
        ));

        editor.insertBlocks(blocks, allBlocks[0]);
        editor.removeBlocks([allBlocks[0]])
    };


    const {contentAction, clearContentAction} = useEditorStore();

    useEffect(() => {
        console.log("Running")
        const performContentAction = async () => {
            if (!contentAction) return;

            switch (contentAction.type) {
                case 'append':
                    await appendBlocks(contentAction.content);
                    break;
                case 'replace':
                    await replaceContentAtBeginning(contentAction.content);
                    break;
                default:
                    break;
            }
            clearContentAction();

        };

        performContentAction();

    }, [contentAction]);

    return (
        <>
            <BlockNoteView editor={editor} theme={"light"}>
                <FormattingToolbarPositioner
                    editor={editor as any}
                    formattingToolbar={CustomFormattingToolbar}
                />
                <HyperlinkToolbarPositioner editor={editor}/>
                <SlashMenuPositioner editor={editor}/>
                <SideMenuPositioner
                    editor={editor}
                    sideMenu={(props) => (
                        <DefaultSideMenu {...props} dragHandleMenu={CustomDragHandleMenu}/>
                    )}
                />
                <ImageToolbarPositioner editor={editor}/>
            </BlockNoteView>
        </>

    );
}
