import { useQuery } from "@tanstack/react-query";
import API from "../../../api";
import { IntegrationEmbedTagOption } from "../../../types/embeds";

async function listIntegrationEmbedConfigSelectInputValues({
  productId,
}: {
  productId: string;
}) {
  const response = await API.get(`/embed/theme-tags/${productId}`);
  return response.data.payload as {
    tags: IntegrationEmbedTagOption[];
    themes: { id: string; name: string }[];
  };
}

export default function useAddIntegrationEmbedConfigSelectInputValues({
  productId,
  onSuccess,
  onError,
}: {
  productId: string;
  onSuccess: () => void;
  onError: () => void;
}) {
  return useQuery(
    ["listIntegrationEmbedConfigSelectInputValues", productId],
    () => listIntegrationEmbedConfigSelectInputValues({ productId }),
    {
      staleTime: 15 * 60 * 1000, // 15 minutes in milliseconds
      onSuccess: onSuccess,
      onError: onError,
    }
  );
}
