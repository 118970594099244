import React from 'react';
import {Modal} from 'flowbite-react';
import UserProfileForm from './UserProfileForm';
import {HiOutlinePencil} from "react-icons/hi";
import useUpdateUserProfileName from "../../../hooks/profile/useUpdateUserProfileName";
import {toast} from "react-toastify";

export default function UserProfileEditModal(
    {
        firstName,
        lastName
    }: {
        firstName: string;
        lastName: string;
    }
) {
    const [showModal, setShowModal] = React.useState(false);

    const {mutate} = useUpdateUserProfileName({
        onSuccess: () => {
            setShowModal(false);
            toast.success('Profile updated successfully');
        },
        onError: () => {
            toast.error('Failed to update profile');
        }
    })


    return (
        <div>

            <button
                onClick={() => {
                    setShowModal(true);
                }}
                className="group p-2 pl-4 bg-blue-500 rounded-full mt-2 mr-2 inline-flex items-center justify-center overflow-hidden hover:w-auto transition-all duration-300 ease-in-out"
            >
                <HiOutlinePencil className="w-4 h-4 text-white"/>
                <span
                    className="ml-2 group-hover:mr-2 text-white max-w-0 group-hover:max-w-xs overflow-hidden transition-all duration-300 ease-in-out">
                    Profile
                </span>
            </button>

            <Modal show={showModal} onClose={() => setShowModal(false)}>
                <Modal.Header>
                    <h2 className="text-lg font-semibold text-gray-700">Edit User Profile</h2>
                </Modal.Header>
                <Modal.Body>
                    <UserProfileForm userProfile={{
                        firstName: firstName,
                        lastName: lastName
                    }} onSubmit={mutate}/>
                </Modal.Body>
            </Modal>
        </div>
    );
}
