import React, {useState} from "react";
import {FiLoader, FiMinusCircle, FiPlusCircle, FiSave} from "react-icons/fi";
import {
    UserData,
    useAddSubscribers,
} from "../../pages/Subscriber/hooks/useAddSubscribers";
import {useParams} from "react-router-dom";
import {useQueryClient} from "@tanstack/react-query";
import {toast} from "react-toastify";

const AddSubscriberDynamicForm = () => {
    const {prodId} = useParams();

    const queryClient = useQueryClient();

    const [userData, setUserData] = useState<UserData[]>([
        {name: "", email: ""},
    ]);

    const onUserAddSuccess = () => {
            // When we successfully save the user to db, reset the forms
            setUserData([{name: "", email: "", categoryID: ""}]);
            queryClient.invalidateQueries(["pendingSubscribers", prodId]);
            queryClient.invalidateQueries(["subscribers", prodId]);

            toast.success("Subscriber added successfully")
        }
    ;

    const {mutate, isLoading, error} = useAddSubscribers(onUserAddSuccess);

    const handleAddInput = (index?: number) => {
        const newUser = {name: "", email: "", categoryID: ""};

        if (typeof index === "number") {
            const updatedUserData = [...userData];
            updatedUserData.splice(index + 1, 0, newUser);
            setUserData(updatedUserData);
        } else {
            setUserData([...userData, newUser]);
        }
    };

    const handleInputChange = (index: number, field: string, value: string) => {
        const updatedUserData: UserData[] = [...userData];
        updatedUserData[index][field] = value;
        setUserData(updatedUserData);

        console.log(updatedUserData);
    };

    const handleRemoveInput = (index: number) => {
        const updatedUserData: UserData[] = [...userData];
        updatedUserData.splice(index, 1);
        setUserData(updatedUserData);
    };

    const handleSubmit = () => {
        if (prodId) {
            mutate({productId: prodId, subscribers: userData});
        }
    };

    if (error) {
        return <div>Error</div>;
    }

    return (
        <div className="mt-4">
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit();
                }}
            >
                {userData.map((user, index) => (
                    <div key={index} className="mb-4 flex space-x-3 justify-between">
                        <div className="flex space-x-2 mb-1 w-full">
                            <div className="flex-1">
                                <div className="">
                                    <input
                                        type="text"
                                        required
                                        disabled={isLoading}
                                        placeholder="Full Name"
                                        className="rounded border-gray-200 text-gray-500 py-2 px-3 w-full"
                                        value={user.name}
                                        onChange={(e) =>
                                            handleInputChange(index, "name", e.target.value)
                                        }
                                    />
                                </div>
                            </div>
                            <div className="flex-1">
                                <input
                                    type="email"
                                    required
                                    disabled={isLoading}
                                    placeholder="Email Address"
                                    className="rounded border-gray-200 text-gray-500 py-2 px-3 w-full"
                                    value={user.email}
                                    onChange={(e) =>
                                        handleInputChange(index, "email", e.target.value)
                                    }
                                />
                            </div>
                        </div>
                        <div className="h-full flex space-x-2 mb-4 mt-auto">
                            <button
                                disabled={isLoading}
                                type="button"
                                className="ml-2 text-green-500"
                                onClick={() => handleAddInput(index)}
                            >
                                <FiPlusCircle className="w-5 h-5"/>
                            </button>
                            <button
                                disabled={isLoading}
                                type="button"
                                className="ml-2 text-red-500"
                                onClick={() => handleRemoveInput(index)}
                            >
                                <FiMinusCircle className="w-5 h-5"/>
                            </button>
                        </div>
                    </div>
                ))}
                <div className="flex space-x-2">
                    <button
                        type="button"
                        className="bg-blue-500 text-white py-2 px-4 rounded-md flex space-x-2"
                        onClick={() => handleAddInput()}
                        disabled={isLoading}
                    >
                        <FiPlusCircle className="my-auto h-4 w-4"/>
                        <span className="my-auto">Add User</span>
                    </button>
                    <button
                        type="submit"
                        className="bg-green-500 text-white py-2 px-4 ml-2 rounded-md flex space-x-2"
                        disabled={isLoading}
                    >
                        {isLoading ? (
                            <FiLoader className="my-auto h-4 w-4 animate-spin"/>
                        ) : (
                            <FiSave className="my-auto h-4 w-4"/>
                        )}
                        <span className="my-auto">Submit</span>
                    </button>
                </div>
            </form>
        </div>
    );
};

export default AddSubscriberDynamicForm;
