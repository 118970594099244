import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Dropdown } from "flowbite-react";
import { Organization } from "../../hooks/store/useOrgStore";

interface OrganizationSelectProps {
  organizations: Organization[] | undefined;
}

export function OrganizationSelect({ organizations }: OrganizationSelectProps) {
  const navigate = useNavigate();
  const [selectedOrgName, setSelectedOrgName] = useState("");

  useEffect(() => {
    const storedOrgId = localStorage.getItem("orgID");
    if (storedOrgId) {
      const selectedOrg = organizations?.find(
        (org) => org.ROWID === storedOrgId
      );
      setSelectedOrgName(selectedOrg ? selectedOrg.name : "");
    }
  }, [organizations]);

  const handleSelect = (organization: Organization) => {
    localStorage.setItem("orgID", organization.ROWID);
    setSelectedOrgName(organization.name);
    const selectedProd = organization.products
      ? organization.products[0].ROWID
      : "";
    localStorage.setItem("prodID", selectedProd);
    navigate(`/app/${organization.ROWID}/notes/${selectedProd}`);
  };

  return (
    <div className="w-max">
      <Dropdown label={selectedOrgName || "Loading ..."} inline>
        {organizations?.map((organization) => (
          <Dropdown.Item
            key={organization.ROWID}
            onClick={() => handleSelect(organization)}
          >
            {organization.name}
          </Dropdown.Item>
        ))}
      </Dropdown>
    </div>
  );
}
