import DefaultSettingLayout from "../../../components/Settings/SettingsDefaultLayout";
import UserLogoCard from "../../../components/Settings/Profile/UserLogoCard";
import useGetUserProfile from "../../../hooks/profile/useGetUserProfile";
import GenericCircularIndicatorSkeletonLoader from "../../../components/Shared/GenericCirularIndicatorSkeletonLoader";

export default function DefaultSettingProfilePage() {
    const {
        data: user,
        isLoading,
        isError,
        error
    } = useGetUserProfile();

    if ((!isLoading && isError) || (!isLoading && !user)) return <div>Error: {`${error}`}</div>

    return (
        <>
            <DefaultSettingLayout>
                {
                    isLoading ?
                        <GenericCircularIndicatorSkeletonLoader/> : <UserLogoCard user={user}/>
                }
            </DefaultSettingLayout>
        </>
    )
        ;
}
