import React, {useState} from 'react';
import {useFormik} from 'formik';
import {Button, ToggleSwitch} from 'flowbite-react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import LanguageSelect from "../lanugage";
import useEditorStore from "../../../../state/useEditorStore";
import {useTranslateWithRAI} from "../../../../hooks/ai/useTranslateWithRAI";
import {toast} from "react-toastify";
import loading = toast.loading;

export default function TranslateWithRAIForm({
                                                 closeModal,
                                                 content,
                                             }: {
    closeModal: () => void;
    content: string;
}) {
    const [isCustomContent, setIsCustomContent] = useState<boolean>(false);
    const [contentToRender, setContentToRender] = useState<string>(content ?? "");
    const [selectedDestinationLanguage, setSelectedDestinationLanguage] = useState<string | null>("en");

    const [didTranslate, setDidTranslate] = useState<boolean>(false);


    const {mutate, isLoading} = useTranslateWithRAI(
        (value) => {
            setContentToRender(value);
            setDidTranslate(true);
        },
        (error) => {
            console.error(error);
        }
    );

    const {requestReplaceContent, requestAppendContent} = useEditorStore()

    const toggleContentSource = () => setIsCustomContent(!isCustomContent);

    const formik = useFormik({
        initialValues: {
            customContent: '',
        },
        onSubmit: values => {
            mutate({
                message: isCustomContent ? values.customContent : contentToRender,
                targetLanguage: selectedDestinationLanguage ?? "en",
            });
            setIsCustomContent(false)
        },
    });

    return (
        <div className={`space-y-4 ${isCustomContent ? 'h-[50vh]' : 'h-[60vh]'}`}>
            <div className="flex justify-between items-center">
                <ToggleSwitch checked={isCustomContent}
                              label={isCustomContent ? 'Custom Content' : 'Default Content'}
                              onChange={toggleContentSource}/>
                <div className="flex-1"></div>
                {didTranslate && !isLoading && <div className={"space-x-2"}>
                    <button
                        onClick={(e) => {
                            e.preventDefault();
                            requestAppendContent(contentToRender)
                            closeModal()
                        }}
                        className="mb-4 py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                        Append Content
                    </button>
                    <button
                        onClick={(e) => {
                            e.preventDefault();
                            requestReplaceContent(contentToRender)
                            closeModal()
                        }}
                        className="mb-4 py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                        Replace Content
                    </button>
                </div>}
            </div>
            <form onSubmit={formik.handleSubmit} className="flex flex-col space-y-2">
                <LanguageSelect
                    label={"Select Destination Language"}
                    selectedLanguage={selectedDestinationLanguage}
                    setSelectedLanguage={setSelectedDestinationLanguage}
                />
                <div
                    className={`mb-4 transition-all duration-500 ease-in-out overflow-y-auto bg-gray-100 rounded-lg ${isLoading ? 'animate-pulse' : ''}`}>
                    {isLoading ? (
                        <div className="p-[20px] h-[40vh] space-y-4">
                            <div className="space-y-2">
                                <div className="h-4 bg-gray-300 rounded"></div>
                                <div className="h-4 bg-gray-300 rounded"></div>
                                <div className="h-4 bg-gray-300 rounded"></div>
                                <div className="h-4 bg-gray-300 rounded"></div>
                                <div className="h-4 bg-gray-300 rounded"></div>
                                <div className="h-4 bg-gray-300 rounded w-5/6"></div>
                            </div>
                            <div className="space-y-2">
                                <div className="h-4 bg-gray-300 rounded"></div>
                                <div className="h-4 bg-gray-300 rounded"></div>
                                <div className="h-4 bg-gray-300 rounded"></div>
                                <div className="h-4 bg-gray-300 rounded"></div>
                                <div className="h-4 bg-gray-300 rounded"></div>
                                <div className="h-4 bg-gray-300 rounded w-5/6"></div>
                            </div>
                        </div>
                    ) : isCustomContent ? (
                        <textarea
                            className="prose p-[20px] h-[30vh] text-gray-600 dark:text-gray-400 max-w-none w-full bg-gray-100 rounded-lg"
                            placeholder="Type your content here..."
                            onChange={formik.handleChange}
                            value={formik.values.customContent}
                            name="customContent"
                        />
                    ) : (
                        <div className="h-[40vh]">
                            <ReactMarkdown
                                remarkPlugins={[remarkGfm]}
                                className="prose p-[20px] text-gray-600 dark:text-gray-400 max-w-none"
                            >
                                {contentToRender}
                            </ReactMarkdown>
                        </div>
                    )}
                </div>
                <Button type="submit" color={"blue"}>
                    {isLoading ? "Translating..." : didTranslate ? "Re-translate" : "Translate"}
                </Button>
            </form>
        </div>
    );
}
