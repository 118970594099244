import React, { ReactNode } from "react";
import SettingsDefaultSidebar from "./SettingsDefaultSidebar";

type DefaultSettingLayoutProps = {
  children: ReactNode;
};

const DefaultSettingLayout: React.FC<DefaultSettingLayoutProps> = ({
  children,
}) => {
  return (
    <div className="flex h-full">
      <SettingsDefaultSidebar />
      <div className="flex-1 p-4 h-full">
        <div className="bg-gray-100 rounded-sm h-full p-2">{children}</div>
      </div>
    </div>
  );
};

export default DefaultSettingLayout;
