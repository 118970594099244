import create from 'zustand';

type ContentAction = {
    type: 'append' | 'replace';
    content: string;
} | null;

interface EditorState {
    contentAction: ContentAction;
    requestAppendContent: (content: string) => void;
    requestReplaceContent: (content: string) => void;
    clearContentAction: () => void;
}

const useEditorStore = create<EditorState>((set) => ({
    contentAction: null, // Holds the action to be performed along with any necessary data

    requestAppendContent: (content: string) => set(() => ({
        contentAction: {type: 'append', content}
    })),

    requestReplaceContent: (content: string) => set(() => ({
        contentAction: {type: 'replace', content}
    })),

    clearContentAction: () => set(() => ({contentAction: null}))
}));


export default useEditorStore;