import { useQuery } from "@tanstack/react-query";
import API from "../../api";
import { IndividualFeedback } from "../../types/feedback";

async function fetchAllFeedbacks(productId: string) {
  const response = await API.get(`/product/${productId}/feedback`);
  return response.data.payload as IndividualFeedback[];
}

export default function useGetFeedbacksForProduct(productId: string) {
  return useQuery(
    ["feedbacks", productId],
    () => {
      return fetchAllFeedbacks(productId);
    },
    {
      cacheTime: 1000 * 60 * 5,
      refetchOnWindowFocus: false,
    }
  );
}
