import {useEffect} from "react";
import {Outlet, useNavigate, useParams} from "react-router-dom";
import Footer from "./components/Shared/Footer";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import {ReactQueryDevtools} from "@tanstack/react-query-devtools";
import {StickyNavbar} from "./components/Shared/NavBar";
import ProductDashboardSideNavigation from "./components/Layout/Sidebar/Sidebar";
import useUserOrgStore from "./hooks/store/useUserOrgStore";
import API from "./api";
import {DarkThemeToggle, Flowbite} from 'flowbite-react';
import {lightDefaultTheme} from "@blocknote/react";

const queryClient = new QueryClient();

declare global {
    interface Window {
        catalyst: any;
    }
}

async function getUserOrgDetails() {
    const data = await API.get("/user-orgs");
    return data.data.payload;
}

function EntryPoint() {
    const navigate = useNavigate();
    const {orgId, prodId} = useParams();

    useEffect(() => {
        const navigationPath = window.location.pathname;
        console.log(navigationPath);
        if (navigationPath && navigationPath.includes("/index.html")) {
            navigate("/app/");
        }
    }, [navigate]);

    const addUserOrg = useUserOrgStore((state) => state.addUserOrg);

    useEffect(() => {
        getUserOrgDetails()
            .then((userOrgs) => {
                userOrgs.forEach((userOrg: any) => {
                    addUserOrg({
                        organizationID: userOrg.organizationID,
                        organizationName: userOrg.name,
                        userRole: userOrg.role,
                    });
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }, [addUserOrg]);

    return (
        <Flowbite>
            <QueryClientProvider client={queryClient}>
                <div className="flex w-screen flex-cols bg-gray-50 dark:bg-gray-700 dark:text-white">
                    {orgId && prodId && (
                        // When both OrgID and ProdID are present, then it means we are in main product dashboard
                        <div className="flex-none h-screen">
                            <ProductDashboardSideNavigation/>
                        </div>
                    )}

                    <div className="flex flex-col flex-1 h-screen overflow-y-scroll">
                        {orgId && prodId ? (
                            // When both OrgID and ProdID are present, then it means we are in main product dashboard
                            <div className="flex flex-1 flex-col">
                                <div className="">
                                    <StickyNavbar/>
                                </div>
                                <div className="flex-1 px-5 py-2 flex flex-col">
                                    <div className="h-full flex flex-col">
                                        <div className="flex-1">
                                            <Outlet/>
                                        </div>
                                        <div className="mt-2">
                                            <Footer/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            // In other cases, we are either in global settings or organization settings page
                            <div className="h-full flex flex-col">
                                <div className="flex-1">
                                    <Outlet/>
                                </div>
                                <div className="">
                                    <Footer/>
                                </div>
                            </div>
                        )}
                        <ReactQueryDevtools initialIsOpen={false}/>
                    </div>
                </div>
            </QueryClientProvider>
        </Flowbite>
    );
}

export default EntryPoint;
