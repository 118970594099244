export default function SubscriberVerificationSkeleton() {
  return (
    <div className="flex w-2/3 mx-auto items-center justify-center h-full animate-pulse">
      <div className="flex-1 flex flex-col md:flex-row space-y-2 md:space-x-4 max-w-4xl rounded-2xl overflow-hidden shadow-lg p-16 bg-white">
        <div className="md:w-1/2 mb-4 md:mb-0 flex flex-col">
          {/* Logo and Title Skeleton */}
          <div className="mb-4 text-center">
            <div className="w-16 h-16 bg-gray-300 rounded-full mx-auto mb-4"></div>
            <div className="h-6 bg-gray-300 rounded w-3/4 mx-auto mb-2"></div>
            <div className="h-4 bg-gray-300 rounded w-1/2 mx-auto"></div>
          </div>
          {/* Text Content Skeleton */}
          <div className="flex-1 mb-4 space-y-4 mt-6">
            <div className="h-4 bg-gray-300 rounded"></div>
            <div className="h-4 bg-gray-300 rounded w-5/6"></div>
            <div className="h-4 bg-gray-300 rounded w-2/3"></div>
          </div>
          {/* Buttons Skeleton */}
          <div className="flex justify-center space-x-4 mt-2">
            <div className="w-24 h-10 bg-gray-300 rounded"></div>
            <div className="w-24 h-10 bg-gray-300 rounded"></div>
          </div>
        </div>
        {/* Image Skeleton */}
        <div className="md:w-1/2 h-80 bg-gray-300 rounded"></div>
      </div>
    </div>
  );
}
