import { useMutation } from "@tanstack/react-query";
import API from "../../api";

async function addOrInviteMemberToOrg({
    email,
    orgId,
    role,
}: {email: string, orgId: string, role: string}){
    const response = await API.post(`/users/${orgId}/members`, {
        email: email,
        role: role,
    });

    return response.data.payload;
}


function useAddMember({
    onSuccess,
    onError,
}: {
    onSuccess?: (data: any) => void;
    onError?: (error: any) => void;
}){
    return useMutation(addOrInviteMemberToOrg, {
        onSuccess: onSuccess,
        onError: onError
    });
}

export default useAddMember;