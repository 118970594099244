import API from "../../../../api";
import { useQuery } from "@tanstack/react-query";

type EmbedConfig = {
  CREATORID: string;
  MODIFIEDTIME: string;
  NoOfProducts: string;
  AllowedDomain: string;
  ProductID: string;
  CREATEDTIME: string;
  ROWID: string;
  ThemeColor: string;
  TextColor: string;
  AccentColor: string;
  notes: EmbedNote[];
};

export interface EmbedNote {
  CREATORID: string;
  IsArchived: boolean;
  MODIFIEDTIME: string;
  IsPublic: boolean;
  productId: string;
  CREATEDTIME: string;
  Title: string;
  ROWID: string;
  UserIDs: null;
  Content: string;
  Tags: string[];
}

type Tag = {
  id: string;
  label: string;
  color: string;
};

type User = {
  id: string;
  name: string;
};

export interface EmbedOrgInfo {
  tags: Tag[];
  users: User[];
}

const getEmbedConfig = async (embedID: string): Promise<EmbedConfig> => {
  try {
    const { data } = await API.get(`/embed/${embedID}`);
    return data;
  } catch (error) {
    console.log(error);
    return {
      AccentColor: "",
      TextColor: "",
      ThemeColor: "",
      AllowedDomain: "",
      CREATEDTIME: "",
      CREATORID: "",
      MODIFIEDTIME: "",
      NoOfProducts: "",
      ProductID: "",
      ROWID: "",
      notes: [],
    };
  }
};

const getEmbedOrgInfoConfig = async (
  orgID: string
): Promise<EmbedConfig | undefined> => {
  try {
    const { data } = await API.get(`/organizations/${orgID}/tags-and-users`);
    return data;
  } catch (error) {
    console.log(error);
    return undefined;
  }
};

export function useEmbed(embedID: string | undefined) {
  return useQuery({
    queryKey: [embedID, "embed"],
    queryFn: () => getEmbedConfig(embedID ? embedID : "-1"),
    staleTime: 1000 * 60,
  });
}

export function useEmbedOrgInfo(orgID: string | undefined) {
  return useQuery({
    queryKey: [orgID, "embedorginfo"],
    queryFn: () => getEmbedOrgInfoConfig(orgID ? orgID : "-1"),
    staleTime: 1000 * 60,
  });
}
