import {useMutation, useQuery} from "@tanstack/react-query";
import API from "../../../api";

export type Product = {
    ROWID: string;
    name: string;
    logo: string;
    public_releases: number;
    private_releases: number;
    orgID: string;
    description: string;
    privacyPolicy?: string;
    socialsTwitter?: string;
    socialsFacebook?: string;
    socialsInstagram?: string;
    socialsLinkedIn?: string;
    customerContactUs?: string;
    doubleOptIn: boolean;
};

const getProducts = async (orgId: string | undefined): Promise<Product[]> => {
    try {
        if (orgId === undefined) {
            Promise.reject(new Error("orgId is undefined"));
        }
        const {data} = await API.get(`/products/${orgId}`);
        return data.payload;
    } catch (error) {
        console.log(error);
        throw error;
    }
};

const sendTestEmail = async ({
                                 toUser,
                                 noteID,
                             }: {
    toUser: string;
    noteID: string;
}) => {
    const response = await fetch(
        "/server/blast_function/releaseNote/publish/test/" + noteID,
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                toUser: toUser,
            }),
        }
    );

    if (!response.ok) {
        throw new Error("Email sending failed");
    }

    return response.json();
};

export function useProducts(orgId: string | undefined) {
    if (orgId === undefined) {
        orgId = "0";
    }

    return useQuery({
        queryKey: [orgId, "products"],
        queryFn: () => getProducts(orgId),
        staleTime: Infinity,
    });
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const updateProduct = async (productId: string) => {
    try {
        const {data} = await API.put(`/product/${productId}`);
        return data.payload;
    } catch (error) {
        console.log(error);
        throw error;
    }
};

const useSendTestEmail = (onSuccess: () => void, onError: () => void) => {
    const mutation = useMutation(sendTestEmail);

    const sendEmail = (emailData: any) => {
        mutation.mutate(emailData, {
            onSuccess,
            onError,
        });
    };

    return {
        sendEmail,
        isSending: mutation.isLoading,
        error: mutation.isError ? mutation.error : null,
    };
};

export default useSendTestEmail;
