import { toast } from "react-toastify";
import { useQueryClient } from "@tanstack/react-query";
import GenericDeleteModal from "../../../Shared/GenericDeleteModal";
import { HiUserRemove } from "react-icons/hi";

export default function OrganizationMemberDeleteModal({
  memberName,
  memberID,
  organizationID,
}: {
  organizationID: string;
  memberID: string;
  memberName: string;
}) {
  const queryClient = useQueryClient();

  return (
    <GenericDeleteModal
      buttonElement={
        <div className="relative group">
          <HiUserRemove className="h-4 w-4 text-gray-500 hover:text-gray-900 mx-1" />
          <span className="absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-6 w-auto px-2 py-1 bg-black text-white text-xs rounded hidden group-hover:block">
            Remove
          </span>
        </div>
      }
      deleteButtonText="Remove"
      apiUrl={`/users/${organizationID}/members/${memberID}`}
      onDeleteSuccess={function (): void {
        toast.success(
          `Member ${memberName} has been removed from the organization`
        );
        queryClient.invalidateQueries(["members", organizationID]);
      }}
      onDeleteError={() => {
        toast.error(`Error removing member: ${memberName}`);
      }}
      titlePreText="Are you sure you want to remove member"
      titleText={memberName}
    />
  );
}
