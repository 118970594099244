import { useParams } from "react-router-dom";
import SettingsOrganizationLayout from "../../../components/Settings/Organization/SettingsOrganizationLayout";
import useGetMembersForOrgID from "../../../hooks/members/useGetMembersForOrgID";
import MemberListTableComponent from "../../../components/Settings/Organization/Members/MemberListComponent";
import TableSkeletonLoader from "../../../components/Shared/TableSkeletonLoader";
import OrganizationMemberAddModal from "../../../components/Member/OrganizationMemberAddModal";
import { OrganizationUser } from "../../../types/users";
import useGetOrgRoleForUser from "../../../hooks/store/useGetOrgRoleForUser";

export default function OrgSettingsMembersPage() {
  const { orgId } = useParams<{ orgId: string }>();
  const {
    data: members,
    error: getMembersError,
    isLoading: getMemberIsLoading,
    isFetching: isFetchingMembers,
  } = useGetMembersForOrgID(orgId ?? "");

  const userRole = useGetOrgRoleForUser(orgId ?? "");

  if (orgId === undefined) {
    return <div>404</div>;
  }

  if (getMembersError) {
    return <div>Error: {getMembersError.message}</div>;
  }

  return (
    <>
      <SettingsOrganizationLayout>
        <div className="shadow-md p-8 bg-white rounded-md h-full">
          <div className="flex flex-row">
            <h1 className="text-2xl font-bold mb-6 text-gray-700 flex-1">
              Organization Members
            </h1>
            {userRole === "admin" && !getMemberIsLoading && members && (
              <div>
                <OrganizationMemberAddModal
                  organizationID={orgId}
                  membersList={(members as OrganizationUser[]).map(
                    (m) => m.email
                  )}
                />
              </div>
            )}
          </div>
          <div className="bg-gray-200 mt-2">
            {getMemberIsLoading || !members || isFetchingMembers ? (
              <TableSkeletonLoader />
            ) : (
              <MemberListTableComponent members={members} orgID={orgId} />
            )}
          </div>
        </div>
      </SettingsOrganizationLayout>
    </>
  );
}
