import {useMutation} from "@tanstack/react-query";
import API from "../../api";

interface PublicFeedbackFormInput {
    email: string;
    feedback: string;
    isLike: boolean;
}

async function addPublicFeedback(
    {
        feedbackData,
        productID,
        noteID,
    }: {
        feedbackData: PublicFeedbackFormInput;
        productID: string;
        noteID: string;
    }) {
    const url = `/public/product/note/feedback/${productID}/${noteID}`;

    const response = await API.post(url, {
        email: feedbackData.email,
        feedback: feedbackData.feedback,
        isLike: feedbackData.isLike,
    });

    return response.data;
}

export default function useAddPublicFeedback(
    {
        onSuccess,
        onError,
    }: {
        onSuccess: (data: any) => void;
        onError: (error: any) => void;
    }) {
    return useMutation(addPublicFeedback, {
        onSuccess,
        onError,
    });
}
