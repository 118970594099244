import useEditProduct from "../../hooks/product/useEditProduct";
import {OrganizationProductForm} from "./OrganizationProductForm";

interface OrganizationProductEditFormProps {
    prductID: string;
    onError: () => void;
    onSuccess: () => void;
    initialValues: {
        logoURL: string;
        name: string;
        doubleOptIn: boolean,
        description: string;
        privacyPolicy?: string;
        socialsTwitter?: string;
        socialsFacebook?: string;
        socialsInstagram?: string;
        socialsLinkedIn?: string;
        customerContactUs?: string;
    };
}

export default function OrganizationProductEditForm(
    {
        prductID,
        onError,
        onSuccess,
        initialValues,
    }: OrganizationProductEditFormProps) {
    const {mutate: updateProduct} = useEditProduct({
        onSuccess: onSuccess,
        onError: onError,
    });

    function onSubmit(values: any) {
        const valueToUpdateWith = {
            productID: prductID,
            logo: values.logoFileID,
            name: values.name,
            description: values.description,
            privacyPolicy: values.privacyPolicy,
            socialsTwitter: values.socialsTwitter,
            socialsFacebook: values.socialsFacebook,
            socialsInstagram: values.socialsInstagram,
            socialsLinkedIn: values.socialsLinkedIn,
            customerContactUs: values.customerContactUs,
            doubleOptIn: values.doubleOptIn,
        }

        updateProduct(valueToUpdateWith);
    }

    return (
        <div>
            <OrganizationProductForm
                initialValues={{
                    logoFileURL: initialValues.logoURL,
                    logoFileID: undefined,
                    name: initialValues.name,
                    doubleOptIn: initialValues.doubleOptIn,
                    description: initialValues.description,
                    privacyPolicy: initialValues.privacyPolicy,
                    socialsTwitter: initialValues.socialsTwitter,
                    socialsFacebook: initialValues.socialsFacebook,
                    socialsInstagram: initialValues.socialsInstagram,
                    socialsLinkedIn: initialValues.socialsLinkedIn,
                    customerContactUs: initialValues.customerContactUs,
                }}
                onSubmit={onSubmit}
            />
        </div>
    );
}
