import React from "react";

type PriorityComponentProps = {
    value: string;
    onChange: (value: string) => void;
};

export const PriorityComponent: React.FC<PriorityComponentProps> = (
    {
        value,
        onChange,
    }
) => {

    return (
        <div className="mt-4">
            <label
                htmlFor="priority"
                className="block text-gray-700 text-md font-semibold mb-2"
            >
                Update Granularity
            </label>
            <select
                id="priority"
                required
                className="w-full my-1 border-solid border-2 border-gray-300 rounded p-2 mb-4"
                value={value}
                onChange={(e) => onChange(e.target.value)}
            >
                <option value="5">Major Release</option>
                <option value="3">Minor Release</option>
                <option value="1">Patch Release</option>
            </select>
        </div>
    );
};
