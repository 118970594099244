import React, { useState, useCallback, KeyboardEvent } from "react";

type TagComponentProps = {
  tags: string[];
  onTagsChange: (tags: string[]) => void;
};

export const TagComponent: React.FC<TagComponentProps> = ({
  tags,
  onTagsChange,
}) => {
  const [currentTag, setCurrentTag] = useState<string>("");

  const handleAddTag = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      if (event.key === "Enter") {
        event.preventDefault(); // Prevent the form from being submitted
        if (currentTag && !tags.includes(currentTag)) {
          onTagsChange([...tags, currentTag]);
          setCurrentTag("");
        }
      }
    },
    [tags, currentTag, onTagsChange]
  );

  const handleRemoveTag = useCallback(
    (tagToRemove: string) => {
      onTagsChange(tags.filter((tag) => tag !== tagToRemove));
    },
    [tags, onTagsChange]
  );

  return (
    <div className="flex flex-col gap-2">
      <label
        htmlFor="tagForm"
        className="block text-gray-700 text-md font-semibold mb-2"
      >
        Tags
      </label>
      <input
        type="text"
        id="tagForm"
        className="border-2 border-gray-300 p-2 rounded w-full transition duration-150"
        placeholder="Type a tag and press Enter..."
        value={currentTag}
        onChange={(e) => setCurrentTag(e.target.value)}
        onKeyDown={handleAddTag}
      />
      <div className="flex flex-wrap gap-2 mt-2 mb-2">
        {tags.map((tag, index) => (
          <div
            key={index}
            className="flex items-center gap-1 p-1 bg-blue-100 px-2 rounded-sm"
          >
            <span className="text-black text-sm">{tag}</span>
            <button
              className="text-xs text-black rounded-full w-4 h-4 flex items-center justify-center"
              onClick={(e) => {
                e.preventDefault();
                handleRemoveTag(tag);
              }}
            >
              x
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};
