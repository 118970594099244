import { Button, Modal } from "flowbite-react";
import { useState } from "react";
import { HiPlusCircle } from "react-icons/hi";
import OrganizationProductAddForm from "./OrganizationProductAddForm";
import { toast } from "react-toastify";
import { useQueryClient } from "@tanstack/react-query";

interface OrganizationProductAddModalProps {
  organizationID: string;
}

function OrganizationProductAddModal({
  organizationID,
}: OrganizationProductAddModalProps) {
  const [openModal, setOpenModal] = useState(false);
  const queryClient = useQueryClient();

  return (
    <>
      <Button color="blue" onClick={() => setOpenModal(true)}>
        <div className="flex space-x-1 align-center justify-center">
          <HiPlusCircle className="h-5 w-5" />
          <span>Add New</span>
        </div>
      </Button>
      <Modal show={openModal} size="2xl" onClose={() => setOpenModal(false)}>
        <Modal.Header>
          <h2 className="text-gray-700 font-bold ">Add Product</h2>
        </Modal.Header>
        <Modal.Body>
          <OrganizationProductAddForm
            organizationID={organizationID}
            onError={() => {
              toast.error("Error Adding Product. Please try again later.");
            }}
            onSuccess={() => {
              toast.success("Product Added");
              queryClient.invalidateQueries([organizationID, "products"]);
              queryClient.invalidateQueries(["organizations"]);
              setOpenModal(false);
            }}
          />
        </Modal.Body>
      </Modal>
    </>
  );
}

export default OrganizationProductAddModal;
