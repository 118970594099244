import React, { useState, ChangeEvent, FormEvent } from "react";
import Select, { MultiValue } from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useParams } from "react-router-dom";
import API from "../../../../api";
import CircularLoading from "../../../../components/Shared/CircularLoading";
import { toast } from "react-toastify";
import { useQueryClient } from "@tanstack/react-query";

interface FormValues {
  NoOfProducts: number;
  AccentColor: string;
  ThemeColor: string;
  TextColor: string;
  AllowedDomain: string[];
  PriorityFilter: number | undefined;
  Label: string;
  LanguageFilter: any;
  CSTagFilter: any;
  LowestVersionFilter: number | undefined;
  HighestVersionFilter: number | undefined;
  CSUserFilter: any;
  LowestDateFilter: Date | null;
  HighestDateFilter: Date | null;
}

interface SelectOption {
  value: string;
  label: string;
}

const initialFormValues: FormValues = {
  Label: "Embed",
  NoOfProducts: 5,
  AccentColor: "#CCCCCC",
  ThemeColor: "#EEEEEE",
  TextColor: "#BBBBBB",
  AllowedDomain: ["*"],
  PriorityFilter: undefined,
  LanguageFilter: [],
  CSTagFilter: [],
  LowestVersionFilter: undefined,
  HighestVersionFilter: undefined,
  CSUserFilter: [],
  LowestDateFilter: null,
  HighestDateFilter: null,
};

interface SelectValue {
  value: string;
  label: string;
}

interface ThemeOption {
  ThemeName: string;
  AccentColor: string;
  ThemeColor: string;
  TextColor: string;
}

const themeOptions: Record<string, ThemeOption> = {
  Standard: {
    ThemeName: "Standard",
    AccentColor: "#CCCCCC",
    ThemeColor: "#EEEEEE",
    TextColor: "#BBBBBB",
  },
  "Hot Blue": {
    ThemeName: "HotBlue",
    AccentColor: "#FF5733",
    ThemeColor: "#3357FF",
    TextColor: "#000000",
  },
};

function isMultiSelectValue(value: any): value is MultiValue<SelectValue> {
  return Array.isArray(value);
}

const languageOptions: SelectOption[] = [
  { value: "en", label: "English" },
  { value: "fr", label: "French" },
];

const csUserOptions: SelectOption[] = [
  { value: "User1", label: "User1" },
  { value: "User2", label: "User2" },
  // Add more options as needed
];

const csTagOptions: SelectOption[] = [
  { value: "Tag1", label: "Tag1" },
  { value: "Tag2", label: "Tag2" },
  // Add more options as needed
];

const EmbedForm: React.FC = () => {
  const [formValues, setFormValues] = useState<FormValues>(initialFormValues);
  const [usePrebuiltColorPalette, setUsePrebuiltColorPalette] = useState(true);
  const [selectedPrebuiltColorPalette, setSelectedPrebuiltColorPalette] =
    useState<string>("");

  const queryClient = useQueryClient();
  const [isFormSubmitLoading, setIsFormSubmitLoading] =
    useState<boolean>(false);

  const [newDomain, setNewDomain] = useState("");
  const [allowedDomains, setAllowedDomains] = useState<string[]>([]);

  const { prodId } = useParams();

  const handleSelectChange = (
    name: string,
    selectedOptions: MultiValue<SelectValue> | Date | null
  ) => {
    if (isMultiSelectValue(selectedOptions)) {
      const convertedSelectedOptions: SelectOption[] = selectedOptions.map(
        (option) => ({
          value: option.value,
          label: option.label,
        })
      );

      setFormValues((prevValues) => ({
        ...prevValues,
        [name]: convertedSelectedOptions,
      }));
    } else {
      handleDateChange(name, selectedOptions as Date);
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    if (
      name === "LowestVersionFilter" &&
      formValues.HighestVersionFilter !== undefined &&
      parseInt(value) > formValues.HighestVersionFilter
    ) {
      return; // Don't update the state if the value is greater than HighestVersionFilter
    }

    if (
      name === "HighestVersionFilter" &&
      formValues.LowestVersionFilter !== undefined &&
      parseInt(value) < formValues.LowestVersionFilter
    ) {
      return; // Don't update the state if the value is less than LowestVersionFilter
    }

    setFormValues((prevValues) => ({ ...prevValues, [name]: value }));
  };

  const isValidDomain = (domain: string) => {
    if (domain === "*") return true;
    if (domain.includes("localhost")) return true;
    if (allowedDomains.includes(domain)) return false;

    const domainRegex = /^(?:(?!-)[A-Za-z0-9-]{1,63}(?<!-)\.)+[A-Za-z]{2,6}$/;
    return domainRegex.test(domain);
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    try {
      setIsFormSubmitLoading(true);
      const allowedDomains = formValues.AllowedDomain.join(",");
      const response = await API.post("/embed/", {
        ...formValues,
        AllowedDomain: allowedDomains,
        ProductID: prodId,
      });
      console.log("Response:", response.data, response.status);
      // You can perform actions with the response here
      if (response.status) {
        setIsFormSubmitLoading(false);
        if (response.status === 200) {
          toast.success("Integration added successfully");
          queryClient.invalidateQueries(["integration", "embeds", prodId]);
        }
      }
    } catch (error) {
      console.error("Error:", error);
      // Handle error scenarios
    }
  };

  const handleDateChange = (name: string, date: Date | null) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: date,
    }));
  };

  const handleAddDomain = () => {
    if (isValidDomain(newDomain)) {
      setAllowedDomains((prevDomains) => [...prevDomains, newDomain]);
      setNewDomain("");
    }
  };

  const handleRemoveDomain = (index: number) => {
    const updatedDomains = allowedDomains.filter((_, i) => i !== index);
    setAllowedDomains(updatedDomains);
  };

  const handleAddDomainKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleAddDomain();
    }
  };

  const handleThemeSelect = (selectedTheme: string) => {
    if (themeOptions[selectedTheme]) {
      const selectedThemeValues = themeOptions[selectedTheme];
      setFormValues((prevValues) => ({
        ...prevValues,
        ThemeColor: selectedThemeValues.ThemeColor,
        TextColor: selectedThemeValues.TextColor,
        AccentColor: selectedThemeValues.AccentColor,
      }));
    }
  };

  return (
    <div className="flex">
      <div className="w-full px-4">
        <form onSubmit={handleSubmit}>
          {/* Label */}
          <div className="mb-4">
            <label className="block mb-2 text-gray-600">Label:</label>
            <input
              type="text"
              name="Label"
              value={formValues.Label}
              onChange={handleChange}
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
            />
            <label className="block mb-2 text-sm text-gray-600">
              <i>
                The label that will be used to refer to this embed form
                internally.
              </i>
            </label>
          </div>

          <div className="mb-4">
            <input
              hidden
              type="text"
              name="ProductID"
              value={prodId!.toString()}
              onChange={handleChange}
              disabled={true}
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
            />
          </div>

          <div className="mb-4">
            <label className="block mb-2 text-gray-600">Theme:</label>
            {usePrebuiltColorPalette ? (
              <Select
                options={Object.keys(themeOptions).map((theme) => ({
                  value: theme,
                  label: theme,
                }))}
                onChange={(selectedOption) => {
                  if (selectedOption) {
                    setSelectedPrebuiltColorPalette(selectedOption.label);
                    handleThemeSelect(selectedOption.value);
                  }
                }}
                value={
                  usePrebuiltColorPalette
                    ? {
                        value: formValues.ThemeColor,
                        label: selectedPrebuiltColorPalette,
                      }
                    : null
                }
              />
            ) : (
              <div>
                <label className="block mb-2 text-gray-600">
                  Accent Color:
                </label>
                <input
                  type="color"
                  name="AccentColor"
                  value={formValues.AccentColor}
                  onChange={handleChange}
                  className="h-[2.5em] border border-gray-300 rounded px-3 py-2 w-full focus:outline-none focus:border-blue-500"
                />
                <label className="block mb-2 text-gray-600">Theme Color:</label>
                <input
                  type="color"
                  name="ThemeColor"
                  value={formValues.ThemeColor}
                  onChange={handleChange}
                  className="h-[2.5em] border border-gray-300 rounded px-3 py-2 w-full focus:outline-none focus:border-blue-500"
                />
                <label className="block mb-2 text-gray-600">Text Color:</label>
                <input
                  type="color"
                  name="TextColor"
                  value={formValues.TextColor}
                  onChange={handleChange}
                  className="h-[2.5em] border border-gray-300 rounded px-3 py-2 w-full focus:outline-none focus:border-blue-500"
                />
              </div>
            )}
            <div className="mt-2">
              <label className="inline-flex items-center">
                <input
                  type="checkbox"
                  checked={usePrebuiltColorPalette}
                  onChange={() =>
                    setUsePrebuiltColorPalette(!usePrebuiltColorPalette)
                  }
                  className="w-4 h-4 text-blue-500 form-checkbox"
                />
                <span className="ml-2 text-gray-600">
                  Use Prebuilt Color Pallet
                </span>
              </label>
            </div>
          </div>

          <div className="mb-4">
            <label className="block mb-2 text-gray-600">Allowed Domain:</label>
            <div className="flex items-center">
              <input
                type="text"
                value={newDomain}
                onKeyDown={handleAddDomainKeyDown}
                onChange={(e) => setNewDomain(e.target.value)}
                className="w-full px-3 py-2 border border-gray-300 rounded-l focus:outline-none focus:border-blue-500"
              />
              <button
                type="button"
                onClick={handleAddDomain}
                disabled={!isValidDomain(newDomain)}
                className={`bg-blue-500 text-white px-3 py-2 rounded-r ${
                  !isValidDomain(newDomain)
                    ? "opacity-50 cursor-not-allowed"
                    : "hover:bg-blue-600"
                } focus:outline-none`}
              >
                Add
              </button>
            </div>
            <div className="mt-2 space-x-2">
              {allowedDomains.map((domain, index) => (
                <span
                  key={index}
                  className="inline-flex items-center px-3 py-1 text-gray-800 bg-gray-200 rounded"
                >
                  {domain}
                  <button
                    className="ml-2 text-gray-500"
                    onClick={() => handleRemoveDomain(index)}
                  >
                    X
                  </button>
                </span>
              ))}
            </div>
            <label className="block mb-2 text-sm text-gray-600">
              <i>List of domains where the you will be adding the embed.</i>
            </label>
          </div>
          <div>
            <div className="mb-4">
              <label className="block mb-2 text-gray-600">
                Release Count Limit:
              </label>
              <input
                type="text"
                name="NoOfProducts"
                value={formValues.NoOfProducts}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
              />
              <label className="block mb-2 text-sm text-gray-600">
                <i>
                  Setting a value here would mean that the embed will only
                  latest `n` releases.
                </i>
              </label>
            </div>
            {/* PriorityFilter */}
            <div className="mb-4">
              <label className="block mb-2 text-gray-600">
                Priority Filter:
              </label>
              <input
                type="number"
                name="PriorityFilter"
                value={formValues.PriorityFilter}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
              />
              <label className="block mb-2 text-sm text-gray-600">
                <i>
                  This will filter release notes with priority equal or higher
                  than the one you give.
                </i>
              </label>
            </div>

            <div className="mb-4">
              <label className="block mb-2 text-gray-600">Language:</label>
              <Select
                required
                options={languageOptions}
                name="LanguageFilter"
                value={formValues.LanguageFilter}
                onChange={(selectedOption) =>
                  handleSelectChange("LanguageFilter", selectedOption)
                }
              />
            </div>
            <div className="mb-4">
              <label className="block mb-2 text-gray-600">Tags:</label>
              <Select
                options={csTagOptions}
                isMulti
                name="CSTagFilter"
                value={formValues.CSTagFilter}
                onChange={(selectedOptions) =>
                  handleSelectChange("CSTagFilter", selectedOptions)
                }
              />
              <label className="block mb-2 text-sm text-gray-600">
                <i>Not selecting any would mean all tags are allowed.</i>
              </label>
            </div>
            {/* LowestVersionFilter */}
            <div className="mb-4">
              <label className="block mb-2 text-gray-600">
                Lowest Version Filter:
              </label>
              <input
                type="number"
                name="LowestVersionFilter"
                value={formValues.LowestVersionFilter}
                max={formValues.HighestVersionFilter}
                min={0}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
              />
              <label className="block mb-2 text-sm text-gray-600">
                <i>
                  This will filter release notes with version equal or greater
                  than the one given.
                </i>
              </label>
            </div>
            {/* HighestVersionFilter */}
            <div className="mb-4">
              <label className="block mb-2 text-gray-600">
                Highest Version Filter:
              </label>
              <input
                type="number"
                name="HighestVersionFilter"
                value={formValues.HighestVersionFilter}
                min={formValues.LowestVersionFilter}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
              />
              <label className="block mb-2 text-sm text-gray-600">
                <i>
                  This will filter release notes with version equal or less than
                  the one given.
                </i>
              </label>
            </div>
            {/* CSUserFilter */}
            <div className="mb-4">
              <label className="block mb-2 text-gray-600">Authors:</label>
              <Select
                options={csUserOptions}
                isMulti
                name="CSUserFilter"
                value={formValues.CSUserFilter}
                onChange={(selectedOptions) =>
                  handleSelectChange("CSUserFilter", selectedOptions)
                }
              />
              <label className="block mb-2 text-sm text-gray-600">
                <i>
                  This will show the release notes written by selected authors.
                </i>
              </label>
            </div>
            {/* LowestDateFilter */}
            <div className="mb-4">
              <label className="block mb-2 text-gray-600">
                Lowest Date Filter:
              </label>
              <DatePicker
                selected={formValues.LowestDateFilter}
                onChange={(date: Date | null) =>
                  handleSelectChange("LowestDateFilter", date)
                }
                className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
              />
            </div>
            {/* HighestDateFilter */}
            <div className="mb-4">
              <label className="block mb-2 text-gray-600">
                Highest Date Filter:
              </label>
              <DatePicker
                selected={formValues.HighestDateFilter}
                onChange={(date: Date | null) =>
                  handleSelectChange("HighestDateFilter", date)
                }
                className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
              />
            </div>
          </div>
          <div className="mb-4">
            {isFormSubmitLoading ? (
              <button
                disabled
                className="px-4 py-2 text-white bg-gray-500 rounded focus:outline-none"
              >
                <CircularLoading />
              </button>
            ) : (
              <button
                type="submit"
                className="px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-600 focus:outline-none"
              >
                Submit
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default EmbedForm;
