import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import SubscriberTable from "../../components/Subscriber/SubscriberTable";
import { HiOutlineExclamationCircle, HiPlusCircle } from "react-icons/hi";
import { Button, Modal } from "flowbite-react";
import useDeleteSubscriber from "./hooks/useDeleteSubscriber";
import { FiLoader } from "react-icons/fi";
import TableSkeletonLoader from "../../components/Shared/TableSkeletonLoader";

const { useSubscribers } = require("./hooks/useSubscribers");

export default function SubscriberList() {
  const { prodId, orgId } = useParams();
  const navigate = useNavigate();

  const { getSubscribersForProduct } = useSubscribers();
  const { isModalOpen, closeModal, openModal, deleteSubscriber, isDeleting } =
    useDeleteSubscriber();

  const queryClient = useQueryClient();

  const { data: subscribers, isLoading: loading } = useQuery({
    queryKey: ["subscribers", prodId],
    queryFn: () => getSubscribersForProduct(prodId),
    staleTime: 1000 * 60,
  });

  const onDeleteSuccess = () => {
    queryClient.invalidateQueries(["subscribers", prodId]);
  };

  const onDeleteFailure = (error: string) => {
    console.log(error);
  };

  return (
    <div className="mx-1 my-2 bg-white p-6 rounded-lg h-full">
      <div className="flex">
        <div className="flex-1">
          <h1 className="text-gray-800 text-2xl font-semibold mb-4">
            Subscribers
          </h1>
        </div>
        <div>
          <Button
            color="blue"
            onClick={() =>
              navigate(`/app/${orgId}/notes/${prodId}/subscribers/add`)
            }
          >
            <div className="flex space-x-1 align-center justify-center">
              <HiPlusCircle className="h-5 w-5" />
              <span>Add Subscribers</span>
            </div>
          </Button>
        </div>
      </div>
      <div className="mt-2">
        {loading ? (
          <TableSkeletonLoader />
        ) : (
          <div>
            <SubscriberTable
              openDeleteModal={openModal}
              subscribers={subscribers}
            />

            {isModalOpen && (
              <Modal show={isModalOpen} onClose={closeModal}>
                <Modal.Body>
                  <div className="text-center">
                    <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
                    <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                      Are you sure you want to delete this product?
                    </h3>
                    <div className="flex justify-center gap-4">
                      <Button
                        color="failure"
                        className="w-36"
                        onClick={() => {
                          deleteSubscriber(onDeleteSuccess, onDeleteFailure);
                        }}
                      >
                        {isDeleting ? (
                          <FiLoader className="my-auto h-4 w-4 animate-spin" />
                        ) : (
                          "Yes, I'm sure"
                        )}
                      </Button>
                      <Button color="gray" onClick={closeModal}>
                        No, cancel
                      </Button>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
