import { useParams, useSearchParams } from "react-router-dom";
import useGetFeedbacksForProduct from "../../hooks/feedback/useGetFeedbacksForProduct";
import TableSkeletonLoader from "../../components/Shared/TableSkeletonLoader";
import ListFeedbackTableComponent from "../../components/Feedback/ListFeedbackTableComponent";
import { FiX } from "react-icons/fi";
import { useEffect, useState } from "react";

export default function FeedbackListTablePage() {
  const { prodId: productID } = useParams<{ prodId: string }>();
  const [searchParams, setSearchParams] = useSearchParams();

  const { data, isLoading, isError, isFetching } = useGetFeedbacksForProduct(
    productID ?? ""
  );

  const [releaseNoteSearchParamTitle, setReleaseNoteSearchParamTitle] =
    useState<string | undefined>(undefined);

  useEffect(() => {
    const releaseNoteSearchParam = searchParams.get("release_note");
    if (releaseNoteSearchParam) {
      if (data) {
        const releaseNote = data.find(
          (feedback) =>
            feedback.releaseNote.id.toString() === releaseNoteSearchParam
        );

        if (releaseNote) {
          setReleaseNoteSearchParamTitle(releaseNote.releaseNote.title);
        } else {
          setReleaseNoteSearchParamTitle("Invalid Release Note");
        }
      }
    }
  }, [data, searchParams]);

  if (isError) {
    return <>Error...</>;
  }

  return (
    <>
      <div className="shadow-md p-8 m-1 bg-white rounded-md h-full">
        <div className="flex flex-row">
          <h1 className="text-2xl font-bold mb-6 text-gray-700 flex-1">
            Feedback
          </h1>
          {releaseNoteSearchParamTitle && (
            <div className="flex items-center bg-gray-50 shadow-sm rounded-lg overflow-hidden px-4 py-2 mb-4">
              <span className="text-sm font-medium text-gray-700">
                <b>Show Only:</b> <span>{releaseNoteSearchParamTitle}</span>
              </span>
              <button
                onClick={() => {
                  setSearchParams({ release_note: "" });
                  setReleaseNoteSearchParamTitle(undefined);
                }}
                className="ml-4 bg-gray-200 rounded-full p-1 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400"
              >
                <FiX className="text-gray-600 text-lg" />
              </button>
            </div>
          )}
        </div>
        <div className="mt-2">
          {!data || isLoading || isFetching || !productID ? (
            <TableSkeletonLoader />
          ) : (
            <ListFeedbackTableComponent
              productID={productID}
              feedbacks={data}
            />
          )}
        </div>
      </div>
    </>
  );
}
