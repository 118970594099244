import React from 'react';
import {ReleaseNoteWithRowId} from "../../../../types/releaseNotes";
import useGetPublishedNoteStats from "../../../../hooks/releasenote/useGetPublishedNoteStats";
import {AiOutlineMail, AiOutlineClockCircle, AiOutlineUser} from 'react-icons/ai'; // Import icons

export function PublishedNoteStatsModalBodyComponent({note}: { note: ReleaseNoteWithRowId; }) {
    const {data: stats, isLoading, isError} = useGetPublishedNoteStats({
        noteID: note.ROWID,
        onSuccess: (data) => console.log("Success", data),
        onError: (error) => console.log("Error", error),
    });

    if (isError) return <div>Error loading statistics</div>;

    return (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 p-4">
            <>
                <StatCard
                    icon={<AiOutlineClockCircle className="text-white text-3xl w-5 h-5"/>}
                    title="Published Time"
                    value={isLoading ? null : stats.publishedAt}
                    bgColor="bg-red-500"
                />
                <StatCard
                    icon={<AiOutlineMail className="text-white text-3xl w-5 h-5"/>}
                    title="Emails Sent"
                    value={isLoading ? null : stats.emailsSent === -1 ? "Unknown" : stats.emailsSent}
                    bgColor="bg-blue-500"
                />
                <StatCard
                    icon={<AiOutlineUser className="text-white text-3xl w-5 h-5"/>}
                    title="Current Subscribers"
                    value={isLoading ? null : stats.currentSubscribers}
                    bgColor="bg-gray-500"
                />
            </>
        </div>
    );
}

function StatCard({icon, title, value, bgColor}: {
    icon: React.ReactNode;
    title: string;
    value: string | number | null;
    bgColor: string;
}) {
    return (
        <div className={`rounded-lg overflow-hidden shadow-lg ${bgColor} p-4 text-white`}>
            <div className="flex items-center space-x-2">
                <div>
                    {icon}
                </div>
                <h2 className="text-md font-bold">{title}</h2>
            </div>
            {
                value === null ?
                    <p className="mt-2 text-sm font-bold w-32 rounded-xl h-4 animate-pulse bg-gray-200/[0.5]"></p>
                    : <p className="mt-2 text-sm font-bold">
                        {value}
                    </p>
            }
        </div>
    );
}
