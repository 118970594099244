import toast from "react-hot-toast";
import GenericDeleteModal from "../../Shared/GenericDeleteModal";
import { useQueryClient } from "@tanstack/react-query";

export function DeleteIntegrationEmbedModalComponent({
  embedID,
  productID,
  embedLabel,
}: {
  embedID: string;
  productID: string;
  embedLabel: string;
}) {
  const queryClient = useQueryClient();

  return (
    <GenericDeleteModal
      apiUrl={`/embed/${embedID}`}
      onDeleteSuccess={() => {
        toast.success("Embed deleted");
        queryClient.invalidateQueries(["integration", "embeds", productID]);
      }}
      titleText={embedLabel}
    />
  );
}
