import {Modal} from "flowbite-react";
import {useState} from "react";
import {HiOutlinePencilAlt} from "react-icons/hi";
import WriteWithRAIForm from "./WriteWithRAIForm";

export default function WriteWithRAIModal() {
    const [showModal, setShowModal] = useState<boolean>(false);

    return (
        <div>
            <div>
                <button
                    className="text-sm w-max py-2 flex items-center space-x-2"
                    onClick={(e) => {
                        e.preventDefault();
                        setShowModal(true);
                    }}
                >
                    <HiOutlinePencilAlt className="w-4 h-4"/>
                    <span>Write with RAI </span>
                </button>
            </div>
            <div>
                <Modal
                    size="4xl"
                    popup
                    dismissible
                    show={showModal}
                    onClose={() => {
                        setShowModal(false);
                    }}
                >
                    <Modal.Body>
                        <div className="py-4">
                            <WriteWithRAIForm closeModal={() => setShowModal(false)}/>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        </div>
    );
}
