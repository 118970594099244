import { Sidebar } from "flowbite-react";
import {
  HiOfficeBuilding,
  HiQuestionMarkCircle,
  HiUser,
  HiCog,
} from "react-icons/hi";
import { Link, useNavigate, useParams } from "react-router-dom";

function SettingsOrganizationSidebar() {
  const navigate = useNavigate();
  const { orgId } = useParams();

  return (
    <Sidebar aria-label="ReleaseBlast Settings Sidebar">
      <Link to={`/app/redirect`}>
        <Sidebar.Logo
          href="#"
          img="https://i.imgur.com/uhCxppI.png"
          imgAlt="ReleaseBlast Logo"
        >
          <div className="flex flex-col">
            <span>ReleaseBlast</span>
            <span className="text-xs text-gray-500">Organization Settings</span>
          </div>
        </Sidebar.Logo>
      </Link>
      <Sidebar.Items>
        <Sidebar.ItemGroup></Sidebar.ItemGroup>

        <Sidebar.ItemGroup>
          <Sidebar.Item icon={HiCog}>
            <button
              className="w-full text-left py-1"
              onClick={() => {
                navigate(`/app/settings/organization/${orgId}`);
              }}
            >
              <p>Profile</p>
            </button>
          </Sidebar.Item>
          <Sidebar.Item icon={HiUser}>
            <button
              className="w-full text-left py-1"
              onClick={() => {
                navigate(`/app/settings/organization/${orgId}/members`);
              }}
            >
              <p>Members</p>
            </button>
          </Sidebar.Item>
          <Sidebar.Item icon={HiOfficeBuilding}>
            <button
              className="w-full text-left py-1"
              onClick={() => {
                navigate(`/app/settings/organization/${orgId}/products`);
              }}
            >
              <p>Products</p>
            </button>
          </Sidebar.Item>
        </Sidebar.ItemGroup>
      </Sidebar.Items>
    </Sidebar>
  );
}

export default SettingsOrganizationSidebar;
