import { useState, useEffect } from "react";

const useIsAuthenticated = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

  useEffect(() => {
    const authentication = async () => {
      //@ts-ignore
      return await window.catalyst.auth.isUserAuthenticated();
    };

    authentication()
      .then((response) => {
        if (response.status === 200) {
          setIsAuthenticated(true);
        } else {
          setIsAuthenticated(false);
        }
        setLoading(false);
      })
      .catch((error) => {
        setIsAuthenticated(false);
        setLoading(false);
        console.log("Unexpected error", error);
      });
  }, []);

  return { loading, isAuthenticated };
};

export default useIsAuthenticated;
