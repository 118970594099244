import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import GenericSkeletonLoader from "../Shared/GenericSkeletonLoadingPage";

interface IProtectedRoutes {
  children: any;
}

const ProtectedRoutes = (props: IProtectedRoutes) => {
  const [loading, setLoading] = useState<boolean>(true);

  const navigate = useNavigate();
  useEffect(() => {
    const authentication = async () => {
      //@ts-ignore
      return await window.catalyst.auth.isUserAuthenticated();
    };

    authentication()
      .then((response) => {
        if (response.status === 200) {
          setLoading(false);
        } else {
          setLoading(false);
          navigate("/__catalyst/auth/login");
        }
      })
      .catch((error) => {
        setLoading(false);
        navigate("/__catalyst/auth/login");
      });
  }, [navigate]);

  return loading ? <GenericSkeletonLoader /> : <>{props?.children}</>;
};

export default ProtectedRoutes;
