import React from "react";
import { Organization } from "../../../../hooks/store/useOrgStore";
import { HiLocationMarker } from "react-icons/hi";
import OrganizationEditModal from "../../../Organization/OrganizationEditOrganizationModal";
import useUserOrgStore from "../../../../hooks/store/useUserOrgStore";

type OrganizationLogoCardProps = {
  organization: Organization;
  onSaveLogo: (file: File) => void;
};

const OrganizationLogoCard: React.FC<OrganizationLogoCardProps> = ({
  organization,
}) => {
  const userRole = useUserOrgStore(
    (state) =>
      state.userOrgs.filter(
        (userOrg) => userOrg.organizationID === organization.ROWID
      )[0].userRole
  );

  return (
    <div className="flex flex-col bg-white shadow rounded-lg p-4 w-full h-full">
      <div className="flex-1 mx-auto w-full">
        <div
          className="w-full h-64 bg-cover bg-center rounded-lg relative mb-24"
          style={{
            backgroundImage: `url(https://images.unsplash.com/photo-1499336315816-097655dcfbda?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2710&q=80)`,
          }}
        >
          <div className="absolute bottom-16 left-0 right-0 flex justify-center">
            <img
              src={organization.logo}
              alt="Organization Logo"
              className="w-32 h-32 rounded-full z-10 mb-[-25%]"
            />
          </div>
          {userRole === "admin" && (
            <div className="absolute top-0 right-0">
              <OrganizationEditModal />
            </div>
          )}
        </div>
        <div className="text-center mt-12 w-full mx-auto">
          <h3 className="text-4xl font-semibold leading-normal text-blue-gray-700 mb-2">
            {organization.name}
          </h3>
          <div className="text-sm leading-normal mt-0 mb-2 text-blue-gray-400 font-bold uppercase">
            <HiLocationMarker className="inline-block mr-2 text-blue-gray-400 align-middle" />
            Los Angeles, California
          </div>
        </div>
      </div>
      <div className="flex-1 mx-48 mt-10 py-10 border-t border-blue-gray-200 px-4">
        <div className="mx-auto w-full">
          <h1 className="text-xl font-bold">General information</h1>
          <p className="text-blue-gray-600 text-justify py-2">
            {organization.description}
          </p>
          <div className="flex mt-8">
            <div className="flex-1 space-y-2">
              <div className="flex space-x-2">
                <p className="text-blue-gray-500">Products: </p>
                <p className="text-blue-gray-800 font-semibold">
                  {organization.products?.length || 0}
                </p>
              </div>
              <div className="flex space-x-2">
                <p className="text-blue-gray-500">Release Notes: </p>
                <p className="text-blue-gray-800 font-semibold">
                  {organization.public_releases}
                </p>
              </div>
              <div className="flex space-x-2">
                <p className="text-blue-gray-500">Active Since: </p>
                <p className="text-blue-gray-800 font-semibold">Jan 10, 2023</p>
              </div>
            </div>
            <div className="flex-1 space-y-2">
              <div className="flex space-x-2">
                <p className="text-blue-gray-500">Members: </p>
                <p className="text-blue-gray-800 font-semibold">
                  {organization.members?.length || 0}
                </p>
              </div>
              <div className="flex space-x-2">
                <p className="text-blue-gray-500">Draft Release Notes: </p>
                <p className="text-blue-gray-800 font-semibold">
                  {organization.private_releases}
                </p>
              </div>
              <div className="flex space-x-2">
                <p className="text-blue-gray-500">Current Account Plan: </p>
                <p className="text-blue-gray-800 font-semibold">Free</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrganizationLogoCard;
