import {Sidebar} from "flowbite-react";
import {
    HiUserCircle,
    HiOfficeBuilding,
    HiLockClosed,
    HiQuestionMarkCircle,
} from "react-icons/hi";
import {Link, useNavigate} from "react-router-dom";

function SettingsDefaultSidebar() {
    const navigate = useNavigate();

    return (
        <Sidebar aria-label="ReleaseBlast Settings Sidebar">
            <Link to="/app/redirect">
                <Sidebar.Logo
                    href="/"
                    img="https://i.imgur.com/uhCxppI.png"
                    imgAlt="ReleaseBlast Logo"
                >
                    <div className="flex flex-col">
                        <span>ReleaseBlast</span>
                        <span className="text-xs text-gray-500">User Settings</span>
                    </div>
                </Sidebar.Logo>
            </Link>
            <Sidebar.Items>
                <Sidebar.ItemGroup></Sidebar.ItemGroup>

                <Sidebar.ItemGroup>
                    <Sidebar.Item icon={HiUserCircle}>
                        <button
                            className="w-full text-left py-1"
                            onClick={() => {
                                navigate("/app/settings");
                            }}
                        >
                            <p>Profile</p>
                        </button>
                    </Sidebar.Item>
                    {/*<Sidebar.Item href="#" icon={HiLockClosed}>*/}
                    {/*    <button*/}
                    {/*        className="w-full text-left py-1"*/}
                    {/*        onClick={() => {*/}
                    {/*            navigate("/app/settings/security");*/}
                    {/*        }}*/}
                    {/*    >*/}
                    {/*        <p>Security</p>*/}
                    {/*    </button>*/}
                    {/*</Sidebar.Item>*/}
                    <Sidebar.Item icon={HiOfficeBuilding}>
                        <button
                            className="w-full text-left py-1"
                            onClick={() => {
                                navigate("/app/settings/organizations");
                            }}
                        >
                            <p>Organizations</p>
                        </button>
                    </Sidebar.Item>
                    {/*<Sidebar.Item href="#" icon={HiQuestionMarkCircle}>*/}
                    {/*    <button*/}
                    {/*        className="w-full text-left py-1"*/}
                    {/*        onClick={() => {*/}
                    {/*            navigate("/app/settings");*/}
                    {/*        }}*/}
                    {/*    >*/}
                    {/*        <p>Help</p>*/}
                    {/*    </button>*/}
                    {/*</Sidebar.Item>*/}
                </Sidebar.ItemGroup>
            </Sidebar.Items>
        </Sidebar>
    );
}

export default SettingsDefaultSidebar;
