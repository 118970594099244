import {ReleaseNoteListTable} from "../../../components/Note/View/NoteListTable";
import {useNavigate, useParams} from "react-router-dom";
import {useReleaseNotes} from "../hooks/useReleaseNotes";
import TableSkeletonLoader from "../../../components/Shared/TableSkeletonLoader";
import {Button} from "flowbite-react";
import {HiPlusCircle} from "react-icons/hi";

export default function ViewReleaseNotesTablePage() {
    const {prodId, orgId} = useParams<{ orgId: string; prodId: string }>();
    const {
        data: releaseNotes,
        isLoading: loading,
        isFetching,
    } = useReleaseNotes(prodId ?? "");

    const navigate = useNavigate();

    return (
        <div className="shadow-md p-8 m-1 bg-white rounded-md h-full dark:bg-gray-800 dark:text-white">
            <div className="flex flex-row">
                <h1 className="text-2xl font-bold mb-6 text-gray-700 flex-1 dark:text-gray-200">
                    Release Notes
                </h1>
                <div>
                    <Button
                        color="blue"
                        onClick={() => {
                            navigate(`/app/${orgId}/notes/${prodId}/add`);
                        }}
                    >
                        <div className="flex space-x-1 align-center justify-center">
                            <HiPlusCircle className="h-5 w-5"/>
                            <span>Create Release Note</span>
                        </div>
                    </Button>
                </div>
            </div>
            <div className="mt-2">
                {!releaseNotes || loading || !prodId || !orgId ? (
                    <TableSkeletonLoader/>
                ) : (
                    <ReleaseNoteListTable
                        isFetching={isFetching}
                        releaseNotes={releaseNotes}
                        prodId={prodId}
                        orgId={orgId}
                    />
                )}
            </div>
        </div>
    );
}
