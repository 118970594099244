import { useQueryClient } from "@tanstack/react-query";
import GenericDeleteModal from "../../Shared/GenericDeleteModal";

export function FeedbackDeleteModal({
  feedbackID,
  prodID,
}: {
  feedbackID: string;
  prodID: string;
}) {
  const queryClient = useQueryClient();

  const apiUrl = `/feedback/${feedbackID}`;

  const handleDeleteSuccess = () => {
    queryClient.invalidateQueries(["feedbacks", prodID]);
  };

  const handleDeleteError = (error: any) => {
    console.error("Error deleting the category:", error);
  };

  return (
    <GenericDeleteModal
      apiUrl={apiUrl}
      onDeleteSuccess={handleDeleteSuccess}
      onDeleteError={handleDeleteError}
      titleText={"Feedback"}
    />
  );
}
