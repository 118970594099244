import {Modal} from "flowbite-react";
import {useState} from "react";
import {HiPencil} from "react-icons/hi";
import {toast} from "react-toastify";
import {useQueryClient} from "@tanstack/react-query";
import OrganizationProductEditForm from "./OrganizationProductEditForm";

interface OrganizationProductEditModalProps {
    organizationID: string;
    productID: string;
    initialValues: {
        doubleOptIn: boolean;
        logoURL: string;
        name: string;
        description: string;
        privacyPolicy?: string;
        socialsTwitter?: string;
        socialsFacebook?: string;
        socialsInstagram?: string;
        socialsLinkedIn?: string;
        customerContactUs?: string;
    };
}

function OrganizationProductEditModal(
    {
        organizationID,
        initialValues,
        productID,
    }: OrganizationProductEditModalProps) {
    const [openModal, setOpenModal] = useState(false);
    const queryClient = useQueryClient();

    return (
        <>
            <button
                onClick={() => setOpenModal(true)}
                className="text-green-700 rounded-sm focus:outline-none"
            >
                <HiPencil className="h-4 w-4"/>
                <span
                    className="absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-6 w-auto px-2 py-1 bg-black text-white text-xs rounded hidden group-hover:block">
          Edit
        </span>
            </button>
            <Modal show={openModal} size="2xl" onClose={() => setOpenModal(false)}>
                <Modal.Header>
                    <h2 className="text-gray-700 font-bold ">Edit Product</h2>
                </Modal.Header>
                <Modal.Body>
                    <OrganizationProductEditForm
                        prductID={productID}
                        onError={() => {
                            toast.error("Error Updating Product. Please try again later.");
                        }}
                        onSuccess={() => {
                            toast.success("Product Information Updated");
                            queryClient.invalidateQueries([organizationID, "products"]);
                            queryClient.invalidateQueries(["organizations"]);
                            setOpenModal(false);
                        }}
                        initialValues={initialValues}
                    />
                </Modal.Body>
            </Modal>
        </>
    );
}

export default OrganizationProductEditModal;
