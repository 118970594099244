import LandingContactUs from "../../components/Landing/index/contactus";
import LandingHomeCustomers from "../../components/Landing/index/customer";
import LandingHomeFeatures from "../../components/Landing/index/features";
import LandingHomeHero from "../../components/Landing/index/hero";
import LandingHomePricing from "../../components/Landing/index/pricing";
import LandingHomeTestimonials from "../../components/Landing/index/testimonials";
import LandingNavbar from "../../components/Landing/navbar";

export default function Landing() {
  return (
    <>
      <LandingNavbar />
      <LandingHomeHero />
      <LandingHomeCustomers />
      <LandingHomeFeatures />
      <LandingHomeTestimonials />
      <LandingHomePricing />
      <LandingContactUs />
    </>
  );
}
