import { Button, Modal, Table } from "flowbite-react";
import { FiLoader } from "react-icons/fi";
import AddSubscriberDynamicForm from "../../components/Subscriber/AddSubscriberDynamicForm";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useSubscribers } from "./hooks/useSubscribers";
import { useParams } from "react-router-dom";
import { HiOutlineExclamationCircle, HiTrash } from "react-icons/hi";
import GenericSkeletonLoader from "../../components/Shared/GenericSkeletonLoadingPage";
import useDeleteSubscriber from "./hooks/useDeleteSubscriber";
import SubscriberFileUploadComponent from "../../components/Subscriber/SubscriberFileUploadComponent";

export default function SubscriberAddPage() {
  const { prodId } = useParams();

  const { getPendingSubscribersForProduct } = useSubscribers();
  const { isModalOpen, openModal, closeModal, deleteSubscriber, isDeleting } =
    useDeleteSubscriber();

  const queryClient = useQueryClient();

  const { data: subscribers, isLoading: loading } = useQuery({
    queryKey: ["pendingSubscribers", prodId],
    queryFn: () => getPendingSubscribersForProduct(prodId ?? ""),
    staleTime: 1000 * 60,
  });

  if (loading || !subscribers) {
    return <GenericSkeletonLoader />;
  }

  const onDeleteSuccess = () => {
    queryClient.invalidateQueries(["pendingSubscribers", prodId]);
  };

  const onDeleteFailure = (error: string) => {
    console.log(error);
  };

  return (
    <div className="mx-2 my-2">
      <div className="flex">
        <div className="flex-1">
          <h1 className="text-gray-800 text-2xl font-semibold mb-4">
            Add New Subscribers
          </h1>
        </div>
        <div>
          <SubscriberFileUploadComponent />
        </div>
      </div>
      <div className="w-full flex space-x-5">
        <div className="flex-1 flex flex-col pb-4 rounded-lg mt-4">
          <div className="bg-white pb-6">
            <div className="rounded-t-md bg-gray-200 text-gray-700 px-6 py-3 font-semibold text-xs uppercase">
              New Subscriber Form
            </div>
            <div className="px-6">
              <AddSubscriberDynamicForm />
            </div>
          </div>
          <div className="flex-1"></div>
        </div>
        <div className="w-1/3 mt-4 rounded-md h-full max-h-[70vh] overflow-y-auto">
          <Table hoverable>
            <Table.Head>
              <Table.HeadCell className="bg-gray-200">
                Pending Invitations
              </Table.HeadCell>
              <Table.HeadCell className="bg-gray-200">
                <span className="sr-only">Edit</span>
              </Table.HeadCell>
            </Table.Head>
            <Table.Body className="divide-y">
              {subscribers.length === 0 && (
                <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                  <Table.Cell>No Pending Invitations</Table.Cell>
                  <Table.Cell className="flex space-x-1"></Table.Cell>
                </Table.Row>
              )}

              {subscribers.map((subscriber) => (
                <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                  <Table.Cell>{subscriber.Email}</Table.Cell>
                  <Table.Cell className="flex space-x-1">
                    <div className="flex-1"></div>
                    <div className="flex space-x-1">
                      <div className="relative group">
                        <button
                          onClick={() => openModal(subscriber.ROWID)}
                          className="text-red-700 rounded-sm focus:outline-none"
                        >
                          <HiTrash className="h-5 w-5" />
                          <span className="absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-6 w-auto px-2 py-1 bg-black text-white text-xs rounded hidden group-hover:block">
                            Delete
                          </span>
                        </button>
                      </div>
                    </div>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
          {isModalOpen && (
            <Modal show={isModalOpen} onClose={closeModal}>
              <Modal.Body>
                <div className="text-center">
                  <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
                  <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                    Are you sure you want to delete this product?
                  </h3>
                  <div className="flex justify-center gap-4">
                    <Button
                      color="failure"
                      className="w-36"
                      onClick={() => {
                        deleteSubscriber(onDeleteSuccess, onDeleteFailure);
                      }}
                    >
                      {isDeleting ? (
                        <FiLoader className="my-auto h-4 w-4 animate-spin" />
                      ) : (
                        "Yes, I'm sure"
                      )}
                    </Button>
                    <Button color="gray" onClick={closeModal}>
                      No, cancel
                    </Button>
                  </div>
                </div>
              </Modal.Body>
            </Modal>
          )}
        </div>
      </div>
    </div>
  );
}
