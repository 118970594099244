import {Sidebar} from "flowbite-react";
import {
    HiChartPie,
    HiCog,
    HiDocument,
    HiPuzzle,
    HiQuestionMarkCircle,
    HiSparkles,
    HiUserGroup,
} from "react-icons/hi";
import {useNavigate, useParams} from "react-router-dom";
import {useOrganizations} from "../../../pages/Organizations/hooks/useOrganizations";
import {useEffect, useState} from "react";
import {Organization} from "../../../hooks/store/useOrgStore";
import {toast} from "react-toastify";

export default function SideNavigation() {
    const navigate = useNavigate();

    const {orgId} = useParams();
    const {prodId} = useParams();

    const {data: organizations} = useOrganizations();

    const [currentOrganization, setCurrentOrganization] = useState<
        Organization | undefined
    >();

    useEffect(() => {
        function getCurrentOrganization() {
            if (orgId === undefined) {
                return;
            } else {
                const org = organizations?.find((e) => {
                    return e.ROWID === orgId;
                });

                return org;
            }
        }

        const currOrg = getCurrentOrganization();

        setCurrentOrganization(currOrg);
    }, [orgId, organizations]);

    return (
        <div className="h-screen">
            <Sidebar aria-label="ReleaseBlast Sidebar">
                {orgId === null ? (
                    <div className="px-2">
                        <div className="flex items-center space-x-3 animate-pulse">
                            <div className="h-8 w-8 bg-gray-300 rounded-full"></div>
                            <div className="h-4 w-32 bg-gray-300 rounded"></div>
                        </div>
                        <hr className="mt-4"/>

                        <div className="mt-4 space-y-3">
                            {[...Array(7)].map((_, idx) => (
                                <div
                                    key={idx}
                                    className="flex items-center space-x-3 animate-pulse"
                                >
                                    <div className="h-10 w-10 bg-gray-300 rounded"></div>
                                    <div className="space-y-2">
                                        <div className="h-3 w-32 bg-gray-300 rounded"></div>
                                        <div className="h-2 w-32 bg-gray-300 rounded"></div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                ) : (
                    <>
                        {currentOrganization ? (
                            <Sidebar.Logo href="#" img={`${currentOrganization.logo}`}>
                                <p>{currentOrganization.name}</p>
                            </Sidebar.Logo>
                        ) : (
                            <div className="mx-2 flex items-center space-x-3 animate-pulse">
                                <div className="h-8 w-8 bg-gray-300 rounded-full"></div>
                                <div className="h-4 w-32 bg-gray-300 rounded"></div>
                            </div>
                        )}

                        <Sidebar.Items>
                            <Sidebar.ItemGroup></Sidebar.ItemGroup>

                            <Sidebar.ItemGroup>
                                <Sidebar.Item icon={HiChartPie}>
                                    <button
                                        className="w-full text-left py-1"
                                        onClick={() => {
                                            navigate(`/app/${orgId}/notes/${prodId}/dash`);
                                        }}
                                    >
                                        <p>Dashboard</p>
                                    </button>
                                </Sidebar.Item>
                                <Sidebar.Item icon={HiDocument}>
                                    <button
                                        className="w-full text-left py-1"
                                        onClick={() => {
                                            navigate(`/app/${orgId}/notes/${prodId}`);
                                        }}
                                    >
                                        <p>Release Notes</p>
                                    </button>
                                </Sidebar.Item>
                                <Sidebar.Item icon={HiUserGroup}>
                                    <button
                                        className="w-full text-left py-1"
                                        onClick={() => {
                                            navigate(`/app/${orgId}/notes/${prodId}/subscribers`);
                                        }}
                                    >
                                        <p>Subscribers</p>
                                    </button>
                                </Sidebar.Item>
                                <Sidebar.Item icon={HiSparkles}>
                                    <button
                                        className="w-full text-left py-1"
                                        onClick={() => {
                                            navigate(`/app/${orgId}/${prodId}/feedback`);
                                        }}
                                    >
                                        <p>Feedback</p>
                                    </button>
                                </Sidebar.Item>
                                <Sidebar.Item icon={HiPuzzle}>
                                    <button
                                        className="w-full text-left py-1"
                                        onClick={() => {
                                            navigate(`/app/${orgId}/${prodId}/integration/embeds`);
                                        }}
                                    >
                                        <p>Integrations</p>
                                    </button>
                                </Sidebar.Item>
                            </Sidebar.ItemGroup>
                            <Sidebar.ItemGroup>
                                <Sidebar.Item icon={HiCog}>
                                    <button
                                        className="w-full text-left py-1"
                                        onClick={() => {
                                            navigate(`/app/settings/organization/${orgId}/products`);
                                        }}
                                    >
                                        <p>Settings</p>
                                    </button>
                                </Sidebar.Item>
                                {/*<Sidebar.Item icon={HiQuestionMarkCircle}>*/}
                                {/*    <button*/}
                                {/*        className="w-full text-left py-1"*/}
                                {/*        onClick={() => {*/}
                                {/*            toast.info("Feature yet to be implimented");*/}
                                {/*        }}*/}
                                {/*    >*/}
                                {/*        <p>Docs</p>*/}
                                {/*    </button>*/}
                                {/*</Sidebar.Item>*/}
                            </Sidebar.ItemGroup>
                        </Sidebar.Items>
                    </>
                )}
            </Sidebar>
        </div>
    );
}
