import API from "../../../api";

export type Subscriber = {
  ROWID: string;
  Product: string;
  Email: string;
  Name: string;
  CREATEDTIME: string;
};

export const useSubscribers = () => {
  return {
    getSubscribersForProduct: async (prodId: string): Promise<Subscriber[]> => {
      try {
        const response = await API.get(`/subscribers/${prodId}`);
        return response.data.payload;
      } catch (err) {
        console.log(err);
        throw err;
      }
    },
    getPendingSubscribersForProduct: async (
      prodId: string
    ): Promise<Subscriber[]> => {
      try {
        const response = await API.get(`/subscribers/${prodId}/pending`);
        return response.data.payload;
      } catch (err) {
        console.log(err);
        throw err;
      }
    },
  };
};
