import {Modal} from "flowbite-react";
import {useState} from "react";
import {HiEye} from "react-icons/hi";
import {ReleaseNoteWithRowId} from "../../../types/releaseNotes";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import NoteDateComponent from "./NoteDate";

function DefaultViewRenderButtonComponent() {
    return <button
        className="px-1 py-1 text-gray-500 hover:text-gray-900 "
    >
        <div className="relative group">
            <HiEye className="h-4 w-4 text-gray-500 hover:text-gray-900"/>
            <span
                className="absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-6 w-auto px-2 py-1 bg-black text-white text-xs rounded hidden group-hover:block">
            View
          </span>
        </div>
    </button>
}

export function ReleaseNoteViewRenderHTMLModal(
    {
        releaseNote,
        ButtonComponent = DefaultViewRenderButtonComponent,
    }: {
        releaseNote: ReleaseNoteWithRowId;
        ButtonComponent?: React.FC;
    }) {
    const [openModal, setOpenModal] = useState(false);

    return (
        <>
            <div onClick={() => {
                setOpenModal(true);
            }}
            >
                <ButtonComponent/>
            </div>
            <Modal
                dismissible
                show={openModal}
                onClose={() => setOpenModal(false)}
                size="3xl"
                className="dark:bg-gray-800"
            >
                <Modal.Header className="">
                    <div className="flex flex-col">
                        <h1 className="dark:text-gray-50">{releaseNote.title}</h1>
                        <div className="">
                            <NoteDateComponent
                                date={releaseNote?.addedDate?.toString() ?? ""}
                            />
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body className="">
                    <ReactMarkdown
                        remarkPlugins={[remarkGfm]}
                        className="prose dark:prose-invert p-[20px] max-w-none"
                    >
                        {releaseNote.content}
                    </ReactMarkdown>
                </Modal.Body>
                {releaseNote?.tag && releaseNote?.tag.length > 0 && (
                    <Modal.Footer className="dark:bg-gray-700">
                        <div className="mt-2 space-x-2">
                            {releaseNote?.tag.map((v) => (
                                <button
                                    key={v}
                                    className="text-sm bg-gray-200 dark:bg-gray-600 px-2 py-1 rounded-md dark:text-gray-400"
                                >
                                    #{v}
                                </button>
                            ))}
                        </div>
                    </Modal.Footer>
                )}
            </Modal>

        </>
    );
}
