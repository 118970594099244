import { useQuery } from "@tanstack/react-query";
import API from "../../api";
import { OrganizationUser } from "../../types/users";

async function fetchMembersByOrgID(orgID: string): Promise<OrganizationUser[]>{
    const response = await API.get(`/organization/${orgID}/members`);
    return response.data.payload;
}

export default function useGetMembersForOrgID(orgID: string) {
    return useQuery<OrganizationUser[], Error>(["members", orgID], () => fetchMembersByOrgID(orgID));
}