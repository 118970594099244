import React from "react";
import { EmbedNote, useEmbed } from "./hooks";
import { useParams } from "react-router-dom";
import LoadingPage from "../../../../components/Shared/LoadingPage";
import dayjs from "dayjs";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

const Embed: React.FC = () => {
  const { embedID } = useParams();
  const { data: embedConfig, isLoading: loading } = useEmbed(embedID);

  return (
    <div className="mt-10 mx-auto max-w-6xl">
      <div className="min-h-screen">
        {loading ? (
          <LoadingPage fullHeight={false} />
        ) : (
          <>
            <div className="text-center text-xl mt-4">
              {embedConfig?.notes?.length === 0 &&
                "No release notes for this app"}
            </div>
            <div>
              {embedConfig?.notes?.map((note: EmbedNote) => (
                <div
                  key={note?.ROWID}
                  className="bg-gray-200 rounded-lg p-6 my-8 transition-all duration-300"
                  style={{
                    backgroundColor: embedConfig?.ThemeColor
                      ? embedConfig.ThemeColor
                      : "rgb(238 238 238 / var(--tw-bg-opacity))",
                    color: embedConfig?.TextColor
                      ? embedConfig.TextColor
                      : "rgb(117 117 117 / var(--tw-text-opacity))",
                  }}
                >
                  <div className="flex justify-between items-center">
                    <h1 className="text-2xl font-semibold">
                      {note.Title}
                      <span className="italic text-sm ml-2">
                        (date:{" "}
                        {dayjs(note?.CREATEDTIME).format("MMMM DD, YYYY ")})
                      </span>
                    </h1>
                  </div>
                  <hr
                    className="w-[70%] my-4 border-gray-400"
                    style={{
                      borderColor: embedConfig?.AccentColor
                        ? embedConfig.AccentColor
                        : "rgb(189 189 189 / var(--tw-border-opacity))",
                    }}
                  />
                  <ReactMarkdown
                    remarkPlugins={[remarkGfm]}
                    className="prose prose-lg p-[20px]"
                  >
                    {note.Content}
                  </ReactMarkdown>

                  <div className="flex justify-between items-center mt-4">
                    Tags: {note.Tags}
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Embed;
