import { Pagination, Table } from "flowbite-react";
import { IndividualFeedback } from "../../types/feedback";
import { useEffect, useMemo } from "react";
import {
  Column,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import NoteDateComponent from "../Note/View/NoteDate";
import { SingleFeedbackDetailViewModal } from "./Modal/SingleFeedbackDetailViewModal";
import { FeedbackDeleteModal } from "./Modal/FeedbackDeleteModal";
import { useSearchParams } from "react-router-dom";

type ListFeedbackTableComponentProps = {
  productID: string;
  feedbacks: IndividualFeedback[];
};

export default function ListFeedbackTableComponent({
  productID,
  feedbacks,
}: ListFeedbackTableComponentProps) {
  const [searchParams, setSearchParams] = useSearchParams();

  const columns = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "ID",
        cell: (props: any) => <b>{props.getValue()}</b>,
      },
      {
        accessorKey: "email",
        header: "Email",
        cell: (props: any) => <p>{props.getValue()}</p>,
      },
      {
        accessorKey: "releaseNote.title",
        header: "Release Note",
        cell: (props: any) => {
          const fullText = props.getValue();
          const trimmedText =
            fullText.split(" ").slice(0, 5).join(" ") +
            (fullText.split(" ").length > 5 ? "..." : "");

          return (
            <div
              className="relative group"
              onClick={() => {
                setSearchParams({
                  release_note: props.row.original.releaseNote.id.toString(),
                });
              }}
            >
              <p className="truncate">{trimmedText}</p>
              <span className="absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-6 w-auto px-2 py-1 bg-black text-white text-xs rounded hidden group-hover:block">
                Click to Filter
              </span>
            </div>
          );
        },
        filterFn: (row: any, columnId: any, filterValue: any) => {
          const currentReleaseNoteID = row.original?.releaseNote.id;
          return currentReleaseNoteID.toString() === filterValue;
        },
      },
      {
        accessorKey: "feedback",
        header: "Feedback",
        cell: (props: any) => {
          const fullText = props.getValue();
          const trimmedText =
            fullText.split(" ").slice(0, 4).join(" ") +
            (fullText.split(" ").length > 4 ? "..." : "");

          return (
            <div className="relative">
              <p className="truncate">{trimmedText}</p>
            </div>
          );
        },
      },
      {
        accessorKey: "isLike",
        header: "Type",
        cell: (props: any) => <p>{props.getValue() ? "Like" : "Dislike"}</p>,
      },
      {
        accessorKey: "createdAt",
        header: "Created At",
        cell: (props: any) => (
          <p>
            <NoteDateComponent date={props.getValue().toString() ?? ""} />
          </p>
        ),
      },
      {
        header: "Actions",
        cell: ({ row }: any) => (
          <div className="flex space-x-2">
            <div className="bg-gray-200 rounded-md text-xl px-1 pt-1">
              <SingleFeedbackDetailViewModal feedback={row.original} />
            </div>
            <div className="bg-gray-200 rounded-md text-xl px-1 pt-1">
              <FeedbackDeleteModal
                feedbackID={row.original.id}
                prodID={productID}
              />
            </div>
          </div>
        ),
      },
    ],
    [productID, setSearchParams]
  );

  const table = useReactTable({
    data: feedbacks,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    initialState: {
      pagination: {
        pageSize: 7,
      },
    },
  });

  useEffect(() => {
    const rnRowIDFilterParam = searchParams.get("release_note");

    console.log("Realse Note Filter by ID: ", rnRowIDFilterParam);
    const titleColumn = table
      .getAllColumns()
      .find((c: Column<IndividualFeedback>) => c.id === "releaseNote_title");

    if (titleColumn) {
      if (rnRowIDFilterParam) {
        titleColumn.setFilterValue(rnRowIDFilterParam.toString());
      } else {
        titleColumn.setFilterValue(undefined);
      }
    }
  }, [table, searchParams]);

  return (
    <div>
      <div className="flex-1">
        <Table hoverable>
          {table.getHeaderGroups().map((headerGroup) => (
            <Table.Head key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <Table.HeadCell key={header.id}>
                  <p>{header.column.columnDef.header?.toString() ?? ""}</p>
                </Table.HeadCell>
              ))}
            </Table.Head>
          ))}
          <Table.Body className="divide-y">
            {table.getRowModel().rows.map((row) => (
              <Table.Row
                className="bg-white dark:border-gray-700 dark:bg-gray-800"
                key={row.id}
              >
                {row.getVisibleCells().map((cell) => (
                  <Table.Cell
                    key={cell.id}
                    className="whitespace-nowrap font-medium text-gray-900 dark:text-white"
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Table.Cell>
                ))}
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </div>

      <div className="flex ml-auto mt-2">
        <Pagination
          currentPage={table.getState().pagination.pageIndex + 1}
          totalPages={table.getPageCount()}
          onPageChange={(page) => {
            table.setPageIndex(page - 1);
          }}
        />
      </div>
    </div>
  );
}
