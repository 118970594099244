import {useMutation, useQueryClient} from "@tanstack/react-query";
import API from "../../api";

type AddProductProductInfo = {
    orgID: string;
    logo: string;
    name: number;
    description: string;
    privacyPolicy?: string;
    socialsTwitter?: string;
    socialsFacebook?: string;
    socialsInstagram?: string;
    socialsLinkedIn?: string;
    customerContactUs?: string;
};

async function addProduct(
    {
        orgID,
        logo,
        name,
        description,
    }: AddProductProductInfo) {
    const response = await API.post(`/organization/${orgID}/products`, {
        logo: logo,
        name: name,
        description: description,
    });

    return response.data.payload;
}

export default function useAddProduct(
    {
        onSuccess,
        onError,
    }: {
        onSuccess: () => void;
        onError: () => void;
    }) {
    return useMutation(addProduct, {
        onSuccess: () => {
            onSuccess();
        },
        onError: () => {
            onError();
        },
    });
}
