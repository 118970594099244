import {useFormik} from 'formik';
import * as Yup from 'yup';
import {Button} from 'flowbite-react';

export interface UserProfileEditInfo {
    firstName: string;
    lastName: string;
}

interface UserProfileFormProps {
    userProfile?: UserProfileEditInfo;
    onSubmit: (values: UserProfileEditInfo) => void;
}

export default function UserProfileForm({
                                            userProfile = undefined,
                                            onSubmit,
                                        }: UserProfileFormProps) {
    const formik = useFormik({
        initialValues: {
            firstName: userProfile ? userProfile.firstName : '',
            lastName: userProfile ? userProfile.lastName : '',
        },
        onSubmit: onSubmit,
        validationSchema: Yup.object({
            firstName: Yup.string().required('First name is required'),
            lastName: Yup.string().required('Last name is required'),
        }),
    });

    return (
        <div>
            <form onSubmit={formik.handleSubmit}>
                <div className="mb-4">
                    <label htmlFor="firstName" className="block text-gray-700 text-sm font-bold mb-2">
                        First Name
                    </label>
                    <input
                        id="firstName"
                        name="firstName"
                        type="text"
                        value={formik.values.firstName}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    />
                    {formik.touched.firstName && formik.errors.firstName && (
                        <div className="text-red-500 text-xs">{formik.errors.firstName}</div>
                    )}
                </div>

                <div className="mb-4">
                    <label htmlFor="lastName" className="block text-gray-700 text-sm font-bold mb-2">
                        Last Name
                    </label>
                    <input
                        id="lastName"
                        name="lastName"
                        type="text"
                        value={formik.values.lastName}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    />
                    {formik.touched.lastName && formik.errors.lastName && (
                        <div className="text-red-500 text-xs">{formik.errors.lastName}</div>
                    )}
                </div>
                <div>
                    <Button
                        type="submit"
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                        isProcessing={formik.isSubmitting}
                    >
                        Save
                    </Button>
                </div>
            </form>
        </div>
    );
}
