import { Modal } from "flowbite-react";
import { IndividualFeedback } from "../../../types/feedback";
import React from "react";
import { HiEye } from "react-icons/hi";

export function SingleFeedbackDetailViewModal({
  feedback,
}: {
  feedback: IndividualFeedback;
}) {
  const [showModal, setShowModal] = React.useState(false);

  return (
    <div>
      <div>
        <button className="" onClick={() => setShowModal(true)}>
          <HiEye className="h-5 w-5" />
        </button>
      </div>
      <div>
        <Modal show={showModal} size="4xl" onClose={() => setShowModal(false)}>
          <Modal.Header>
            <h2 className="text-gray-700 font-bold ">Feedback Details</h2>
          </Modal.Header>
          <Modal.Body>
            <div className="space-y-3">
              <div className="flex items-center">
                <span className="font-semibold text-gray-600 w-32">Email:</span>
                <span className="text-gray-700">{feedback.email}</span>
              </div>
              <div className="flex items-center">
                <span className="font-semibold text-gray-600 w-32">
                  Release Note:
                </span>
                <span className="text-gray-700">
                  {feedback.releaseNote.title}
                </span>
              </div>
              <div className="flex items-center">
                <span className="font-semibold text-gray-600 w-32">Type:</span>
                <span className="text-gray-700">
                  {feedback.isLike ? "Like" : "Dislike"}
                </span>
              </div>
              <div className="flex items-center">
                <span className="font-semibold text-gray-600 w-32">
                  Created At:
                </span>
                <span className="text-gray-700">
                  {new Date(feedback.createdAt).toLocaleString()}
                </span>
              </div>
              <hr />
              <div className="flex space-y-2 flex-col">
                <b className="">Feedback </b>
                <p className="text-gray-700">{feedback.feedback}</p>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
}
